import {
  Box,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import {
  getSystemPackageCaption,
  getSystemPackageColor,
  getSystemPackageName,
  packageColorMap,
  systemPackageEnum,
} from '../../../../helpers/packageHelper';

const SystemPackageItem = ({ handleCloseModal,systemPackage, onPurchaseClick }) => {
  const theme = useTheme();
  const isCustomPackage = () => {
    return systemPackage?.name === systemPackageEnum.CUSTOM;
  };

  const handlePurchaseClick = () => {
    onPurchaseClick(systemPackage)
  };
  return (
    <Box height="100%">
      <Card
        variant="outlined"
        sx={{ 
          boxShadow: `${getSystemPackageColor(systemPackage, theme).light} 0 4px 0 0 inset`,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-8px)',
            boxShadow: `${getSystemPackageColor(systemPackage, theme).light} 0 4px 0 0 inset,
                       ${getSystemPackageColor(systemPackage, theme).main} 0 8px 16px -4px`,
            '& .MuiButton-root': {
              transform: 'scale(1.05)',
              boxShadow: `${getSystemPackageColor(systemPackage, theme).main} 0 4px 12px`
            }
          }
        }}
      >
        <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Box>
            <Typography
              sx={{ color: getSystemPackageColor(systemPackage, theme).main }}
              variant="h6"
              fontWeight={'bold'}
            >
              {getSystemPackageName(systemPackage)}
            </Typography>
          </Box>
          <Box flex={1} display="flex" flexDirection="column">
            <List>
              <ListItem sx={{ p: 0 }}>
                <SmartToyOutlinedIcon
                  sx={{ fontSize: 17, mr: 1.5, color: theme.palette.text.primary }}
                />
                <ListItemText
                  color="text.primary"
                  primary={`${isCustomPackage() ? 'tùy chỉnh số lượng' : systemPackage?.maxBot} bot`}
                />
              </ListItem>
              <ListItem sx={{ p: 0 }}>
                <ChatBubbleOutlineOutlinedIcon
                  sx={{ fontSize: 17, mr: 1.5, color: theme.palette.text.primary }}
                />
                <ListItemText
                  color="text.primary"
                  primary={
                    <>
                      {`${isCustomPackage() ? 'không giới hạn' : Math.floor(systemPackage?.maxMessage * systemPackage?.duration * (systemPackage?.duration === 6 ? 1.1 : 1))} tin nhắn`}
                      {systemPackage?.duration === 6 && (
                        <Typography 
                          component="span" 
                          color="primary.main"
                          sx={{
                            display: 'block',
                            fontSize: '0.75rem',
                            fontWeight: 'bold'
                          }}
                        >
                          (Tiết kiệm 10% khi đăng ký các gói 6 tháng)
                        </Typography>
                      )}
                    </>
                  }
                />
              </ListItem>
              <ListItem sx={{ p: 0 }}>
                <AccessTimeOutlinedIcon
                  sx={{ fontSize: 17, mr: 1.5, color: theme.palette.text.primary }}
                />
                <ListItemText
                  color="text.primary"
                  primary={`${isCustomPackage() ? 'thời gian linh hoạt' : systemPackage?.duration + ' tháng'}`}
                />
              </ListItem>
              <ListItem sx={{ p: 0 }}>
                <FolderOutlinedIcon
                  sx={{ fontSize: 17, mr: 1.5, color: theme.palette.text.primary }}
                />
                <ListItemText
                  color="text.primary"
                  primary={`${isCustomPackage() ? 'không giới hạn' : systemPackage?.maxFile} file`}
                />
              </ListItem>
              <ListItem sx={{ p: 0 }}>
                <AttachmentOutlinedIcon sx={{ fontSize: 17, mr: 1.5 }} />
                <ListItemText
                  color="text.primary"
                  primary={`${isCustomPackage() ? 'không giới hạn' : systemPackage?.maxUrl} url`}
                />
              </ListItem>
            </List>
            <Box mt="auto">
              <Box mb={1}>
                <Typography
                  variant="subtitle1"
                  color={'text.secondary'}
                  sx={{ textDecoration: 'line-through' }}
                >
                  {isCustomPackage()
                    ? <br />
                    : `${systemPackage?.basePrice?.toLocaleString('vi-VN')} VND`}
                </Typography>
                <Typography variant="h5">
                  {systemPackage?.price
                    ? `${systemPackage?.price?.toLocaleString('vi-VN')} VND`
                    : 'Liên hệ'}
                </Typography>
              </Box>
              <Box height={32}>
                <Typography
                  variant="body2"
                  fontWeight={'normal'}
                  fontSize={11}
                  color={'text.secondary'}
                >
                  {getSystemPackageCaption(systemPackage)}
                </Typography>
              </Box>
              <Box mt={3}>
                <Button
                  sx={{ 
                    width: '100%',
                    transition: 'all 0.3s ease-in-out'
                  }}
                  color={packageColorMap[systemPackage?.name]}
                  variant="contained"
                  onClick={handlePurchaseClick}
                >
                  {isCustomPackage() ? 'Tùy chỉnh' : 'Mua ngay'}
                </Button>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SystemPackageItem;

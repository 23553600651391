import { Alert, Snackbar } from "@mui/material";

const MessageCustoms = ({ open,
    message,
    severity = 'success',
    autoHideDuration = 3000,
    onClose,
    anchorOrigin = { vertical: 'top', horizontal: 'right' } }
) => {

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            anchorOrigin={anchorOrigin}
        >
            <Alert
                onClose={onClose}
                severity={severity}
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    );

}

export default MessageCustoms
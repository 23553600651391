import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    commissions: [],
    total: 0,
    totalPages: 0,
    isLoading: false,
    error: null,
    filters: {
        page: 1,
        limit: 10,
        startDate: null,
        endDate: null,
        sourceFilter: 'all',
        refresh: 0,
    },
    ui: {
        isList: true,
        isAffiliate: false,
        isEarning: false,
        isAccount: false,
    },
}
const commissionSlice = createSlice({
    name: 'commission',
    initialState,
    reducers: {
        getCommissions: (state, action) => {
            state.commissions = action.payload.data
            state.totalPages = action.payload.pagination.totalPages
            state.total = action.payload.pagination.total
            state.isLoading = false
            state.error = null
        },
        updateCommissions: (state, action) => {
            state.filters = { ...state.filters, ...action.payload };
        },
        updateUI: (state, action) => {
            state.ui = { ...state.ui, ...action.payload };
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        },
        triggerRefresh: (state) => {
            state.filters.refresh += 1;
        }
    }
})
export const {
    getCommissions,
    updateCommissions,
    setLoading,
    setError,
    updateUI,
    triggerRefresh
} = commissionSlice.actions

export default commissionSlice.reducer
import { Dialog, DialogContent, DialogTitle, Typography, IconButton, Avatar, Box } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import LinkIcon from '@mui/icons-material/Link';

const DetailAffiliate = ({ open, onClose, referrer }) => {
    const affiliateLink = `https://affiliate.schat.vn/?referralCode=${referrer?.referralCode}`;
    const schatLink = `https://schat.vn/?referralCode=${referrer?.referralCode}`;


    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                <Typography variant="h6">Thông tin người giới thiệu</Typography>
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 10, right: 10 }}
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    bgcolor: '#1976d2',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }}>
                    <Box sx={{
                        width: '100%',
                        height: '120px',
                        bgcolor: referrer.isActive ? '#4caf50' : '#F44336',
                        position: 'relative'
                    }}>
                        <Box sx={{
                            position: 'absolute',
                            bottom: '-50px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 135,
                            height: 135, borderRadius: '50%', border: referrer.isActive ? '6px solid #4caf50' : '6px solid #F44336'
                        }}>
                            <Avatar src={referrer?.owner?.avatar} sx={{
                                width: "100%",
                                height: "100%",
                                border: '2px solid #fff',
                            }} />
                        </Box>
                    </Box>
                    <Box sx={{
                        bgcolor: '#fff',
                        width: '100%',
                        px: { xs: 1, lg: 2 },
                        pt: 8,
                        pb: { xs: 1, lg: 2 },
                        textAlign: 'center'
                    }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                            {referrer?.owner?.name}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                            Cấp độ: {referrer?.level}
                        </Typography>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            alignItems: 'flex-start',
                            width: '100%'
                        }}>
                            <Box sx={{ width: '100%', display: 'flex', gap: 1, alignItems: 'center', bgcolor: '#FEF9E7', py: 2, px: 1, borderRadius: '4px' }}>
                                <EmailIcon sx={{ color: 'text.secondary' }} />
                                <Typography>{referrer?.owner?.email}</Typography>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', gap: 1, alignItems: 'center', bgcolor: '#F5EEF8', py: 2, px: 1, borderRadius: '4px' }}>
                                <LinkIcon sx={{ color: 'text.secondary' }} />
                                <Typography sx={{ fontSize: { xs: 14, lg: 16 }, wordBreak: 'break-word' }}>{affiliateLink}</Typography>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', gap: 1, alignItems: 'center', bgcolor: '#FCF3CF', py: 2, px: 1, borderRadius: '4px' }}>
                                <LinkIcon sx={{ color: 'text.secondary' }} />
                                <Typography sx={{ fontSize: { xs: 14, lg: 16 }, wordBreak: 'break-word' }}>{schatLink}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default DetailAffiliate
import httpRequest from '../utils/httpRequest';

const uploadService = {
  uploadFile: (file, botId = null) => {
    const formData = new FormData();
    formData.append('file', file);
    if (botId) {
      formData.append('botId', botId);
    }
    return httpRequest
      .post('/uploads', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => res.data);
  },

  uploadFiles: (botId, newFiles, existingFiles) => {
    const formData = new FormData();
    newFiles.forEach((file) => {
      formData.append('files', file);
    });
    existingFiles.forEach((file) => {
      formData.append('existingFiles', file.fileId);
    });
    formData.append('botId', botId);
    return httpRequest
      .post('/uploads/multiple', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => res.data);
  },
};

export default uploadService;

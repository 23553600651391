import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormGroup,
  FormLabel,
  Tooltip,
  useTheme,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import Input from '../../../components/forms/Input';
import Textarea from '../../../components/forms/Textarea';
import { useEffect, useState } from 'react';
import botService from '../../../services/botService';
import { useSelector } from 'react-redux';
import uploadService from '../../../services/uploadService';
import CardContentHeader from '../../../components/cards/CardContentHeader';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VisuallyHiddenInput from '../../../components/forms/VisuallyHiddenInput';

const BotInformation = () => {
  const theme = useTheme();
  const botSelector = useSelector((state) => state.bot);

  const [isLoading, setIsLoading] = useState(false);
  const [bot, setBot] = useState(botSelector.currentBot);
  const [imagePreview, setImagePreview] = useState(bot?.avatar || null);

  useEffect(() => {
    if (botSelector?.currentBot) {
      setBot(botSelector.currentBot);
      setImagePreview(botSelector.currentBot?.avatar || null);
    }
  }, [botSelector.currentBot]);

  const initialValues = {
    name: bot?.name || '',
    instructions: bot?.instructions || '',
    avatar: null,
  };

  const updateBot = (body) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    botService
      .updateBot(bot.id, body)
      .then((res) => {
        setBot(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const uploadBot = (body) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    botService
      .updateBot(bot.id, body)
      .then((res) => {
        setBot(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    let body = {
      name: values?.name,
      instructions: values?.instructions,
    };
    if (values?.avatar) {
      uploadService
        .uploadFile(values?.avatar, bot.id)
        .then((res) => {
          if (res) {
            body.avatar = res.url;
            uploadBot(body);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      uploadBot(body);
    }
  };

  return (
    <Card>
      <CardContent>
        <CardContentHeader title="Cập nhật thông tin" />
        <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={handleSubmit}>
          {({ handleChange, handleBlur, setFieldValue, values }) => (
            <Form>
              <FormGroup sx={{ mb: 3 }}>
                <FormLabel required>Tên Bot</FormLabel>
                <Field
                  name="name"
                  as={Input}
                  placeholder="Nhập tên..."
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>
              <FormGroup sx={{ mb: 3 }}>
                <FormLabel>Hình đại diện</FormLabel>
                <Box display={'flex'}>
                  <Avatar
                    src={imagePreview}
                    variant="rounded"
                    sx={{
                      width: 100,
                      height: 100,
                      borderRadius: theme.shape.borderRadius,
                      border: `3px solid ${theme.palette.background.default}`,
                      boxSizing: 'border-box',
                    }}
                  />
                  <Tooltip title="Đổi hình đại diện" placement="right">
                    <Button
                      component="label"
                      sx={{
                        ml: 3,
                        borderRadius: theme.shape.borderRadius,
                        width: 100,
                        height: 100,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: `1px dashed ${theme.palette.primary.main}`,
                      }}
                    >
                      <CameraAltIcon sx={{ color: theme.palette.primary.main, fontSize: 30 }} />
                      <VisuallyHiddenInput
                        accept="image/*"
                        type="file"
                        onChange={(e) => {
                          setFieldValue('avatar', e.target.files[0]);
                          setImagePreview(URL.createObjectURL(e.target.files[0]));
                        }}
                      />
                    </Button>
                  </Tooltip>
                </Box>
              </FormGroup>
              <FormGroup sx={{ mb: 3 }}>
                <FormLabel required>Hướng dẫn Bot</FormLabel>
                <Field
                  name="instructions"
                  as={Textarea}
                  rows={20}
                  placeholder="Nhập nội dung hướng dẫn Bot..."
                  value={values.instructions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>

              <Box width={'100%'} display={'flex'} justifyContent={'end'}>
                <Button type="submit" variant="contained" disabled={isLoading}>
                  {!isLoading ? (
                    'Lưu thông tin'
                  ) : (
                    <span>
                      Đang lưu
                      <CircularProgress
                        sx={{ ml: 1 }}
                        color="inherit"
                        size={12}
                        thickness={5}
                        variant="indeterminate"
                      />
                    </span>
                  )}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default BotInformation;

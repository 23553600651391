import {
    Box,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    useTheme,
    useMediaQuery,
    Avatar,
    Divider,
    Chip,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from "react";
import affiliateByAdmin from "../../../services/admin/affiliate/affiliate";
import TotalEarningDialog from "./TotalEarningDialog";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setError, setSelectedAffiliate, updateUi } from "../../../reducers/slices/affiliateSlice";
import { formatCurrency, formatDate } from "../../../utils/formatters";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const AffiliateDialogContainer = ({ open, onClose, id }) => {
    const dispatch = useDispatch();
    const { selectedAffiliate } = useSelector(state => state.affiliate);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [showEarningDialog, setShowEarningDialog] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDarkMode = theme.palette.mode === 'dark';
    const [expandedLevels, setExpandedLevels] = useState({});

    useEffect(() => {
        if (!open) return;
        const fetchData = async () => {
            try {
                const response = await affiliateByAdmin.getRelated(id);
                dispatch(setSelectedAffiliate(response));
            } catch (error) {
                dispatch(setError(error.message));
            }
        }
        fetchData()
    }, [id, open, dispatch]);

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuOpen = (event, id) => {
        setAnchorEl(event.currentTarget);
        setSelectedId(id);
    };

    const handleViewCommission = async () => {
        setShowEarningDialog(true);
        setSearchParams(prev => ({ ...prev, showEarningDialog: 'true', earningId: selectedId }));
        handleMenuClose();
    };

    const handleCloseEarningDialog = () => {
        setShowEarningDialog(false);
        setSearchParams(prev => ({ ...prev, showEarningDialog: 'false', earningId: '' }));
        setSelectedId(null);
    };

    const toggleLevel = (affiliateId) => {
        setExpandedLevels(prev => {
            const newState = { ...prev, [affiliateId]: !prev[affiliateId] };
            return newState;
        });
    };

    const renderAffiliateCard = (affiliate, level = 0) => {
        return (
            <Card
                key={affiliate?._id}
                onClick={() => affiliate?.subAffiliates?.length > 0 && toggleLevel(affiliate?._id)}
                sx={{
                    position: 'relative',
                    mt: 2,
                    ml: isMobile ? 0 : level * 10,
                    borderLeft: `4px solid ${affiliate?.level === 1 ? '#FFA500' :
                        affiliate?.level === 2 ? '#00FF00' : '#0000FF'
                        }`,
                    bgcolor: affiliate?.isMain
                        ? isDarkMode ? 'rgba(128, 0, 128, 0.1)' : '#F0E6F0'
                        : theme.palette.background.paper,
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                    transition: 'all 0.3s ease',
                    cursor: affiliate?.subAffiliates?.length > 0 ? 'pointer' : 'default',
                    borderRadius: '12px',
                }}
            >
                {affiliate?.level !== 3 &&
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            affiliate?.subAffiliates?.length > 0 && toggleLevel(affiliate?._id);
                        }}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 8,
                            zIndex: 1,
                            '&:hover': {
                                backgroundColor: isDarkMode
                                    ? 'rgba(255,255,255,0.08)'
                                    : 'rgba(0,0,0,0.04)'
                            }
                        }}>
                        {expandedLevels[affiliate._id] ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    </IconButton>
                }
                <CardContent sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'space-between',
                    p: 2,
                    gap: isMobile ? 2 : 0
                }}>
                    <Box sx={{ flex: 1 }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            mb: 1,
                        }}>
                            <Box sx={{ borderRadius: '50%', border: affiliate?.isActive ? '3px solid #4caf50' : '3px solid #6C757D' }}>
                                <Avatar src={affiliate?.ownerInfo?.avatar} />
                            </Box>
                            {/* <Avatar src={affiliate.owner.avatar} /> */}
                            <Box>
                                <Typography variant="subtitle2" fontWeight="bold">{affiliate?.owner?.name}</Typography>
                                <Typography sx={{
                                    color: isDarkMode ? theme.palette.text.secondary : '#666',
                                    fontSize: isMobile ? '0.875rem' : '1rem'
                                }}>
                                    <Box component="span" sx={{
                                        fontSize: '0.85rem',
                                        fontWeight: 600,
                                        color: isDarkMode ? theme.palette.text.primary : '#2c3e50'
                                    }}>Cấp độ:</Box> {affiliate?.level}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
                            gap: isMobile ? 1 : 0,
                        }}>
                            <Typography sx={{
                                color: isDarkMode ? theme.palette.text.secondary : '#666',
                                fontSize: isMobile ? '0.875rem' : '1rem',
                            }}>
                                <Box component="span" sx={{
                                    fontWeight: 600,
                                    color: isDarkMode ? theme.palette.text.primary : '#2c3e50'
                                }}>Email:</Box> {affiliate?.owner?.email}
                            </Typography>
                            <Typography sx={{
                                color: isDarkMode ? theme.palette.text.secondary : '#666',
                                fontSize: isMobile ? '0.875rem' : '1rem'
                            }}>
                                <Box component="span" sx={{
                                    fontWeight: 600,
                                    color: isDarkMode ? theme.palette.text.primary : '#2c3e50'
                                }}>Mã giới thiệu:</Box> {affiliate?.referralCode}
                            </Typography>
                            <Typography sx={{
                                color: isDarkMode ? theme.palette.text.secondary : '#666',
                                fontSize: isMobile ? '0.875rem' : '1rem'
                            }}>
                                <Box component="span" sx={{
                                    fontWeight: 600,
                                    color: isDarkMode ? theme.palette.text.primary : '#2c3e50'
                                }}>Ngày đăng ký:</Box> {formatDate(affiliate?.createdAt)}
                            </Typography>
                            <Typography sx={{
                                color: isDarkMode ? theme.palette.text.secondary : '#666',
                                fontSize: isMobile ? '0.875rem' : '1rem'
                            }}>
                                <Box component="span" sx={{
                                    fontWeight: 600,
                                    color: isDarkMode ? theme.palette.text.primary : '#2c3e50'
                                }}>Doanh thu:</Box> {formatCurrency(affiliate?.totalEarning)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                        width: isMobile ? '100%' : 170,
                        display: 'flex',
                        flexDirection: isMobile ? 'row' : 'column',
                        alignItems: isMobile ? 'center' : 'center',
                        justifyContent: isMobile ? 'space-between' : 'center',
                        position: 'relative',
                        ml: isMobile ? 0 : 2,
                        mt: isMobile ? 2 : 0
                    }}>
                        <Typography
                            fontWeight={600}
                            sx={{
                                mb: isMobile ? 0 : 1,
                                position: isMobile ? 'static' : 'absolute',
                                top: isMobile ? 'auto' : 0,
                                color: affiliate?.isActive ? '#2ecc71' : '#e74c3c',
                                fontSize: isMobile ? '0.8rem' : '0.9rem',
                                // textTransform: 'uppercase',
                                letterSpacing: '0.5px'
                            }}
                        >
                            {affiliate?.isActive ? (<Chip label="Đang hoạt động" size="small" color="primary" variant="filled" />
                            ) : (<Chip label="Ngừng hoạt động" size="small" color="success" variant="filled" />
                            )}
                        </Typography>
                        <Tooltip title="Xem thêm">
                            <IconButton
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleMenuOpen(event, affiliate?._id)
                                }}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: isDarkMode
                                            ? 'rgba(255,255,255,0.08)'
                                            : 'rgba(0,0,0,0.04)'
                                    }
                                }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </CardContent>
            </Card>
        );
    };

    const renderAffiliateTree = (affiliate, level = 0) => {
        return (
            <>
                {renderAffiliateCard(affiliate, level)}
                {expandedLevels[affiliate?._id] && affiliate?.subAffiliates?.map((subAffiliate) => (
                    renderAffiliateTree(subAffiliate, level + 1)
                ))}
            </>
        );
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="lg"
                fullWidth
                PaperProps={{
                    sx: {
                        position: 'relative',
                        borderRadius: '16px',
                        boxShadow: isDarkMode
                            ? '0 8px 32px rgba(0,0,0,0.3)'
                            : '0 8px 32px rgba(0,0,0,0.1)',
                        m: isMobile ? 1 : 3,
                        bgcolor: theme.palette.background.paper
                    }
                }}
            >
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        zIndex: 1,
                        '&:hover': {
                            backgroundColor: isDarkMode
                                ? 'rgba(255,255,255,0.08)'
                                : 'rgba(0,0,0,0.04)'
                        }
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: `1px solid ${isDarkMode ? 'rgba(255, 255, 255, 0.12)' : '#eee'}`,
                    pb: 0,
                    px: isMobile ? 3 : 4,
                    pt: isMobile ? 2 : 2,
                }}>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            mb: 2,
                            mr: 2,
                            color: theme.palette.text.primary,
                            fontSize: isMobile ? '1rem' : '1.2rem'
                        }}
                    >
                        Danh sách mỗi quan hệ của: {selectedAffiliate?.owner?.email} (Cấp độ: {selectedAffiliate?.level})
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ px: isMobile ? 2 : 4, py: isMobile ? 2 : 2 }}>
                    {selectedAffiliate && renderAffiliateTree(selectedAffiliate)}
                </DialogContent>
            </Dialog>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                PaperProps={{
                    sx: {
                        borderRadius: '8px',
                        boxShadow: isDarkMode
                            ? '0 4px 16px rgba(0,0,0,0.3)'
                            : '0 4px 16px rgba(0,0,0,0.1)'
                    }
                }}
            >
                <MenuItem onClick={handleViewCommission}>
                    Xem hoa hồng
                </MenuItem>
            </Menu>
            <TotalEarningDialog
                open={showEarningDialog}
                id={selectedId}
                onClose={handleCloseEarningDialog}
            />
        </>
    );
}

export default AffiliateDialogContainer
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormLabel,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import CardContentHeader from '../../../components/cards/CardContentHeader';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import VisuallyHiddenInput from '../../../components/forms/VisuallyHiddenInput';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import BotFileItem from './BotFileItem';
import botService from '../../../services/botService';
import uploadService from '../../../services/uploadService';

const BotFileTranning = () => {
  const theme = useTheme();
  const botSelector = useSelector((state) => state.bot);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Đang tiến hành quét, vui lòng chờ...');
  const [bot, setBot] = useState(botSelector.currentBot);
  const [existingFiles, setExistingFiles] = useState([]); // Files from bot.files
  const [newFiles, setNewFiles] = useState([]); // New files selected from device
  const [initialExistingFiles, setInitialExistingFiles] = useState([]); // To track initial state

  useEffect(() => {
    if (botSelector.currentBot?.id) {
      setBot(botSelector.currentBot);
      const files = botSelector.currentBot.files || [];
      setExistingFiles(files);
      setInitialExistingFiles(files); // Store initial state
    }
  }, [botSelector.currentBot]);

  const handleRemoveExistingFile = (file) => {
    setExistingFiles(existingFiles.filter((f) => f.id !== file.id));
  };

  const handleRemoveNewFile = (file) => {
    setNewFiles(newFiles.filter((f) => f !== file));
  };

  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setNewFiles([...newFiles, ...selectedFiles]);
  };

  const handleSubmit = () => {
    if (isLoading) return;

    // Check if there are any changes
    const hasNoNewFiles = newFiles.length === 0;
    const existingFilesUnchanged = existingFiles.length === initialExistingFiles.length &&
      existingFiles.every(file => initialExistingFiles.find(f => f.id === file.id));

    if (hasNoNewFiles && existingFilesUnchanged) {
      alert('Không có thay đổi nào được thực hiện. Vui lòng thêm hoặc xóa file trước khi tải lên.');
      return;
    }

    setIsLoading(true);
    setLoadingText('Đang tiến hành quét file, vui lòng chờ...');

    // Log all files (existing + new)
    console.log('Existing files:', existingFiles);
    console.log('New files:', newFiles);

    // Always upload files first
    uploadService.uploadFiles(bot.id, newFiles, existingFiles)
      .then((uploadedFiles) => {
        let fileIds;
        if (newFiles.length === 0) {
          // If no new files, use existing file IDs
          fileIds = existingFiles.map(file => file.id);
        } else {
          // Combine existing and newly uploaded file IDs
          fileIds = [
            ...existingFiles.map(file => file.id),
            ...uploadedFiles.map(file => file.id)
          ];
        }
        console.log("fileIds", fileIds);

        return botService.tranningFiles(bot.id, { fileIds });
      })
      .then((res) => {
        setBot(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Card>
      <CardContent>
        <CardContentHeader title="Training với file" />

        <FormLabel>Danh sách file</FormLabel>
        <Box mb={3} mt={1}>
          <Grid container spacing={2}>
            {existingFiles.map((file, index) => (
              <Grid key={index} xl={3} lg={4} md={4} xs={6} sm={6} item>
                <Link target="_blank" to={file.url}>
                  <BotFileItem file={file} handleDelete={handleRemoveExistingFile} />
                </Link>
              </Grid>
            ))}
            {newFiles.map((file, index) => (
              <Grid key={index} xl={3} lg={4} md={4} xs={6} sm={6} item>
                <BotFileItem file={file} handleDelete={handleRemoveNewFile} />
              </Grid>
            ))}
            <Grid item xl={3} lg={4} md={4} xs={6} sm={6}>
              <Tooltip title="Tải file lên" placement="right">
                <Button
                  disabled={isLoading}
                  component="label"
                  sx={{
                    borderRadius: theme.shape.borderRadius,
                    width: '100%',
                    height: 100,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: `1px dashed ${theme.palette.primary.main}`,
                  }}
                >
                  <DriveFolderUploadOutlinedIcon />
                  <VisuallyHiddenInput
                    accept=".txt,.docx,.pdf,.xlsx"
                    type="file"
                    onChange={handleFileSelect}
                  />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
        {isLoading && (
          <Typography variant="caption" sx={{ mt: 1, fontSize: theme.typography.fontSize }}>
            <CircularProgress
              sx={{ mr: 1 }}
              color="inherit"
              size={12}
              thickness={5}
              variant="indeterminate"
            />
            {loadingText}
          </Typography>
        )}
        <Box width={'100%'} display={'flex'} justifyContent={'end'}>
          <Button onClick={handleSubmit} type="submit" variant="contained" disabled={isLoading}>
            {!isLoading ? (
              'Bắt đầu tải file lên'
            ) : (
              <span>
                Đang tải file lên
                <CircularProgress
                  sx={{ ml: 1 }}
                  color="inherit"
                  size={12}
                  thickness={5}
                  variant="indeterminate"
                />
              </span>
            )}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BotFileTranning;

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormGroup,
  FormLabel,
  IconButton,
  styled,
  Tooltip,
  useTheme,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import Textarea from '../../components/forms/Textarea';
import { useState } from 'react';
import botService from '../../services/botService';
import uploadService from '../../services/uploadService';
import CloseIcon from '@mui/icons-material/Close';
import Input from '../../components/forms/Input';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useModal } from '../../hooks/useModal';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const BotImageUpload = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const botImageUploadModal = useModal('bot-image-upload');
  const { botId } = botImageUploadModal.data;
  const [image, setImage] = useState(botImageUploadModal.data?.image || null);
  const [imagePreview, setImagePreview] = useState(image?.file?.url || null);

  const initialValues = {
    name: image?.file?.name || '',
    description: image?.file?.description || '',
    file: null,
  };

  const addImageToBot = async (botId, body) => {
    setIsLoading(true);
    botService
      .addImage(botId, body)
      .then((res) => {
        if (res) {
          botImageUploadModal.closeModal();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    uploadService
      .uploadFile(values.file, botId)
      .then((res) => {
        addImageToBot(botId, {
          fileId: res.id,
          description: values.description,
          name: values.name,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        sx={{ '&.MuiCardHeader-root': { borderBottom: '1px solid #ccc', py: 2 } }}
        action={
          <IconButton aria-label="settings">
            <CloseIcon />
          </IconButton>
        }
        title="Thêm ảnh mới"
      />
      <CardContent>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ handleChange, handleBlur, setFieldValue, values }) => (
            <Form>
              <FormGroup sx={{ mb: 3 }}>
                <FormLabel>Tên ảnh</FormLabel>
                <Field as={Input} name="name" placeholder="Tên ảnh" value={values.name} />
              </FormGroup>
              <FormGroup sx={{ mb: 3 }}>
                <FormLabel>Ảnh</FormLabel>
                <Box display={'flex'}>
                  <Avatar
                    src={imagePreview}
                    variant="rounded"
                    sx={{
                      width: 100,
                      height: 100,
                      borderRadius: theme.shape.borderRadius,
                      border: `3px solid ${theme.palette.background.default}`,
                      boxSizing: 'border-box',
                    }}
                  />
                  <Tooltip title="Thay ảnh đại diện" placement="right">
                    <Button
                      component="label"
                      sx={{
                        ml: 3,
                        borderRadius: theme.shape.borderRadius,
                        width: 100,
                        height: 100,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: `1px dashed ${theme.palette.primary.main}`,
                      }}
                    >
                      <CameraAltIcon sx={{ color: theme.palette.primary.main, fontSize: 30 }} />
                      <VisuallyHiddenInput
                        accept="image/*"
                        type="file"
                        onChange={(e) => {
                          setFieldValue('file', e.target.files[0]);
                          setImagePreview(URL.createObjectURL(e.target.files[0]));
                        }}
                      />
                    </Button>
                  </Tooltip>
                </Box>
              </FormGroup>
              <FormGroup sx={{ mb: 3 }}>
                <FormLabel>Mô tả ảnh</FormLabel>
                <Field
                  name="description"
                  as={Textarea}
                  cols={30}
                  rows={10}
                  placeholder="Nhập nội dung mô tả ảnh..."
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>

              <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                <Button type="submit" variant="contained">
                  Lưu thông tin
                  {isLoading && (
                    <CircularProgress
                      sx={{ ml: 1 }}
                      color="inherit"
                      size={15}
                      thickness={5}
                      variant="indeterminate"
                    />
                  )}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default BotImageUpload;

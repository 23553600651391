import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  CircularProgress,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Pagination,
  TablePagination,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPackages } from '../../../reducers/slices/packageSilce';
import { fetchUsers } from '../../../reducers/slices/userSlice';
import PackageItem from './PackageItem';
import ModalCustom from '../../../components/modals/ModalCustom';
import PackageCreate from './PackageCreate';
import CustomTable from '../../../components/table/TableCustom';
import CardCustom from '../../../components/table/CardCustom';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Package = () => {
  const [status, setStatus] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [packageCode, setPackageCode] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);

  const dispatch = useDispatch();
  const { packages, currentPage, totalPages } = useSelector((state) => state.packages);
  useEffect(() => {
    dispatch(fetchPackages({ page: currentPage, limit: 5 }));
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (searchTerm.length > 1) {
      setLoading(true);
      dispatch(fetchUsers({ query: searchTerm }))
        .then((response) => {
          setUserOptions(response.payload.users || []);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [searchTerm, dispatch]);

  useEffect(() => {
    if (selectedUserId) {
      dispatch(fetchPackages({ page: currentPage, userId: selectedUserId, status, packageCode }));
    }
  }, [selectedUserId, status, packageCode, dispatch, currentPage]);

  useEffect(() => {
    dispatch(
      fetchPackages({ page: currentPage, userId: selectedUserId || '', status, packageCode })
    );
  }, [status, packageCode, selectedUserId, dispatch, currentPage]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSearchChange = (event, value) => {
    setSelectedUserId(value ? value.id : null);
  };

  const handlePackageCodeChange = (event) => {
    setPackageCode(event.target.value);
  };

  const handlePageChange = (event, value) => {
    dispatch(fetchPackages({ page: value, userId: selectedUserId || '', status, packageCode }));
  };
  const handleClick = () => {
    setIsOpen(true);
  };
  // const columns = [
  //   {
  //     id: 'code', label: 'Mã giao dịch', minWidth: 100, render: (_, data) => data?.code,
  //   },
  //   { id: 'owner', label: 'Khách hàng', minWidth: 100, render: (_, data) => data?.owner?.name },
  //   {
  //     id: 'name', label: 'Tên gói', minWidth: 100, render: (_, data) => data?.info?.name,

  //   },
  //   { id: 'duration', label: 'Thời hạn (tháng)', minWidth: 100, render: (_, data) => data?.info?.duration },
  //   { id: 'price', label: 'Giá (VND)', minWidth: 100, render: (_, data) => data?.info?.price.toLocaleString('vi-VN') },
  //   { id: 'status', label: 'Trạng thái', minWidth: 100, },
  //   {
  //     id: 'action', label: 'Chức năng', minWidth: 100, render: (_, data) => (
  //       data.status === 'active' ? (
  //         <>
  //           <Tooltip title="Xem gói" placement="top">
  //             <IconButton>
  //               <VisibilityIcon color="action" />
  //             </IconButton>
  //           </Tooltip>
  //           <Tooltip title="Hủy gói" placement="top">
  //             <IconButton>
  //               <DeleteIcon color="error" />
  //             </IconButton>
  //           </Tooltip>
  //         </>

  //       ) :
  //         data.status === 'waiting' ? (
  //           <>
  //             <Button variant="contained" color="primary" >
  //               Kích hoạt
  //             </Button>
  //             <Tooltip title="Hủy gói" placement="top"><IconButton>
  //               <DeleteIcon color="error" />
  //             </IconButton></Tooltip>

  //           </>
  //         )
  //           : data.status === 'reject' ? (
  //             <Button variant="contained" color="primary">
  //               Kích hoạt
  //             </Button>
  //           ) : ''
  //     )

  //   },
  // ]
  return (
    <Container sx={{ height: '86vh', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 2,
          flexShrink: 0,
        }}
      >
        <TextField
          label="Mã giao dịch"
          variant="outlined"
          value={packageCode}
          onChange={handlePackageCodeChange}
          sx={{
            marginRight: 2, width: { xs: '100%', sm: 200 },
            '& .MuiOutlinedInput-root': {
              height: 40,
            },
            '& .MuiInputLabel-root': {
              transform: 'translate(14px, 8px) scale(1)',
              '&.Mui-focused, &.MuiFormLabel-filled': {
                transform: 'translate(14px, -9px) scale(0.75)',
              }
            },
            '& .MuiSelect-select': {
              paddingTop: '8px',
              paddingBottom: '8px',
            }
          }}
        />

        <FormControl sx={{ minWidth: 200, marginRight: 2 }}>
          <TextField
            select
            label="Trạng thái"
            value={status}
            onChange={handleStatusChange}
            sx={{

              '& .MuiOutlinedInput-root': {
                height: 40,
              },
              '& .MuiInputLabel-root': {
                transform: 'translate(14px, 8px) scale(1)',
                '&.Mui-focused, &.MuiFormLabel-filled': {
                  transform: 'translate(14px, -9px) scale(0.75)',
                }
              },
              '& .MuiSelect-select': {
                paddingTop: '8px',
                paddingBottom: '8px',
              }
            }}
          >
            <MenuItem value="active">Thành công</MenuItem>
            <MenuItem value="waiting">Đang chờ</MenuItem>
            <MenuItem value="reject">Đã hủy</MenuItem>
          </TextField>

        </FormControl>

        <Autocomplete
          sx={{ width: 400, marginRight: 2 }}
          getOptionLabel={(option) => option.name || ''}
          options={userOptions}
          loading={loading}
          onChange={handleSearchChange}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderOption={(props, option) => (
            <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={option.avatar} sx={{ mr: 1 }} />
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tìm kiếm khách hàng"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: 40,
                },
                '& .MuiInputLabel-root': {
                  transform: 'translate(14px, 8px) scale(1)',
                  '&.Mui-focused, &.MuiFormLabel-filled': {
                    transform: 'translate(14px, -9px) scale(0.75)',
                  }
                },
                '& .MuiSelect-select': {
                  paddingTop: '8px',
                  paddingBottom: '8px',
                }
              }}
            />
          )}
        />

        <Button
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            whiteSpace: 'nowrap',
          }}
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          Tạo mới
        </Button>
      </Box>

      <Box sx={{ flexGrow: 1, overflow: 'auto', minHeight: 0 }}>
        {/* <CardCustom columns={columns} data={packages} /> */}
        <TableContainer component={Paper} sx={{ maxHeight: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Mã giao dịch</StyledTableCell>
                <StyledTableCell align="center">Khách hàng</StyledTableCell>
                <StyledTableCell align="center" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                  Tên gói
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                  Thời hạn (tháng)
                </StyledTableCell>
                <StyledTableCell align="center">Giá (VND)</StyledTableCell>
                <StyledTableCell align="center">Trạng thái </StyledTableCell>
                <StyledTableCell align="center">Chức năng</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {packages &&
                packages
                  .filter((pack) => pack.owner !== null)
                  .map((pack, index) => <PackageItem key={pack.id} pack={pack} index={index} />)}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <CustomTable columns={columns} data={packages} /> */}
      </Box>

      <Box sx={{
        mt: 2,
        py: 2,
        // borderTop: 1,
        // borderColor: 'divider',
        backgroundColor: 'background.paper',
        display: 'flex',
        justifyContent: 'center',
        flexShrink: 0
      }}>
        {/* <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          // color="primary"
          variant="outlined"
          shape="rounded"
          siblingCount={1}
        /> */}
        {/* <TablePagination
          component="div"
          count={100}
          page={currentPage}
          rowsPerPage={5}
          onPageChange={handlePageChange}

          onRowsPerPageChange={''}
        /> */}
      </Box>

      <ModalCustom open={isOpen} onClose={handleClose} size="large" placement="center">
        <PackageCreate setIsOpen={setIsOpen} />
      </ModalCustom>
    </Container>
  );
};

export default Package;

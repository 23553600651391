import { Box, Card, CardContent, Chip, IconButton, Typography } from "@mui/material"
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import InventoryIcon from '@mui/icons-material/Inventory';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MessageIcon from '@mui/icons-material/Message';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TranslateIcon from '@mui/icons-material/Translate';
import { formatCurrency, formatDate } from "../../../../utils/formatters";
const PackageInfo = ({ data, commission }) => {
    return (
        <Card sx={{
            width: "100%",
            border: '1px solid #e0e0e0',
            borderRadius: '10px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.04)',
            bgcolor: '#F0F2FA',

        }}>
            <CardContent>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}>
                    <Typography color="text.secondary">Gói dịch vụ</Typography>
                    <IconButton>
                        <InventoryIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
                    <Box >
                        <Typography variant="h5" fontWeight={700} mb={1} color="#4355F9" fontSize="1.5rem">
                            {data?.package?.info?.name}
                        </Typography>
                        <Typography color="text.secondary" mb={3} sx={{ opacity: 0.8 }}>
                            Mã: {data?.package?.code}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                        {data?.package?.status === 'active' ? <Chip
                            label="Đang hoạt động"
                            size="small"
                            sx={{
                                bgcolor: '#1EC670',
                                color: 'white',
                                borderRadius: '8px',
                                fontSize: '0.75rem',
                                fontWeight: 600,
                                '& .MuiChip-label': {
                                    px: 2,
                                    py: 0.5
                                }
                            }}
                        /> : <Chip
                            label="Không hoạt động"
                            size="small"
                            sx={{
                                bgcolor: '#E74C3C',
                                color: 'white',
                                borderRadius: '8px',
                                fontSize: '0.75rem',
                                fontWeight: 600,
                                '& .MuiChip-label': {
                                    px: 2,
                                    py: 0.5
                                }
                            }}
                        />}
                        {commission?.isFirstPurchase === true ? <Chip
                            label="Mua đầu tiên"
                            size="small"
                            sx={{
                                bgcolor: '#42a5f5',
                                color: 'white',
                                borderRadius: '8px',
                                fontSize: '0.75rem',
                                fontWeight: 600,
                                '& .MuiChip-label': {
                                    px: 2,
                                    py: 0.5
                                }
                            }} /> : commission?.isFirstPurchase === false ? <Chip
                                label="Mua từ lần 2"
                                size="small"
                                sx={{
                                    bgcolor: '#29b6f6',
                                    color: 'white',
                                    borderRadius: '8px',
                                    fontSize: '0.75rem',
                                    fontWeight: 600,
                                    '& .MuiChip-label': {
                                        px: 2,
                                        py: 0.5
                                    }
                                }} />
                            : <Chip
                                label="N/A"
                                size="small"
                                color="error"
                                sx={{
                                    // bgcolor: '#29b6f6',
                                    color: 'white',
                                    borderRadius: '8px',
                                    fontSize: '0.75rem',
                                    fontWeight: 600,
                                    '& .MuiChip-label': {
                                        px: 2,
                                        py: 0.5
                                    }
                                }} />}
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row' },
                    gap: 2,
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2.5,
                        width: "100%"
                    }}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1.5, color: '#333' }}>
                            <Box sx={{
                                bgcolor: '#E2E9FF',
                                p: 1.5,
                                borderRadius: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid rgb(170, 179, 255)',
                                width: '40px',
                                height: '40px',
                                color: '#4355F9'
                            }}>$</Box>
                            <Box>
                                <Box sx={{ color: '#666', fontSize: '0.875rem' }}>Giá:</Box>
                                <Box sx={{ fontWeight: 'bold' }}>{formatCurrency(data?.package?.info?.price)}</Box>

                            </Box>
                        </Typography>

                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1.5, color: '#333' }}>
                            <Box sx={{
                                bgcolor: '#FFF4E5',
                                p: 1.5,
                                borderRadius: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '40px',
                                height: '40px',
                                color: '#FF9800',
                                border: '1px solid rgb(170, 179, 255)',

                            }}>
                                <IconButton sx={{ fontSize: '1.2rem' }}><AccessAlarmsIcon /></IconButton>

                            </Box>
                            <Box>
                                <Box sx={{ color: '#666', fontSize: '0.875rem' }}>Thời hạn:</Box>
                                <Box sx={{ fontWeight: 'bold' }}>{data?.package?.info?.duration} tháng</Box>
                            </Box>
                        </Typography>

                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1.5, color: '#333' }}>
                            <Box sx={{
                                bgcolor: '#E8F5E9',
                                p: 1.5,
                                borderRadius: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '40px',
                                height: '40px',
                                border: '1px solid rgb(170, 179, 255)',
                                color: '#4CAF50'
                            }}>
                                <IconButton sx={{ fontSize: '1.2rem' }}><SmartToyIcon /></IconButton>
                            </Box>
                            <Box>
                                <Box sx={{ color: '#666', fontSize: '0.875rem' }}>Bot tối đa:</Box>
                                <Box sx={{ fontWeight: 'bold' }}>{data?.package?.info?.maxBot}</Box>
                            </Box>
                        </Typography>

                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1.5, color: '#333' }}>
                            <Box sx={{
                                bgcolor: '#F3E5F5',
                                p: 1.5,
                                borderRadius: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '40px',
                                height: '40px',
                                border: '1px solid rgb(170, 179, 255)',
                                color: '#9C27B0'

                            }}>
                                <IconButton sx={{ fontSize: '1.2rem' }}><InsertDriveFileIcon /></IconButton>
                            </Box>
                            <Box>
                                <Box sx={{ color: '#666', fontSize: '0.875rem' }}>File tối đa:</Box>
                                <Box sx={{ fontWeight: 'bold' }}>{data?.package?.info?.maxFile}</Box>
                            </Box>
                        </Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2.5,
                        width: "100%"
                    }}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1.5, color: '#333' }}>
                            <Box sx={{
                                bgcolor: '#E3F2FD',
                                p: 1.5,
                                borderRadius: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '40px',
                                border: '1px solid rgb(170, 179, 255)',
                                height: '40px',
                                color: '#2196F3'
                            }}>
                                <IconButton sx={{ fontSize: '1.2rem' }}><EventAvailableIcon /></IconButton>
                            </Box>
                            <Box>
                                <Box sx={{ color: '#666', fontSize: '0.875rem' }}>Bắt đầu:</Box>
                                <Box sx={{ fontWeight: 'bold' }}>{formatDate(data?.package?.startDate)}</Box>
                            </Box>
                        </Typography>

                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1.5, color: '#333' }}>
                            <Box sx={{
                                bgcolor: '#FFEBEE',
                                p: 1.5,
                                borderRadius: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '40px',
                                height: '40px',
                                border: '1px solid rgb(170, 179, 255)',
                                color: '#F44336'
                            }}>
                                <IconButton sx={{ fontSize: '1.2rem' }}><EventBusyIcon /></IconButton>
                            </Box>
                            <Box>
                                <Box sx={{ color: '#666', fontSize: '0.875rem' }}>Kết thúc:</Box>
                                <Box sx={{ fontWeight: 'bold' }}>{formatDate(data?.package?.endDate)}</Box>
                            </Box>
                        </Typography>

                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1.5, color: '#333' }}>
                            <Box sx={{
                                bgcolor: '#FFF3E0',
                                p: 1.5,
                                borderRadius: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '40px',
                                border: '1px solid rgb(170, 179, 255)',
                                height: '40px',
                                color: '#FF9800'
                            }}>
                                <IconButton sx={{ fontSize: '1.2rem' }}><MessageIcon /></IconButton>
                            </Box>
                            <Box>
                                <Box sx={{ color: '#666', fontSize: '0.875rem' }}>Tin nhắn tối đa:</Box>
                                <Box sx={{ fontWeight: 'bold' }}>{data?.package?.info?.maxMessage}</Box>
                            </Box>
                        </Typography>

                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1.5, color: '#333' }}>
                            <Box sx={{
                                bgcolor: '#FFF3E0',
                                p: 1.5,
                                borderRadius: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '40px',
                                border: '1px solid rgb(170, 179, 255)',
                                height: '40px',
                                color: '#FF9800'
                            }}>
                                <IconButton sx={{ fontSize: '1.2rem' }}>< TranslateIcon /></IconButton>
                            </Box>
                            <Box>
                                <Box sx={{ color: '#666', fontSize: '0.875rem' }}>Kí tự tối đa:</Box>
                                <Box sx={{ fontWeight: 'bold' }}>{data?.package?.info?.maxCharacter}</Box>
                            </Box>
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}

export default PackageInfo
import { Box, Card, Grid, Typography } from "@mui/material"
import MainTitle from "../../../components/partials/MainTitle"

const BotGuideContent = () => {
    return (
        <Card sx={{ mt: 3, p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box>
                        <MainTitle title="Kịch bản mẫu" />
                        <Card sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom sx={{ mb: 3, fontWeight: 'bold' }}>
                                Bạn là một trợ lý chuyên nghiệp, nhiệm vụ của bạn là trả lời mọi câu hỏi của người dùng về thông tin trên website họ đang truy cập.
                            </Typography>

                            <Typography variant="h6" gutterBottom sx={{ mt: 2, color: '#1976d2', fontWeight: 'bold' }}>
                                1. Phong cách giao tiếp
                            </Typography>
                            <Box component="ul" sx={{ pl: 2, mb: 3 }}>
                                <Typography component="li">Sử dụng văn phong tự nhiên, chuyên nghiệp</Typography>
                                <Typography component="li">Trả lời ngắn gọn, đúng trọng tâm, không lan man</Typography>
                                <Typography component="li">Nếu nội dung dài, hãy tóm tắt các ý chính</Typography>
                            </Box>

                            <Typography variant="h6" gutterBottom sx={{ mt: 2, color: '#1976d2', fontWeight: 'bold' }}>
                                2. Thu thập thông tin người dùng
                            </Typography>
                            <Box component="ul" sx={{ pl: 2, mb: 3 }}>
                                <Typography component="li">
                                    Khi người dùng cung cấp thông tin cá nhân (số điện thoại (phone_value), họ tên (name_value), địa chỉ (address_value)), hãy trích xuất và trả về định dạng như sau ở cuối câu:
                                    <Box sx={{ bgcolor: '#f5f5f5', p: 1, mt: 1, borderRadius: 1, fontFamily: 'monospace' }}>
                                        $info[{'"address": address_value, "phone": phone_value, "name": name_value'}]
                                    </Box>
                                </Typography>
                                <Typography component="li">Nếu thiếu thông tin nào đó, để giá trị null</Typography>
                                <Typography component="li">Chỉ trả về $info[] khi phát hiện được ít nhất một thông tin</Typography>
                                <Typography component="li">Khéo léo và tinh tế khi xin thêm thông tin từ người dùng</Typography>
                            </Box>

                            <Typography variant="h6" gutterBottom sx={{ mt: 2, color: '#1976d2', fontWeight: 'bold' }}>
                                3. Xử lý câu hỏi
                            </Typography>
                            <Box component="ul" sx={{ pl: 2, mb: 3 }}>
                                <Typography component="li">Khi không đủ dữ liệu: Sử dụng Files Search để tìm thông tin phù hợp</Typography>
                                <Typography component="li">
                                    Với câu hỏi về hình ảnh:
                                    <Box component="ul" sx={{ pl: 2, mt: 1 }}>
                                        <Typography component="li">Sử dụng Files Search để tìm thông tin</Typography>
                                        <Typography component="li">
                                            <Box sx={{ bgcolor: '#f5f5f5', p: 1, mt: 1, borderRadius: 1, fontFamily: 'monospace' }}>
                                                + Nếu có đường dẫn ảnh, trả về định dạng: {'<img src="đường_dẫn_ảnh">'}
                                            </Box>
                                        </Typography>
                                    </Box>
                                </Typography>
                            </Box>

                            <Typography variant="body1" sx={{ mt: 3, fontStyle: 'italic', color: '#666' }}>
                                Luôn đảm bảo câu trả lời chính xác, ngắn gọn và hữu ích cho người dùng.
                            </Typography>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box>
                        <MainTitle title="Video hướng dẫn cài đặt bot" />
                        <Card sx={{ p: 2, height: '100%' }}>
                            <Box sx={{
                                position: 'relative',
                                paddingTop: '56.25%', // 16:9 Aspect Ratio
                                width: '100%'
                            }}>
                                <iframe
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        border: 0
                                    }}
                                    src="https://www.youtube.com/embed/Kb4dW77j9Tw"
                                    title="Hướng dẫn cài đặt bot"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </Box>
                        </Card>
                    </Box>
                </Grid>
            </Grid >
        </Card >
    )
}

export default BotGuideContent
import { Box, Button, Typography, Divider, IconButton, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import GoogleIcon from '@mui/icons-material/Google';
import ChatIcon from '@mui/icons-material/Chat';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ForumIcon from '@mui/icons-material/Forum';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SaveIcon from '@mui/icons-material/Save';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShareIcon from '@mui/icons-material/Share';
import cookieUtil from '../../utils/cookieUtil';
import logoSchat from '../../assets/media/logos/logoschat.png';
import { fadeIn, float, gradient, pulse } from '../../utils/animations';
import { useTheme } from '@emotion/react';
const SignInWithGoogle = () => {
    const theme = useTheme();
    const [params, setParams] = useState({ redirect: "", referralCode: "" });
    const isMobileOrTablet = useMediaQuery('(max-width:1200px)');
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const redirectUrl = urlParams.get('redirect');
        const referralCode = cookieUtil.getCookie('referral-code');
        console.log('referralCode:', referralCode);
        if (redirectUrl) setParams({ ...params, redirect: redirectUrl });
        if (referralCode) setParams({ ...params, referralCode: referralCode });
    }, []);
    const handleLoginWithGoogle = () => {
        const queryString = new URLSearchParams(params).toString();

        const url = `${process.env.REACT_APP_GG_AUTH_URL}?${queryString}`;

        window.open(url, '_self');
    };
    const handlePrivacyPolicyClick = () => {
        window.location.href = 'https://schat.vn/chinh-sach-bao-mat/';
    };
    const handlesecurityClick = () => {
        window.location.href = 'https://schat.vn/dieu-khoan-su-dung/';
    };
    const features = [
        {
            icon: <SmartToyIcon sx={{ fontSize: 40, color: '#fff' }} />,
            title: 'Trí tuệ nhân tạo thông minh',
            description: 'Sử dụng trí tuệ nhân tạo để không chỉ trả lời các câu hỏi cơ bản mà còn có khả năng học hỏi từ những câu hỏi phức tạp'
        },
        {
            icon: <InventoryIcon sx={{ fontSize: 40, color: '#fff' }} />,
            title: 'Hỗ trợ thông tin sản phẩm',
            description: 'Đưa ra hình ảnh và thông tin sản phẩm để hỗ trợ quá trình chăm sóc và bán hàng giúp khách hàng hình dung và hiểu rõ hơn về sản phẩm'
        },
        {
            icon: <AssignmentIcon sx={{ fontSize: 40, color: '#fff' }} />,
            title: 'Quy trình bán hàng tự động',
            description: 'Tạo ra quy trình bán hàng chuẩn hóa 100% do AI thực hiện: tự động lọc số điện thoại & địa chỉ khách hàng, chốt khách, gọi điện xác nhận thông tin khách hàng, lên đơn'
        },
        {
            icon: <ForumIcon sx={{ fontSize: 40, color: '#fff' }} />,
            title: 'Chăm sóc khách hàng',
            description: 'Tự động nhắn tin chăm sóc khách hàng sau bán, thực hiện các chiến dịch remarketing, hậu mãi bài bản'
        },
        {
            icon: <RocketLaunchIcon sx={{ fontSize: 40, color: '#fff' }} />,
            title: 'Theo dõi đơn hàng',
            description: 'Theo dõi đơn hàng trên hệ thống và báo cáo tiến trình chuyển hàng tới tay khách hàng'
        },
        {
            icon: <SaveIcon sx={{ fontSize: 40, color: '#fff' }} />,
            title: 'Lưu trữ lịch sử',
            description: 'Lưu lại toàn bộ lịch sử chat và tiếp tục duy trì mạch câu chuyện liên tục ngay cả khi khách hàng thoát ra khỏi nền tảng chat'
        },
        {
            icon: <AccessTimeIcon sx={{ fontSize: 40, color: '#fff' }} />,
            title: 'Hỗ trợ 24/7',
            description: 'Khả năng tương tác với khách hàng không giới hạn số lượng thời gian và ngôn ngữ (chat liên tục 24/7 với mọi ngôn ngữ)'
        },
        {
            icon: <ShareIcon sx={{ fontSize: 40, color: '#fff' }} />,
            title: 'Tích hợp đa nền tảng',
            description: 'Hỗ trợ cho việc bán hàng đa nền tảng nhờ khả năng tích hợp vào nhiều nền tảng như Website, Facebook, Tiktok, Zalo, Whatsapp, ...'
        }

    ];

    return (
        <Box sx={{
            display: 'flex',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'auto',
            flexDirection: isMobileOrTablet ? 'column' : 'row',
            gap: { xs: 0, md: 2 },
            width: '100%',
            bgcolor: '#fff',
            animation: `${fadeIn} 1s ease-out`,
            overflow: 'auto'
        }}>
            <Box sx={{
                width: isMobileOrTablet ? '100%' : '30%',
                minHeight: isMobileOrTablet ? 'auto' : '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: { xs: 2, md: 2.5 },
                padding: { xs: 3, md: 4 },
                // backgroundColor: '#fff',
                // boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                animation: `${fadeIn} 1s ease-out`,
                position: 'relative',
                zIndex: 2
            }}>
                <Box sx={{
                    mb: { xs: 1, sm: 2 },
                    animation: `${float} 3s ease-in-out infinite`
                }}>
                    {/* <ChatIcon sx={{
                        fontSize: { xs: 40, sm: 45 },
                        color: 'primary.main',
                        animation: `${pulse} 2s ease-in-out infinite`
                    }} /> */}
                    <Box >
                        <img style={{
                            width: '100px',
                        }} src={logoSchat} alt="" />
                    </Box>
                </Box>

                <Typography variant="h4" component="h1" sx={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                    backgroundSize: '200% 200%',
                    animation: `${gradient} 3s ease infinite`,
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    fontSize: { xs: '1.5rem', sm: '1.8rem' },
                    maxWidth: '90%'
                }}>
                    Chào mừng bạn đến với Schat
                </Typography>

                <Typography variant="body1"
                    sx={{
                        color: 'text.secondary',
                        textAlign: 'center',
                        mb: { xs: 2, sm: 1 },
                        maxWidth: '85%',
                        animation: `${fadeIn} 1s ease-out 0.3s backwards`,
                        fontSize: { xs: '0.85rem', sm: '0.9rem' }
                    }}>
                    Đăng nhập để tiếp tục trải nghiệm dịch vụ của chúng tôi
                </Typography>

                <Button
                    onClick={handleLoginWithGoogle}
                    variant="contained"
                    color="primary"
                    startIcon={<GoogleIcon />}
                    size="large"
                    fullWidth
                    sx={{
                        py: { xs: 1.2, sm: 1.5 },
                        px: { xs: 2, sm: 3 },
                        borderRadius: 2,
                        textTransform: 'none',
                        fontSize: { xs: '0.85rem', sm: '0.9rem' },
                        mb: 1,
                        maxWidth: '85%',
                        animation: `${fadeIn} 1s ease-out 0.6s backwards`,
                        transition: 'all 0.3s ease',
                        '&:hover': {
                            transform: 'scale(1.02)',
                            boxShadow: '0 4px 20px rgba(0,0,0,0.12)'
                        }
                    }}
                >
                    <span>Đăng nhập với Google</span>
                </Button>

                <Typography variant="body2" sx={{
                    color: 'text.secondary',
                    mt: { xs: 1, sm: 2 },
                    textAlign: 'center',
                    animation: `${fadeIn} 1s ease-out 0.9s backwards`,
                    fontSize: { xs: '0.75rem', sm: '0.8rem' },
                    maxWidth: '85%'
                }}>
                    Bằng việc đăng nhập, bạn đồng ý với{' '}
                    <Typography component="span"
                        onClick={handlesecurityClick}
                        sx={{
                            color: 'primary.main',
                            cursor: 'pointer',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                color: 'primary.dark',
                                textDecoration: 'underline'
                            }
                        }}>
                        Điều khoản dịch vụ
                    </Typography>
                    {' '}và{' '}
                    <Typography component="span"
                        onClick={handlePrivacyPolicyClick} sx={{
                            color: 'primary.main',
                            cursor: 'pointer',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                color: 'primary.dark',
                                textDecoration: 'underline'
                            }
                        }}>
                        Chính sách bảo mật
                    </Typography>
                </Typography>
            </Box>

            <Box sx={{
                flex: 1,
                minHeight: isMobileOrTablet ? 'auto' : '100vh',
                background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
                backgroundSize: '200% 200%',
                animation: `${gradient} 15s ease infinite`,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                overflow: 'hidden',
                padding: { xs: '30px 15px', md: '40px 20px' },
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'radial-gradient(circle at 50% 50%, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 50%, transparent 100%)',
                    backgroundSize: '200% 200%',
                    animation: `${gradient} 15s ease infinite`,
                }
            }}>
                <Typography variant="h3" sx={{
                    fontWeight: 'bold',
                    mb: { xs: 1, md: 2 },
                    textAlign: 'center',
                    position: 'relative',
                    zIndex: 1,
                    animation: `${fadeIn} 1s ease-out`,
                    fontSize: { xs: '1.8rem', md: '2rem' }
                }}>
                    Schat
                </Typography>

                <Typography variant="h6" sx={{
                    maxWidth: { xs: '95%', md: '600px' },
                    textAlign: 'center',
                    mb: { xs: 3, md: 4 },
                    opacity: 0.9,
                    position: 'relative',
                    zIndex: 1,
                    animation: `${fadeIn} 1s ease-out 0.3s backwards`,
                    fontSize: { xs: '0.9rem', md: '1rem' },
                    px: { xs: 1, md: 0 }
                }}>
                    Nền tảng trò chuyện thông minh tiên tiến nhất với công nghệ AI
                </Typography>

                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                        xs: '1fr',
                        md: 'repeat(2, 1fr)'
                    },
                    gap: { xs: 2, md: 2.5 },
                    maxWidth: '1200px',
                    position: 'relative',
                    zIndex: 1,
                    width: '100%',
                    px: { xs: 0, md: 2 }
                }}>
                    {features.map((feature, index) => (
                        <Box key={index} sx={{
                            textAlign: 'left',
                            padding: { xs: 2, md: 2.5 },
                            borderRadius: 3,
                            backgroundColor: 'rgba(255,255,255,0.1)',
                            backdropFilter: 'blur(10px)',
                            transition: 'all 0.3s ease',
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: { xs: 2, md: 2.5 },
                            animation: `${fadeIn} 0.5s ease-out ${index * 0.1}s backwards`,
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                backgroundColor: 'rgba(255,255,255,0.15)',
                                boxShadow: '0 4px 20px rgba(0,0,0,0.2)'
                            }
                        }}>
                            <Box sx={{
                                backgroundColor: 'rgba(255,255,255,0.1)',
                                borderRadius: '15px',
                                padding: { xs: '12px', md: '15px' },
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                    backgroundColor: 'rgba(255,255,255,0.2)',
                                    transform: 'rotate(5deg)'
                                }
                            }}>
                                {React.cloneElement(feature.icon, {
                                    sx: {
                                        fontSize: { xs: 28, md: 32 },
                                        color: '#fff'
                                    }
                                })}
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <Typography variant="h6" sx={{
                                    mb: 0.5,
                                    fontSize: { xs: '1rem', md: '1.1rem' },
                                    fontWeight: 600,
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        color: '#90caf9'
                                    }
                                }}>
                                    {feature.title}
                                </Typography>
                                <Typography variant="body2" sx={{
                                    opacity: 0.9,
                                    lineHeight: 1.5,
                                    fontSize: { xs: '0.8rem', md: '0.85rem' },
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        opacity: 1
                                    }
                                }}>
                                    {feature.description}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default SignInWithGoogle;
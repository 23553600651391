import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    accountReferrals: [],
    totalPages: 0,
    total: 0,
    isLoading: true,
    error: null,
    ui: {
        showAlert: false,
        alertMessage: '',
        alertSeverity: 'error',
    },
    filters: {
        page: 1,
        search: '',
        limit: 10,
        dateFilter: 'all',
        startDate: null,
        endDate: null,
        affiliateFilter: 'all',
        paidFilter: 'all',
        refresh: 0,
    }
}

const accountReferralsSlice = createSlice({
    name: 'accountReferrals',
    initialState,
    reducers: {
        getAccountReferrals: (state, action) => {
            state.accountReferrals = action.payload.data
            state.totalPages = action.payload.pagination.totalPages
            state.total = action.payload.pagination.total
            state.isLoading = false
            state.error = null
        },
        updateAccountReferrals: (state, action) => {
            state.filters = { ...state.filters, ...action.payload };
        },
        updateUI: (state, action) => {
            state.ui = { ...state.ui, ...action.payload };
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        },
        triggerRefresh: (state) => {
            state.filters.refresh += 1;
        }
    }
})
export const {
    getAccountReferrals,
    updateAccountReferrals,
    setLoading,
    setError,
    updateUI,
    triggerRefresh
} = accountReferralsSlice.actions
export default accountReferralsSlice.reducer;

import { Card, Grid, Box } from '@mui/material'
import React from 'react'
import MainTitle from '../../../components/partials/MainTitle'
import BotFileTranning from './BotFileTranning'
import BotUrlTranning from './BotUrlTranning'
import BotInformation from './BotInformation'

const BotSettingContent = () => {
    return (
        <Card sx={{ mt: 3, p: 2 }}>
            <MainTitle title="Cài đặt Bot" />
            <Grid container spacing={3} sx={{ height: '100%' }}>
                <Grid item xl={6} lg={6} md={12} sm={12} width={'100%'}>
                    <BotInformation />
                </Grid>
                <Grid item xl={6} lg={6} md={12} sm={12} width={'100%'}>
                    <Box display={'flex'} flexDirection={'column'} >
                        <Box sx={{ mb: 3 }}>
                            <BotFileTranning />
                        </Box>
                        <Box>
                            <BotUrlTranning />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    )
}

export default BotSettingContent
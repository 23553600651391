import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import packageService from '../../services/packageService';

// Thunk để lấy danh sách packages
export const fetchPackages = createAsyncThunk('packages/fetchPackages', async (params, thunkAPI) => {
  try {
    console.log(params);
    const response = await packageService.getAllPackagesByAdmin(params);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Thunk để xóa package
export const deletePackage = createAsyncThunk('packages/deletePackage', async (id, thunkAPI) => {
  try {
    await packageService.deletePackage(id);
    return { id };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Thunk để tạo package mới
export const createPackage = createAsyncThunk('packages/createPackage', async (packageData, thunkAPI) => {
  try {
    const response = await packageService.createPackage(packageData);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Thunk để cập nhật package
export const updatePackage = createAsyncThunk('packages/updatePackage', async ({ id, status }, thunkAPI) => {
  try {
    console.log(id);
    const response = await packageService.updatePackage(id, status);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


const packageSlice = createSlice({
  name: 'packages',
  initialState: {
    packages: [],
    currentPage: 1,
    pageSize: 100,
    total: 0,
    status: 'idle',
    error: null,
  },
  reducers: {
    setPage(state, action) {
      state.currentPage = action.payload;
    },
    setLimit(state, action) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPackages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.packages = action.payload.packages || [];
        state.currentPage = action.payload.currentPage || 1;
        state.pageSize = action.payload.pageSize || 100;
        state.total = action.payload.total || 0;
      })
      .addCase(fetchPackages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deletePackage.fulfilled, (state, action) => {
        state.packages = state.packages.filter(pkg => pkg.id !== action.payload.id);
      })
      .addCase(createPackage.fulfilled, (state, action) => {
        state.packages.push(action.payload);
      })
      .addCase(updatePackage.fulfilled, (state, action) => {
        const index = state.packages.findIndex(pkg => pkg.id === action.payload.id);
        if (index !== -1) {
          state.packages[index] = action.payload;
        }
      });
  },
});

export const { setPage, setLimit } = packageSlice.actions;
export default packageSlice.reducer;

import { Button, Card, CardContent } from '@mui/material';
import { useEffect, useState } from 'react';
import packageService from '../../../services/packageService';
import AccountPackageTable from './AccountPackageTable';
import CardContentHeader from '../../../components/cards/CardContentHeader';
import ModalCustom from '../../../components/modals/ModalCustom';
import CreateNewPackage from './create-package/CreateNewPackage';

const AccountPackageList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    loadPackages();
  }, []);

  const loadPackages = () => {
    setIsLoading(true);
    packageService
      .getPackages()
      .then((res) => {
        setPackages(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Card>
      <CardContent>
        <CardContentHeader
          title="Gói dịch vụ"
          subtitle={'Liên hệ: 0836991919'}
          action={
            <Button onClick={handleOpenModal} size="small" variant="contained" color="primary">
              Tạo gói mới
            </Button>
          }
        />
        <AccountPackageTable />
        <ModalCustom
          open={openModal}
          onClose={handleCloseModal}
          size="extraLarge"
          placement="center"
          children={<CreateNewPackage handleCloseModal={handleCloseModal} />}
        />
      </CardContent>
    </Card>
  );
};

export default AccountPackageList;

import httpRequest from '../../../utils/httpRequest';
const END_POINT = '/affiliate/admin';
const END_POINT_AFFILIATE = '/admin/affiliates';
const affiliateByAdmin = {
  getLevelOneAffiliates: async () => {
    const res = await httpRequest.get(`${END_POINT}/level-one`);
    return res.data;
  },

  promoteUser: async (userId) => {
    const res = await httpRequest.put(`${END_POINT_AFFILIATE}/promote/${userId}`);
    return res.data;
  },
  getAllAffiliates: async (page, limit, keyword, level, dateFilter, startDate, endDate) => {
    const res = await httpRequest.get(`${END_POINT_AFFILIATE}?page=${page}&limit=${limit}&keyword=${keyword}&level=${level}&dateFilter=${dateFilter}&startDate=${startDate}&endDate=${endDate}`);
    return res.data;
  },
  getRevenue: async (id, startDate, endDate, page, limit, sourceFilter) => {
    const res = await httpRequest.get(`${END_POINT_AFFILIATE}/${id}/revenue?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}&sourceFilter=${sourceFilter}`);
    return res.data;
  },
  updateStatus: async (id) => {
    const res = await httpRequest.put(`${END_POINT_AFFILIATE}/${id}/toggle-status`);
    return res.data;
  },
  getRelated: async (id) => {
    const res = await httpRequest.get(`${END_POINT_AFFILIATE}/${id}/related`);
    return res.data;
  },
  getAffiliateReferrals: async (id, dateFilter, page, limit, search, paidFilter, startDate, endDate, affiliateFilter) => {
    const res = await httpRequest.get(`${END_POINT_AFFILIATE}/${id}/affiliate-referrals?dateFilter=${dateFilter}&page=${page}&limit=${limit}&keyword=${search}&paidFilter=${paidFilter}&startDate=${startDate}&endDate=${endDate}&affiliateFilter=${affiliateFilter}`);
    return res.data;
  },
  getRevenueDetails: async (id) => {
    const res = await httpRequest.get(`${END_POINT_AFFILIATE}/${id}/revenue-details`);
    return res.data;
  }
};

export default affiliateByAdmin;

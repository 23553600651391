import * as React from 'react';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { createTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import LayersIcon from '@mui/icons-material/Layers';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Navigate, useNavigate, useLocation, Outlet } from 'react-router-dom';
import Package from '../containers/admin/package/Package';
import User from '../containers/admin/user/User';
import AuthGuard from './AuthGuard';
import { Typography } from '@mui/material';
import logo from '../assets/media/logos/logoschat.png'
import Dashboard from '../containers/admin/dashboard/Dashboard';
import AffiliateContainer from '../containers/admin/affiliate/AffiliateContainer';
// Navigation structure
const NAVIGATION = [
  {
    kind: 'header',
    title: 'Chức năng chính',
  },
  // {
  //   segment: 'dashboard',
  //   title: 'Trang chủ',
  //   icon: <DashboardIcon />,
  // },
  {
    segment: 'orders',
    title: 'Các gói',
    icon: <ShoppingCartIcon />,
  },
  {
    segment: 'user',
    title: 'Quản lý tài khoản Schat',
    icon: <PeopleAltIcon />,
  },

  // {
  //   segment: 'affiliate',
  //   title: 'Affiliate F1',
  //   icon: <MonetizationOnIcon />,
  // },
  {
    segment: 'affiliate',
    title: 'Quản lý tài khoản Affiliate',
    icon: <MonetizationOnIcon />,
  },
  // {
  //   kind: 'divider',
  // },
  // {
  //   kind: 'header',
  //   title: 'Quản lý dữ liệu',
  // },
  // {
  //   segment: 'reports',
  //   title: 'Thống kê',
  //   icon: <BarChartIcon />,
  //   children: [
  //     {
  //       segment: 'sales',
  //       title: 'Sales',
  //       icon: <DescriptionIcon />,
  //     },
  //     {
  //       segment: 'traffic',
  //       title: 'Traffic',
  //       icon: <DescriptionIcon />,
  //     },
  //   ],
  // },
];

// Create theme for the dashboard
const Theme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

// Main dashboard layout component
function DashboardLayoutBasic() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.replace('/admin', '') || '/';

  const router = React.useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(location.search),
      navigate: (path) => navigate(`/admin${path}`),
    };
  }, [pathname, location.search, navigate]);

  return (
    <AppProvider
      navigation={NAVIGATION}
      router={router}
      theme={Theme}
      branding={{
        logo: <img src={logo} alt="Logo" />,
        title: 'Schat'
      }}
    >
      <DashboardLayout>
        <Box sx={{ marginTop: '-30px' }}>
          <Outlet />
        </Box>
      </DashboardLayout>
    </AppProvider>
  );
}

// Define routing for the admin section
const adminRoute = {
  path: 'admin',
  element: (
    <AuthGuard requiredAdmin requiredAuth>
      <DashboardLayoutBasic />
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      element: <Navigate to="orders" />,
    },
    {
      path: 'dashboard',
      element: <Dashboard />,
    },
    {
      path: 'user',
      element: <User />,
    },
    {
      path: 'orders',
      element: <Package />,
    },
    // {
    //   path: 'affiliate',
    //   element: <Affiliate />,
    // },
    {
      path: 'affiliate',
      element: <AffiliateContainer />,
    },
  ],
};

export default adminRoute;

import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import affiliateByAdmin from "../../../services/admin/affiliate/affiliate";
import BuyerInfo from "./components/BuyerInfo";
import PackageInfo from "./components/PackageInfo";
import AffiliateInfo from "./components/AffiliateInfo";
const OriginOfRoses = ({ open, onClose, commission }) => {
    const [data, setData] = useState(null);
    const viewerId = commission?.affiliate?.id;
    useEffect(() => {
        if (!open) return;
        const fetchData = async () => {
            try {
                const res = await affiliateByAdmin.getRevenueDetails(commission?.id);
                setData(res);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [commission?.id, open])
    return (
        <Dialog open={open}
            onClose={onClose}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle>
                <Typography sx={{
                    fontSize: { xs: '1rem', lg: '1.5rem' },
                    fontWeight: 'bold'
                }} >Xem nguồn gốc hoa hồng của: {commission?.affiliate?.owner?.email} (Cấp độ: {commission?.affiliate?.level}) </Typography>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        zIndex: 1,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ display: 'flex', flexDirection: 'column', gap: 2, }}>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, gap: 2 }}>
                        <PackageInfo data={data} commission={commission} />
                        <BuyerInfo data={data} />
                    </Box>
                    <Typography fontWeight={'bold'}
                        sx={{
                            fontSize: { xs: '1rem', lg: '1.5rem' },
                        }}>
                        Chi tiết hoa hồng cho từng Affiliate
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row', }, gap: 2 }}>
                        {data?.commissions?.map((commission, index) => (
                            <AffiliateInfo viewerId={viewerId} commission={commission} key={index} data={data} />
                        ))}
                    </Box>
                </DialogContentText>
            </DialogContent>
        </Dialog >
    )
}

export default OriginOfRoses
import { Box, Card, CardContent, CardHeader, Container, Grid } from '@mui/material';
import AccountInformation from '../containers/account/information/AccoutInformation';
import { Outlet } from 'react-router-dom';

const Account = () => {
  return (

    <Box display={'flex'} flexDirection={'row'} m={3}>
      <Box>
        <AccountInformation />
      </Box>
      <Box width={'100%'}>
        <Outlet />
      </Box>
    </Box>

  );
};

export default Account;

import { Box, Card, CardContent, Chip, IconButton, Typography } from "@mui/material"
import { Avatar } from "@mui/material"
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { formatCurrency } from "../../../../utils/formatters";

const AffiliateInfo = ({ commission, index, viewerId, data }) => {
    const percent = commission?.commissionPercentage * 100;
    return (
        <Card Card key={index} sx={{
            width: "100%",
            border: '1px solid #e0e0e0',
            borderRadius: '10px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.04)',
            bgcolor: commission?.affiliate?.id === viewerId ? '#90caf9' : '#F0F2FA',
            '&:hover': {
                boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
                transform: 'translateY(-5px)',
                transition: 'all 0.3s ease-in-out',
                cursor: 'pointer',
            }

        }}>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Thông tin Affiliate cấp độ {commission?.affiliate?.level}</Typography>
                    <IconButton>
                        <PersonIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                    <Avatar src={commission?.affiliate?.owner?.avatar} sx={{ width: 70, height: 70, border: commission?.affiliate?.isActive ? '4px solid #1EC670' : '4px solid #E74C3C', boxShadow: commission?.affiliate?.isActive ? '0 1px 20px 15px rgba(226, 153, 7, 0.17)' : '0 1px 20px 15px rgba(231, 77, 60, 0.17)' }} />
                    <Typography variant="h5" fontWeight={'bold'}>{commission?.affiliate?.owner?.name}</Typography>
                    {commission?.affiliate?.isActive ? <Chip label="Hoạt động" size="small" sx={{
                        bgcolor: '#1EC670',
                        color: 'white',
                        borderRadius: '50px',
                        fontSize: '0.75rem',
                        fontWeight: 600,
                        '& .MuiChip-label': {
                            px: 4,
                            py: 0.2
                        }
                    }} /> : <Chip label="Không hoạt động" size="small" sx={{
                        bgcolor: '#E74C3C',
                        color: 'white',
                        borderRadius: '50px',
                        fontSize: '0.75rem',
                        fontWeight: 600,
                        '& .MuiChip-label': {
                            px: 4,
                            py: 0.2
                        }
                    }} />}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                    <Typography sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1.5, color: '#333',
                        width: '100%',
                        bgcolor: '#F0F2FA',
                        border: '1px solid #e0e0e0',
                        borderRadius: '10px',
                        boxShadow: '0 1px 4px 1px rgba(11, 1, 1, 0.14)',
                        p: 1,
                        '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: '0 1px 10px 3px rgba(11, 1, 1, 0.14)',
                            transition: 'all 0.3s ease-in-out',
                            cursor: 'pointer',
                            bgcolor: '#b3e5fc'
                        }
                    }}>
                        <Box sx={{
                            bgcolor: '#FFF4E5',
                            p: 1.5,
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '40px',
                            height: '40px',
                            color: '#FF9800',
                            border: '1px solid rgb(170, 179, 255)',

                        }}>
                            <IconButton sx={{ fontSize: '1.2rem' }}><EmailIcon /></IconButton>

                        </Box>
                        <Box>
                            <Box sx={{ color: '#666', fontSize: '0.875rem' }}>Email:</Box>
                            <Box sx={{ fontWeight: 'bold', fontSize: { xs: '0.85rem', lg: '0.875rem' } }}>{commission?.affiliate?.owner?.email}</Box>
                        </Box>
                    </Typography>
                    <Typography sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1.5, color: '#333',
                        width: '100%',
                        bgcolor: '#a7ffeb',
                        border: '1px solid #e0e0e0',
                        borderRadius: '10px', boxShadow: '0 1px 4px 1px rgba(11, 1, 1, 0.14)',
                        p: 1,
                        '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: '0 1px 10px 3px rgba(11, 1, 1, 0.14)',
                            transition: 'all 0.3s ease-in-out',
                            cursor: 'pointer',
                            bgcolor: '#b3e5fc'
                        }
                    }}>
                        <Box sx={{
                            bgcolor: '#f3e5f5',
                            p: 1.5,
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '40px',
                            height: '40px',
                            color: '#FF9800',
                            border: '1px solid rgb(170, 179, 255)',

                        }}>
                            <IconButton sx={{ fontSize: '1.2rem' }}><PhoneIcon /></IconButton>

                        </Box>
                        <Box>
                            <Box sx={{ color: '#666', fontSize: '0.875rem' }}>Số điện thoại:</Box>
                            <Box sx={{ fontWeight: 'bold', fontSize: { xs: '0.85rem', lg: '0.875rem' } }}>{commission?.affiliate?.owner?.phone === undefined ? "Chưa cập nhật" : commission?.affiliate?.owner?.phone}</Box>
                        </Box>
                    </Typography>
                    <Typography sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1.5, color: '#333',
                        width: '100%',
                        bgcolor: '#fffde7',
                        border: '1px solid #e0e0e0',
                        borderRadius: '10px',
                        boxShadow: '0 1px 4px 1px rgba(11, 1, 1, 0.14)',
                        p: 1,
                        '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: '0 1px 10px 3px rgba(11, 1, 1, 0.14)',
                            transition: 'all 0.3s ease-in-out',
                            cursor: 'pointer',
                            bgcolor: '#b3e5fc'
                        }
                    }}>
                        <Box sx={{
                            bgcolor: '#b3e5fc',
                            p: 1.5,
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '40px',
                            height: '40px',
                            color: '#FF9800',
                            border: '1px solid rgb(170, 179, 255)',

                        }}>
                            <IconButton sx={{ fontSize: '1.2rem' }}><CurrencyExchangeIcon /></IconButton>
                        </Box>
                        <Box>
                            <Box sx={{ color: '#666', fontSize: '0.875rem' }}>Hoa hồng:</Box>
                            <Box sx={{ fontWeight: 'bold', fontSize: { xs: '0.85rem', lg: '0.875rem' } }}>
                                {commission?.commissionPercentage === null
                                    ? `N/A x ${formatCurrency(data?.package?.info?.price)} = ${formatCurrency(commission?.amount)}`
                                    : `${percent}% x ${formatCurrency(data?.package?.info?.price)} = ${formatCurrency(commission?.amount)}`
                                }
                            </Box>
                        </Box>
                    </Typography>
                    <Typography sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1.5, color: '#333',
                        width: '100%',
                        bgcolor: '#f5f5f5',
                        border: '1px solid #e0e0e0',
                        borderRadius: '10px', boxShadow: '0 1px 4px 1px rgba(11, 1, 1, 0.14)',
                        p: 1,
                        '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: '0 1px 10px 3px rgba(11, 1, 1, 0.14)',
                            transition: 'all 0.3s ease-in-out',
                            cursor: 'pointer',
                            bgcolor: '#b3e5fc'
                        }
                    }}>
                        <Box sx={{
                            bgcolor: '#f3e5f5',
                            p: 1.5,
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '40px',
                            height: '40px',
                            color: '#FF9800',
                            border: '1px solid rgb(170, 179, 255)',

                        }}>
                            <IconButton sx={{ fontSize: '1.2rem' }}><CurrencyExchangeIcon /></IconButton>

                        </Box>
                        <Box>
                            <Box sx={{ color: '#666', fontSize: '0.875rem' }}>Tổng thu nhập:</Box>
                            <Box sx={{ fontWeight: 'bold', fontSize: { xs: '0.85rem', lg: '0.875rem' } }}>{formatCurrency(commission?.affiliate?.totalEarning)}</Box>
                        </Box>
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    )
}

export default AffiliateInfo
import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    affiliates: [],
    selectedAffiliate: null,
    earningData: null,
    accountData: null,
    totalPages: 0,
    total: 0,
    isLoading: true,
    error: null,
    ui: {
        anchorE1: null,
        selecteId: null,
        openDialog: false,
        openEarningDialog: false,
        openAccountDialog: false,
        openConfirmDialog: false,
        showAlert: false,
        alertMessage: '',
        alertSeverity: 'error',
        affiliateUpdate: null,
    },
    filters: {
        page: 1,
        limit: 10,
        search: '',
        level: '',
        endDate: null,
        startDate: null,
        dateFilter: 'all',
        refresh: 0,
    },
}
const affiliateSlice = createSlice({
    name: 'affiliate',
    initialState,
    reducers: {
        getAffiliates: (state, action) => {
            state.affiliates = action.payload.data
            state.totalPages = action.payload.pagination.totalPages
            state.total = action.payload.pagination.total
            state.isLoading = false
            state.error = null
        },
        setSelectedAffiliate: (state, action) => {
            state.selectedAffiliate = action.payload
        },
        setEarningData: (state, action) => {
            state.earningData = action.payload
        },
        setAccountData: (state, action) => {
            state.accountData = action.payload
        },
        updateAffiliate: (state, action) => {
            state.filters = { ...state.filters, ...action.payload };
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload

            state.isLoading = false
        },
        updateUi: (state, action) => {
            state.ui = { ...state.ui, ...action.payload }
        },
        resetUi: (state) => {
            state.ui = initialState.ui
        },
        resetFilters: (state) => {
            state.filters = initialState.filters
        },
        triggerRefresh: (state) => {
            state.filters.refresh += 1;
        }

    }

})
export const {
    getAffiliates,
    setSelectedAffiliate,
    setEarningData,
    setAccountData,
    updateAffiliate,
    setLoading,
    setError,
    updateUi,
    resetUi,
    resetFilters,
    triggerRefresh,
} = affiliateSlice.actions
export default affiliateSlice.reducer
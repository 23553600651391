import { Box, TextField, MenuItem, useTheme, useMediaQuery, Tooltip } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch, useSelector } from "react-redux";
import { updateAffiliate } from "../../../../reducers/slices/affiliateSlice";
import { debounce } from "lodash";
import dayjs from 'dayjs';
import { useState, useEffect } from "react";

const AffiliateFilters = () => {
    const dispatch = useDispatch();
    const { filters } = useSelector(state => state.affiliate);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [tempStartDate, setTempStartDate] = useState(null);
    const [tempEndDate, setTempEndDate] = useState(null);

    const handleSearchChange = debounce((e) => {
        dispatch(updateAffiliate({ search: e.target.value, page: 1 }))
    }, 1000)

    const handleLevelChange = (event) => {
        dispatch(updateAffiliate({ level: event.target.value }));
    };

    const handleFilterTypeChange = (event) => {
        const value = event.target.value;
        dispatch(updateAffiliate({ dateFilter: value }));
        setTempStartDate(null);
        setTempEndDate(null);
        if (value !== "custom") {
            dispatch(updateAffiliate({
                startDate: null,
                endDate: null
            }));
        }
    };

    const handleStartDateChange = (date) => {
        setTempStartDate(date);
        if (tempEndDate && date) {
            const startDateISO = dayjs(date).isValid() ? dayjs(date).startOf('day').toISOString() : null;
            const endDateISO = dayjs(tempEndDate).isValid() ? dayjs(tempEndDate).endOf('day').toISOString() : null;
            dispatch(updateAffiliate({
                startDate: startDateISO,
                endDate: endDateISO
            }));
        }
    };

    const handleEndDateChange = (date) => {
        setTempEndDate(date);
        if (tempStartDate && date) {
            const startDateISO = dayjs(tempStartDate).isValid() ? dayjs(tempStartDate).startOf('day').toISOString() : null;
            const endDateISO = dayjs(date).isValid() ? dayjs(date).endOf('day').toISOString() : null;
            dispatch(updateAffiliate({
                startDate: startDateISO,
                endDate: endDateISO
            }));
        }
    };

    // useEffect(() => {
    //     if (tempStartDate && tempEndDate) {
    //         dispatch(updateAffiliate({
    //             startDate: tempStartDate.toISOString(),
    //             endDate: tempEndDate.toISOString()
    //         }));
    //     }
    // }, [tempStartDate, tempEndDate, filters.dateFilter]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: 2,
            alignItems: isMobile ? 'stretch' : 'center',
            width: '100%',
            justifyContent: isMobile ? 'normal' : 'space-between',
        }}>
            <Box sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
                justifyContent: isMobile ? 'normal' : 'space-between',
            }}>
                <TextField
                    select
                    label={isMobile ? "Lọc" : "Lọc theo thời gian"}
                    value={filters.dateFilter}
                    onChange={handleFilterTypeChange}
                    sx={{
                        minWidth: isMobile ? 100 : 180,
                        backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                        '& .MuiOutlinedInput-root': {
                            height: 40,
                        },
                        '& .MuiInputLabel-root': {
                            transform: 'translate(14px, 8px) scale(1)',
                            '&.Mui-focused, &.MuiFormLabel-filled': {
                                transform: 'translate(14px, -9px) scale(0.75)',
                            }
                        }
                    }}
                >
                    <MenuItem value="all">Tất cả</MenuItem>
                    <MenuItem value="today">Hôm nay</MenuItem>
                    <MenuItem value="yesterday">Hôm qua</MenuItem>
                    <MenuItem value="last7days">7 ngày gần nhất</MenuItem>
                    <MenuItem value="thisMonth">Tháng này</MenuItem>
                    <MenuItem value="lastMonth">Tháng trước</MenuItem>
                    <MenuItem value="custom">Tùy chỉnh</MenuItem>
                </TextField>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Tooltip title={filters.dateFilter !== "custom"
                        ? "Chọn 'Tùy chỉnh' trong dropdown để thay đổi ngày"
                        : ""}
                        arrow
                        enterDelay={500}
                    >
                        <Box sx={{ display: "flex", gap: 2 }}>
                            <DatePicker
                                label="Ngày bắt đầu"
                                value={tempStartDate || (filters.startDate ? dayjs(filters.startDate) : null)}
                                onChange={handleStartDateChange}
                                format="DD/MM/YYYY"
                                disabled={filters.dateFilter !== "custom"}
                                readOnly={filters.dateFilter !== "custom"}
                                sx={{
                                    backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                                    width: isMobile ? '100%' : 'auto',
                                    '& .MuiOutlinedInput-root': {
                                        height: 40,
                                        opacity: filters.dateFilter !== "custom" ? 0.7 : 1
                                    },
                                    '& .MuiInputLabel-root': {
                                        transform: 'translate(14px, 8px) scale(1)',
                                        '&.Mui-focused, &.MuiFormLabel-filled': {
                                            transform: 'translate(14px, -9px) scale(0.75)',
                                        }
                                    }
                                }}
                            />
                            <DatePicker
                                label="Ngày kết thúc"
                                value={tempEndDate || (filters.endDate ? dayjs(filters.endDate) : null)}
                                onChange={handleEndDateChange}
                                format="DD/MM/YYYY"
                                disabled={filters.dateFilter !== "custom"}
                                readOnly={filters.dateFilter !== "custom"}
                                sx={{
                                    backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                                    width: isMobile ? '100%' : 'auto',
                                    '& .MuiOutlinedInput-root': {
                                        height: 40,
                                        opacity: filters.dateFilter !== "custom" ? 0.7 : 1
                                    },
                                    '& .MuiInputLabel-root': {
                                        transform: 'translate(14px, 8px) scale(1)',
                                        '&.Mui-focused, &.MuiFormLabel-filled': {
                                            transform: 'translate(14px, -9px) scale(0.75)',
                                        }
                                    }
                                }}
                            />
                        </Box>
                    </Tooltip>
                </LocalizationProvider>
            </Box>

            <Box sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
                justifyContent: isMobile ? 'normal' : 'flex-end',
            }}>
                <TextField
                    select
                    label="Cấp độ"
                    value={filters.level === "" ? "" : filters.level}
                    onChange={handleLevelChange}
                    sx={{
                        minWidth: isMobile ? 100 : 150,
                        backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                        '& .MuiOutlinedInput-root': {
                            height: 40,
                        },
                        '& .MuiInputLabel-root': {
                            transform: 'translate(14px, 8px) scale(1)',
                            '&.Mui-focused, &.MuiFormLabel-filled': {
                                transform: 'translate(14px, -9px) scale(0.75)',
                            }
                        }
                    }}
                >
                    <MenuItem value={""}>Tất cả</MenuItem>
                    <MenuItem value={1}>F1</MenuItem>
                    <MenuItem value={2}>F2</MenuItem>
                    <MenuItem value={3}>F3</MenuItem>
                </TextField>

                <TextField
                    label="Tìm kiếm bằng email hoặc tên"
                    variant="outlined"
                    fullWidth
                    value={filters.searchText}
                    onChange={handleSearchChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon color="disabled" />
                            </InputAdornment>
                        )
                    }}
                    sx={{
                        backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                        borderRadius: '8px',
                        '& .MuiOutlinedInput-root': {
                            height: 40,
                        },
                        '& .MuiInputLabel-root': {
                            transform: 'translate(14px, 8px) scale(1)',
                            '&.Mui-focused, &.MuiFormLabel-filled': {
                                transform: 'translate(14px, -9px) scale(0.75)',
                            }
                        }
                    }}
                />
            </Box>
        </Box>
    );
};

export default AffiliateFilters; 
import { Box, Button, Card, CardContent, Typography, useTheme, styled } from '@mui/material';
import CardContentHeader from '../../../components/cards/CardContentHeader';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import hljs from 'highlight.js';

const StyledCode = styled('code')(({ theme }) => ({
  display: 'block',
  width: '100%',
  fontSize: '12px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '14px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '14px'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '16px'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '18px'
  }
}));

const BotWebsiteIntegration = () => {
  const theme = useTheme();
  const botSelector = useSelector((state) => state.bot);
  const [bot, setBot] = useState(botSelector.currentBot);
  const [scriptCode, setScriptCode] = useState('');
  const [copyCode, setCopyCode] = useState('');
  const href = window.location.origin;

  useEffect(() => {
    if (botSelector.currentBot?.id) {
      setBot(botSelector.currentBot);
      exportScriptCode(botSelector.currentBot?.id);
    }
  }, [botSelector.currentBot]);

  const exportScriptCode = (botId) => {
    if (!botId) {
      return;
    }
    const code = `<script src="${href}/js/chat.js"></script>\n<script>chatAI('${botId}');</script>`;
    setCopyCode(code);
    setScriptCode(hljs.highlightAuto(code).value); // Sử dụng highlightAuto() để tô sáng cú pháp
  };

  const copyScript = () => {
    navigator.clipboard.writeText(copyCode);
  };

  return (
    <Card>
      <CardContent sx={{ p: { xs: 1, sm: 2 } }}>
        <CardContentHeader title={'Mã nhúng vào Website'} />
        <Card
          variant="outlined"
          sx={{
            border: '1px solid #ccc',
            position: 'relative',
            mb: 1,
            width: '100%',
          }}
        >
          <CardContent sx={{
            p: { xs: 1, sm: 2 },
            '& pre': {
              // position: ,
              margin: 0,
              overflowX: 'auto',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              maxWidth: '100%',
              backgroundColor: '#f5f5f5',
              borderRadius: 1,
              p: { xs: 1, sm: 2 }
            }
          }}>
            <pre>
              <StyledCode
                dangerouslySetInnerHTML={{ __html: scriptCode }}
              />
            </pre>
            <Box
              sx={{
                position: { sm: 'static', lg: 'absolute', md: 'static' },
                top: 15,
                right: 30,
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-end' },
                mt: { xs: 1.5, sm: 2 },
                mb: { xs: 0.5, sm: 1 }
              }}
            >
              <Button
                onClick={copyScript}
                variant="contained"
                sx={{
                  fontSize: { xs: '13px', sm: '14px' },
                  py: { xs: 0.5, sm: 1 },
                  px: { xs: 2, sm: 3 },
                  minWidth: { xs: '100px', sm: '120px' }
                }}
              >
                Sao chép
              </Button>
            </Box>
          </CardContent>
        </Card>
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: { xs: 11, sm: 12, md: 14 },
            color: 'text.secondary',
            mt: { xs: 0.5, sm: 1 },
            px: { xs: 0.5, sm: 0 }
          }}
        >
          Sao chép mã ở trên và dán vào thẻ {'<body></body>'} trên trang web của bạn
        </Typography>
      </CardContent>
    </Card>
  );
};

export default BotWebsiteIntegration;

import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../../reducers/slices/authSlice';
import authService from '../../../services/authService';
import toastMessage from '../../../utils/toastMessage';

const HeaderUserMenu = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.auth);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [userLogged, setUserLogged] = useState(authSelector.user);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const goToAccount = () => {
    navigate('/account');
    handleCloseUserMenu();
  };

  const goToAdmin = () => {
    navigate('/admin');
    handleCloseUserMenu();
  };

  const handleLogout = async () => {
    handleCloseUserMenu();
    dispatch(logout());
    try {
      await authService.logout();
      navigate('/auth');
    } catch (error) {
      toastMessage.showError('Đăng xuất thất bại');
    }
  };

  return (
    <>
      <Tooltip title="Tài khoản">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar 
            sizes="small" 
            src={userLogged?.avatar}
            sx={{
              border: `2px solid ${theme.palette.primary.main}`,
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.1)',
              }
            }}
          />
        </IconButton>
      </Tooltip>

      <Menu
        sx={{ 
          mt: '45px',
          '& .MuiList-root': { 
            padding: 2,
            minWidth: '280px',
          },
          '& .MuiPaper-root': {
            boxShadow: theme.shadows[3],
            borderRadius: 3
          }
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={goToAccount} sx={{ borderRadius: 2, p: 2, mb: 1 }}>
          <Box display="flex" alignItems="center" width="100%">
            <Avatar 
              sizes="small" 
              src={userLogged?.avatar}
              sx={{ width: 48, height: 48 }}
            />
            <Box sx={{ ml: 2 }} flex={1}>
              <Typography variant="subtitle1" fontWeight={600}>
                {userLogged?.name}
              </Typography>
              <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                {userLogged?.role === 'admin' ? 'Quản trị viên' : 'Người dùng'}
              </Typography>
            </Box>
          </Box>
        </MenuItem>

        <Divider sx={{ my: 1 }} />

        <MenuItem 
          onClick={goToAccount}
          sx={{
            borderRadius: 2,
            py: 1.5,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            }
          }}
        >
          <AccountCircleIcon sx={{ mr: 2, color: theme.palette.primary.main }} />
          <ListItemText primary="Tài khoản của tôi" />
        </MenuItem>

        {userLogged?.role === 'admin' && (
          <MenuItem 
            onClick={goToAdmin}
            sx={{
              borderRadius: 2,
              py: 1.5,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              }
            }}
          >
            <AdminPanelSettingsIcon sx={{ mr: 2, color: theme.palette.warning.main }} />
            <ListItemText primary="Quản trị hệ thống" />
          </MenuItem>
        )}

        <MenuItem
          onClick={handleLogout}
          sx={{
            borderRadius: 2,
            py: 1.5,
            mt: 1,
            '&:hover': {
              backgroundColor: theme.palette.error.lighter,
              color: theme.palette.error.main,
            },
            color: theme.palette.error.main,
          }}
        >
          <LogoutOutlinedIcon sx={{ mr: 2 }} />
          <ListItemText primary="Đăng xuất" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderUserMenu;

import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Typography,
  Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import affiliation from '../../../services/affiliations';
import AffiliationTableItem from './AffiliationTableItem';
import { useSelector } from 'react-redux';
import CustomTable from '../../../components/table/TableCustom';
import CardCustom from '../../../components/table/CardCustom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const AffiliationTable = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [totalCustomers, setTotalCustomers] = useState(0);

  useEffect(() => {
    loadAff();
  }, [page, rowsPerPage]);

  const loadAff = () => {
    setIsLoading(true);
    affiliation
      .getAffiliation({ page: page + 1, limit: rowsPerPage })
      .then((res) => {
        setData(res.data);
        setTotalCustomers(res.totalCustomers);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderMobileCard = (aff) => (
    <Card key={aff.bonus} sx={{ mb: 2 }}>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="subtitle1">
            <strong>Tên khách hàng:</strong> {aff.customerName}
          </Typography>
          <Typography variant="body2">
            <strong>Số lượng gói đã mua:</strong> {aff.totalPackages}
          </Typography>
          <Typography variant="body2">
            <strong>Tổng tiền:</strong> {aff.totalAmount.toLocaleString()} VNĐ
          </Typography>
          <Typography variant="body2">
            <strong>Tiền thưởng:</strong> {aff.bonus.toLocaleString()} VNĐ
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );

  const renderNoData = () => (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      p: 3,
      minHeight: '200px'
    }}>
      <Typography variant="body1" color="text.secondary">
        Không có dữ liệu
      </Typography>
    </Box>
  );

  // const columns = [
  //   {
  //     id: 'customerName',
  //     label: 'Tên khách hàng',
  //   },
  //   {
  //     id: 'totalPackages',
  //     label: 'Số lượng gói đã mua',
  //   },
  //   {
  //     id: 'totalAmount',
  //     label: 'Tổng tiền (VNĐ)',
  //   },
  //   {
  //     id: 'bonus',
  //     label: 'Tiền thưởng (VNĐ)',
  //   },
  //   {
  //     id: 'action',
  //     label: 'Chi tiết',
  //     render: (value, row) => (
  //       <AffiliationTableItem key={row.bonus} aff={row} />
  //     ),
  //   },
  // ];

  return (
    <Paper>
      {isMobile ? (
        <Box sx={{ p: 2 }}>
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : data?.length === 0 ? (
            renderNoData()
          ) : (

            data?.map((aff) => renderMobileCard(aff))
          )}
        </Box>
      ) : (
        <TableContainer sx={{ height: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Tên khách hàng</StyledTableCell>
                <StyledTableCell align="left">Số lượng gói đã mua</StyledTableCell>
                <StyledTableCell align="left">Tổng tiền (VNĐ)</StyledTableCell>
                <StyledTableCell align="left">Tiền thưởng (VNĐ)</StyledTableCell>
                <StyledTableCell align="left">Chi tiết</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <StyledTableCell colSpan={5} align="center">
                    <CircularProgress />
                  </StyledTableCell>
                </TableRow>
              ) : data?.length === 0 ? (
                <TableRow>
                  <StyledTableCell colSpan={5} align="center">
                    <Typography variant="body1" color="text.secondary">
                      Không có dữ liệu
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              ) : (
                data?.map((aff) => <AffiliationTableItem key={aff.bonus} aff={aff} />)
              )}
            </TableBody>
          </Table>
        </TableContainer>
        // <CustomTable columns={columns} data={data} />

      )}
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalCustomers}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default AffiliationTable;

import React, { useState } from 'react';
import { Container, Grid, TextField, Button, Typography, Box, Paper, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDispatch } from 'react-redux';
import { createUser } from '../../../reducers/slices/userSlice';

const CreateUser = ({ setIsOpen }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    avatar: null,
    name: '',
    phone: '',
    email: '',
    address: '',
    password: '',
    role: 'user',
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          avatar: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreate = () => {
    dispatch(createUser(formData));
    setIsOpen(false);
  };

  return (
    <Paper sx={{
      padding: 5, maxWidth: 600, margin: 'auto',
      minHeight: "60vh"
    }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position={'relative'}
      >
        {/* Avatar Section */}
        <Box
          sx={{
            width: 150,
            height: 150,
            borderRadius: '50%',
            border: '2px dashed #1976d2',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            cursor: 'pointer',
            marginBottom: 3,
            backgroundColor: '#f5f5f5',
          }}
          component="label"
        >
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          {formData.avatar ? (
            <Box
              component="img"
              src={formData.avatar}
              alt="Avatar"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          ) : (
            <AccountCircleIcon
              sx={{
                width: '100%',
                height: '100%',
                opacity: 0.6,
                color: '#1976d2'
              }}
            />
          )}
        </Box>

        {/* Form Fields */}
        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
          <TextField
            fullWidth
            label="Tên tài khoản"
            variant="outlined"
            name="name"
            value={formData.name}
            onChange={handleChange}
            sx={{ bgcolor: '#f0f0f0', mb: 2 }}
          />
          <TextField
            fullWidth
            label="email"
            variant="outlined"
            name="Email"
            value={formData.email}
            onChange={handleChange}
            sx={{ bgcolor: '#f0f0f0', mb: 2 }}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
          <TextField
            fullWidth
            label="Số điện thoại"
            variant="outlined"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            sx={{ bgcolor: '#f0f0f0', mb: 2 }}
          />
          <TextField
            fullWidth
            label="địa chỉ"
            variant="outlined"
            name="address"
            value={formData.address}
            onChange={handleChange}
            sx={{ bgcolor: '#f0f0f0', mb: 2 }}
          />
        </Box>

        <Button
          variant="contained"
          onClick={handleCreate}
          fullWidth
          sx={{
            color: 'white',
            mt: 3,
            // width: '150px',
            borderRadius: 50,
            py: 2,
            bgcolor: '#00ACC1',
            '&:hover': {
              bgcolor: '#00838F'
            }
          }}
        >
          Cập nhật
        </Button>
        <IconButton sx={{ position: 'absolute', top: -20, right: -10 }}>
          <CloseIcon onClick={() => setIsOpen(false)} />
        </IconButton>
      </Box>
    </Paper>
  );
};

export default CreateUser;

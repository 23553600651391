import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import userService from "../../services/userService";

const initialState = {
    users: [],
    totalPages: 0,
    total: 0,
    isLoading: true,
    error: null,
    ui: {
        showAlert: false,
        alertMessage: '',
        alertSeverity: 'error',
    },
    filters: {
        page: 1,
        limit: 10,
        search: '',
        dateFilter: 'all',
        paidFilter: 'all',
        startDate: null,
        endDate: null,
        refresh: 0,

    },
}
export const updateUserAsync = createAsyncThunk(
    'users/updateUser',
    async (userData, { dispatch }) => {
        try {
            const response = await userService.updateUser(userData);
            dispatch(updateInfoUser(response));
            dispatch(triggerRefresh());
            return response;
        } catch (error) {
            dispatch(updateUI({
                showAlert: true,
                alertMessage: 'Cập nhật người dùng thất bại',
                alertSeverity: 'error',
            }));
            throw error;
        }
    }
);
const userSlice1 = createSlice({
    name: 'users',
    initialState,
    reducers: {
        getUsers: (state, action) => {
            state.users = action.payload.data
            state.totalPages = action.payload.pagination.totalPages
            state.total = action.payload.pagination.total
            state.isLoading = false
            state.error = null
        },
        updateUsers: (state, action) => {
            state.filters = { ...state.filters, ...action.payload };
        },
        updateInfoUser: (state, action) => {
            const updatedUser = action.payload;
            state.users = state.users.map(user =>
                user._id === updatedUser._id ? updatedUser : user
            );
            state.ui = {
                showAlert: true,
                alertMessage: 'Cập nhật người dùng thành công',
                alertSeverity: 'success',
            };
        },
        updateUI: (state, action) => {
            state.ui = { ...state.ui, ...action.payload };
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        },
        triggerRefresh: (state) => {
            state.filters.refresh += 1;
        }
    }
})
export const {
    getUsers,
    updateUsers,
    updateUI,
    setLoading,
    setError,
    triggerRefresh,
    updateInfoUser
} = userSlice1.actions;

export default userSlice1.reducer;
import httpRequest from '../../../utils/httpRequest';
const END_POINT = '/admin/users';

const userByAdmin = {
    // getAllUser: async (params) => {
    //     console.log(params);
    //     const res = await httpRequest.get(`${END_POINT}`, { params });
    //     return res.data;
    // },
    getAllUser: async (dateFilter, page, limit, search, paidFilter, startDate, endDate) => {
        const res = await httpRequest.get(`${END_POINT}?dateFilter=${dateFilter}&page=${page}&limit=${limit}&keyword=${search}&paidFilter=${paidFilter}&startDate=${startDate}&endDate=${endDate}`);
        return res.data;
    },
    getUsers: async (id) => {
        const res = await httpRequest.get(`${END_POINT}/${id}`)
        return res.data;
    },
    // updateUsers: async (user) => {
    //     const res = await httpRequest.put(`${END_POINT}/${user.id}`, user);
    //     return res.data;
    // }
};

export default userByAdmin;

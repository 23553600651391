import { Box, Typography, IconButton, Avatar, useTheme, useMediaQuery, Chip, Tooltip } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CustomTable from "../../../../components/table/TableCustom";
import CardCustom from "../../../../components/table/CardCustom";
import { useDispatch, useSelector } from "react-redux";
import { updateUi } from "../../../../reducers/slices/affiliateSlice";
import { formatCurrency, formatDate } from "../../../../utils/formatters";

const AffiliateList = () => {
    const dispatch = useDispatch();
    const { affiliates } = useSelector(state => state.affiliate);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleMenuOpen = (event, id) => {
        dispatch(updateUi({ anchorEl: event.currentTarget, selectedId: id }))
    }

    const columns = [
        {
            id: 'name',
            label: 'Tên',
            render: (_, data) => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box sx={{ borderRadius: '50%', border: data?.isActive ? '3px solid #4caf50' : '3px solid #6C757D' }}>
                        <Avatar src={data?.ownerInfo?.avatar} />
                    </Box>
                    <Box>
                        <Typography fontWeight="bold">{data?.ownerInfo?.name}</Typography>
                        <Typography variant="body1">Cấp độ: {data?.level}</Typography>
                    </Box>
                </Box>
            )
        },
        {
            id: 'email',
            label: 'Email',
            render: (_, data) => <Typography sx={{ width: isMobile ? 150 : "100%", wordBreak: 'break-word' }}>{data?.ownerInfo?.email}</Typography>
        },
        { id: 'referralCode', label: 'Mã giới thiệu', render: (_, data) => <Chip size="small" label={data?.referralCode} color="warning" /> },
        {
            id: 'createdAt',
            label: 'Ngày đăng ký Affiliate',
            render: (_, data) => (
                <Typography>{formatDate(data?.createdAt)}</Typography>
            )
        },
        {
            id: 'isActive',
            label: 'Trạng thái',
            render: (_, data) => (
                <Typography>{data?.isActive === true ? <Chip size="small" label="Hoạt động" color="primary" /> : <Chip size="small" label="Không hoạt động" color="error" />}</Typography>
            )
        },
        {
            id: 'income',
            label: 'Thu nhập (VND)',
            render: (_, data) => (
                <Typography sx={{ fontWeight: 'bold' }}>
                    {formatCurrency(data?.totalEarning)}
                </Typography>
            )
        },
        {
            id: 'action',
            label: 'Thao tác',
            render: (_, data) => (
                <Tooltip title="Menu chi tiết">
                    <IconButton onClick={(e) => handleMenuOpen(e, data)}>
                        <MoreHorizIcon />
                    </IconButton>
                </Tooltip>
            )
        }
    ];
    return (
        <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex' }}>
            {isMobile ? (
                <Box sx={{ gap: 2, overflow: 'auto', flex: 1 }}>
                    <CardCustom columns={columns} data={affiliates} />
                </Box>
            ) : (
                <Box sx={{ width: '100%' }}>
                    <CustomTable columns={columns} data={affiliates} />
                </Box>
            )}
        </Box>
    );
};

export default AffiliateList; 
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Pagination, Box, useTheme, useMediaQuery, Tooltip, Chip, TextField, MenuItem } from "@mui/material"
import CustomTable from "../../../components/table/TableCustom"
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import affiliateByAdmin from "../../../services/admin/affiliate/affiliate";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CardCustom from "../../../components/table/CardCustom";
import { formatCurrency, formatDate } from "../../../utils/formatters";
import VisibilityIcon from '@mui/icons-material/Visibility';
import OriginOfRoses from "./OriginOfRoses";
import { useDispatch, useSelector } from 'react-redux';
import { getCommissions, updateCommissions, setLoading, setError } from '../../../reducers/slices/commissionSlice';

const TotalEarningDialog = ({ open, onClose, data }) => {
    const dispatch = useDispatch();
    const { commissions, filters, isLoading, totalPages, total } = useSelector((state) => state.commission);
    const [isOpen, setIsOpen] = useState(false);
    const [tempStartDate, setTempStartDate] = useState(null);
    const [tempEndDate, setTempEndDate] = useState(null);
    const [commission, setCommission] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
        if (!open) return;
        const fetchData = async () => {
            try {
                dispatch(setLoading(true));
                const response = await affiliateByAdmin.getRevenue(data?._id,
                    filters.startDate ? dayjs(filters.startDate).format('YYYY-MM-DD') : '',
                    filters.endDate ? dayjs(filters.endDate).format('YYYY-MM-DD') : '',
                    filters.page,
                    filters.limit,
                    filters.sourceFilter);
                dispatch(getCommissions(response));
            } catch (error) {
                dispatch(setError(error.message));
            }
        };
        fetchData();
    }, [dispatch, data?._id, filters.startDate, filters.endDate, filters.page, filters.limit, filters.sourceFilter, open,]);

    const handlePageChange = (event, newPage) => {
        dispatch(updateCommissions({ page: newPage }));
    };
    const handleStartDateChange = (date) => {
        setTempStartDate(date);
        if (tempEndDate && date) {
            const startDateISO = dayjs(date).isValid() ? dayjs(date).startOf('day').toISOString() : null;
            const endDateISO = dayjs(tempEndDate).isValid() ? dayjs(tempEndDate).endOf('day').toISOString() : null;
            dispatch(updateCommissions({
                startDate: startDateISO,
                endDate: endDateISO
            }));
        }
    };

    const handleEndDateChange = (date) => {
        setTempEndDate(date);
        if (tempStartDate && date) {
            const startDateISO = dayjs(tempStartDate).isValid() ? dayjs(tempStartDate).startOf('day').toISOString() : null;
            const endDateISO = dayjs(date).isValid() ? dayjs(date).endOf('day').toISOString() : null;
            dispatch(updateCommissions({
                startDate: startDateISO,
                endDate: endDateISO
            }));
        }
    };
    const handleStatusChange = (event) => {
        dispatch(updateCommissions({ sourceFilter: event.target.value }));
    };

    const handleClose = () => {
        setTempStartDate(null);
        setTempEndDate(null);
        dispatch(updateCommissions({
            startDate: null,
            endDate: null,
            page: 1,
            sourceFilter: 'all'
        }));
        onClose();
    };
    const handleOpen = (data) => {
        setCommission(data);
        setIsOpen(true);
    }
    const handleCloseOriginOfRoses = () => {
        setIsOpen(false);
    }
    const colums = [
        {
            id: "name", label: "Tên gói", render: (_, data) => <Typography>{data?.transaction?.info?.name === 'free' ? "Dùng thử" : data?.package?.name === 'basic' ? "Cơ bản" : data?.package?.name === 'standard' ? "Tiêu chuẩn" : 'Nâng cao'}</Typography>
        },
        {
            id: 'price', label: 'Giá (VND)', render: (_, data) => {
                return (
                    <Typography>{formatCurrency(data?.transaction?.info?.price)}</Typography>
                )
            }
        },
        {
            id: 'duration', label: 'Số tháng', render: (_, data) => {
                return (
                    <Typography>{data?.transaction?.info?.duration}</Typography>
                )
            }
        },
        {
            id: 'status', label: 'Loại', render: (_, data) => {
                console.log(data);

                return (
                    <Box sx={{ display: 'flex', gap: 1, flexDirection: { xs: 'column', lg: 'row' } }}>
                        {data?.isFirstPurchase === true ? <Chip label="Mua đầu tiên" size='small' color='secondary' /> : data?.isFirstPurchase === false ? <Chip label="Mua từ lần 2" size='small' color='warning' /> : <Chip label="N/A" size='small' color='error' />}
                        {data?.sourceStatus === 'selfPurchase' ? <Chip label="Bản thân mua" size='small' color='info' /> : data?.sourceStatus === 'directCustomerPurchase' ? <Chip label="Khách trực tiếp mua" size='small' color='warning' /> : data?.sourceStatus === 'subAffiliatePurchase' ? <Chip label="Khách nhánh dưới mua" size='small' color='success' /> : ''}
                    </Box>
                )
            }
        },
        {
            id: 'startDate', label: 'Ngày bắt đầu', render: (_, data) => {
                return (
                    <Typography>{formatDate(data?.transaction?.startDate)}</Typography>
                )
            }
        },
        {
            id: 'commissionPercentage', label: 'Phần trăm hoa hồng', render: (_, data) => {
                if (data?.commissionPercentage === null) {
                    return <Chip label="N/A" size='small' color='error' />;
                }
                const percent = data?.commissionPercentage * 100;
                return <Typography>{percent}%</Typography>;
            }
        },
        {
            id: 'commission', label: 'Hoa hồng (VND)', render: (_, data) => {
                return (
                    <Typography sx={{ fontWeight: "bold" }}>{formatCurrency(data?.commission)}</Typography>
                )
            }
        },
        {
            id: 'isCompanyRevenue', label: 'Ghi chú', render: (_, data) => {
                return (
                    data?.isCompanyRevenue ? <Chip label="Doanh thu thuộc về Schat" size='small' color='primary' /> : ""
                )
            }
        },
        {
            id: 'action', label: 'Thao tác', render: (_, data) => {
                return (
                    <Tooltip title="Xem nguồn gốc hoa hồng">
                        <IconButton onClick={() => handleOpen(data)}>
                            <VisibilityIcon color="primary" />
                        </IconButton>
                    </Tooltip>

                )
            }
        },
    ]
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth disableEscapeKeyDown>
            <DialogTitle sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
                alignItems: isMobile ? 'stretch' : 'center',
                borderBottom: '1px solid #eee',
                pb: 2,
                mt: 6,
                mb: 2,
                gap: 2,
                position: 'relative'
            }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: isMobile ? '0.875rem' : '1.3rem' }}>
                    Lịch sử hoa hồng của: {data?.ownerInfo?.email} (Cấp độ: {data?.level})
                </Typography>
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    flexDirection: isMobile ? 'column' : 'row',
                    width: isMobile ? '100%' : 'auto'
                }}>
                    <TextField
                        select
                        label="Lọc theo loại"
                        value={filters?.sourceFilter}
                        onChange={handleStatusChange}
                        sx={{
                            minWidth: isMobile ? "100%" : 150,
                            backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                            '& .MuiOutlinedInput-root': {
                                height: 40,
                            },
                            '& .MuiInputLabel-root': {
                                transform: 'translate(14px, 8px) scale(1)',
                                '&.Mui-focused, &.MuiFormLabel-filled': {
                                    transform: 'translate(14px, -9px) scale(0.75)',
                                }
                            }
                        }}
                    >
                        <MenuItem value={'all'}>Tất cả</MenuItem>
                        <MenuItem value={'firstPurchase'}>Mua lần đầu</MenuItem>
                        <MenuItem value={'selfPurchase'}>Bản thân mua</MenuItem>
                        <MenuItem value={'directCustomerPurchase'}>Khách hàng trực tiếp mua</MenuItem>
                        <MenuItem value={'subAffiliatePurchase'}>Khách nhánh dưới mua</MenuItem>

                    </TextField>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box sx={{ display: "flex", gap: 2 }}>
                            <DatePicker
                                label="Ngày bắt đầu"
                                value={tempStartDate}
                                onChange={handleStartDateChange}
                                format="DD/MM/YYYY"
                                sx={{
                                    width: isMobile ? '100%' : 'auto',
                                    '& .MuiOutlinedInput-root': {
                                        height: 40,
                                    },
                                    '& .MuiInputLabel-root': {
                                        transform: 'translate(14px, 8px) scale(1)',
                                        '&.Mui-focused, &.MuiFormLabel-filled': {
                                            transform: 'translate(14px, -9px) scale(0.75)',
                                        }
                                    },
                                    '& .MuiSelect-select': {
                                        paddingTop: '8px',
                                        paddingBottom: '8px',
                                    }
                                }}
                            />
                            <DatePicker
                                label="Ngày kết thúc"
                                value={tempEndDate}
                                onChange={handleEndDateChange}
                                format="DD/MM/YYYY"
                                sx={{
                                    width: isMobile ? '100%' : 'auto',
                                    '& .MuiOutlinedInput-root': {
                                        height: 40,
                                    },
                                    '& .MuiInputLabel-root': {
                                        transform: 'translate(14px, 8px) scale(1)',
                                        '&.Mui-focused, &.MuiFormLabel-filled': {
                                            transform: 'translate(14px, -9px) scale(0.75)',
                                        }
                                    },
                                    '& .MuiSelect-select': {
                                        paddingTop: '8px',
                                        paddingBottom: '8px',
                                    }
                                }}
                            />
                        </Box>
                    </LocalizationProvider>
                    <IconButton onClick={handleClose} sx={{ position: 'absolute ', top: -30, right: 15 }} size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                {isMobile ? (
                    <CardCustom columns={colums} data={commissions} isLoading={isLoading} />
                ) : (
                    <CustomTable columns={colums} data={commissions} isLoading={isLoading} />
                )}
            </DialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'end', my: 2, mr: 3 }}>
                <Typography sx={{ mr: 2, border: '1px solid #e0e0e0', py: 0.4, px: 2, borderRadius: '5px', bgcolor: '#E0E0E0' }}>
                    {`Tổng số: ${total}`}
                </Typography>
                <Pagination
                    count={totalPages}
                    page={filters?.page}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                />
            </Box>
            <OriginOfRoses open={isOpen} onClose={handleCloseOriginOfRoses} commission={commission} />
        </Dialog>

    )
}

export default TotalEarningDialog
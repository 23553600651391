import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import botReducer from './slices/botSlice';
import modalReducer from './slices/modalSlice';
import userReducer from './slices/userSlice';
import packageSilce from './slices/packageSilce';
import socialConnectReducer from './slices/socialConnectSlice';
import sidebarReducer from './slices/sidebarSlice';
import affiliateReducer from './slices/affiliateSlice';
import affiliateReferraReducer from './slices/affiliateReferralsSlice'
import userReducer1 from './slices/userSlice1';
import commissionReducer from './slices/commissionSlice';
const rootReducer = {
  auth: authReducer,
  bot: botReducer,
  modal: modalReducer,
  users: userReducer,
  packages: packageSilce,
  socialConnect: socialConnectReducer,
  sidebar: sidebarReducer,
  affiliate: affiliateReducer,
  referrals: affiliateReferraReducer,
  users1: userReducer1,
  commission: commissionReducer
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

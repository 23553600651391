import React, { useState, useEffect } from 'react';
import { VietQR } from 'vietqr';
import {
  Dialog,
  DialogTitle,
  DialogContent, 
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
  Grid,
  Paper,
} from '@mui/material';
import { useSelector } from 'react-redux';

const QrCode = ({ amount = 1000, message = 'Schat', open, onClose }) => {
  const accountNumber = '68555585555';
  const bankCode = '970423';
  const accountName = 'CONG TY CO PHAN PHAT TRIEN TRI TUE NHAN TAO VKS AI';
  const vietQR = new VietQR({
    clientID: process.env.REACT_APP_CLIENT_ID_VIET_QR,
    apiKey: process.env.REACT_APP_API_KEY_VIET_QR,
  });
  const user = useSelector((state) => state.auth.user);
  const [qrCodeData, setQrCodeData] = useState(null);

  useEffect(() => {
    if (amount && message) {
      generateQRCode();
    }
  }, [amount, message]);

  const generateQRCode = () => {
    vietQR
      .genQRCodeBase64({
        bank: bankCode,
        accountName,
        accountNumber,
        amount: amount ? parseInt(amount) : 0,
        memo: message + ` ${user.name}`,
        template: 'compact',
      })
      .then((data) => {
        setQrCodeData(data.data.data.qrDataURL);
      })
      .catch((err) => {
        console.error('Lỗi khi tạo mã QR:', err);
      });
  };

  return (
    <Dialog 
      open={open} 
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: 3
        }
      }}
    >
      <DialogTitle sx={{
        bgcolor: 'primary.main',
        color: 'white',
        py: 2
      }}>
        <Typography variant="h6" align="center">
          Quét mã QR để thanh toán
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        {qrCodeData ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper elevation={0} sx={{
                p: 2,
                display: 'flex',
                justifyContent: 'center',
                bgcolor: 'grey.50',
                borderRadius: 2
              }}>
                <img 
                  src={qrCodeData} 
                  alt="QR code" 
                  style={{ 
                    width: '100%',
                    maxWidth: 240,
                    height: 'auto' 
                  }} 
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{
                p: 2,
                bgcolor: 'grey.50',
                borderRadius: 2
              }}>
                <Typography variant="subtitle1" color="primary" gutterBottom>
                  Thông tin chuyển khoản
                </Typography>
                <Divider sx={{ mb: 2 }} />
                
                <Box sx={{ '& > *': { mb: 1.5 } }}>
                  <Typography variant="body2">
                    <strong>Ngân hàng:</strong> TP Bank
                  </Typography>
                  <Typography variant="body2">
                    <strong>Số tài khoản:</strong> {accountNumber}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Tên chủ thẻ:</strong> {accountName}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Nội dung CK:</strong> {message}
                  </Typography>
                  <Typography variant="h6" color="primary.main" sx={{ mt: 2 }}>
                    Số tiền: {amount?.toLocaleString('vi-VN')} VND
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ 
            p: 4,
            textAlign: 'center'
          }}>
            <Typography variant="body1">
              {amount == 0 ? (
                <>
                  Vui lòng liên hệ{' '}
                  <Button
                    href="tel:0836991919"
                    variant="text" 
                    color="primary"
                    sx={{ fontWeight: 'bold' }}
                  >
                    0836991919
                  </Button>
                  {' '}để được hỗ trợ
                </>
              ) : (
                'Đang tạo mã QR...'
              )}
            </Typography>
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ p: 2, bgcolor: 'grey.50' }}>
        <Button 
          onClick={onClose}
          variant="contained"
          color="primary"
          fullWidth
        >
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QrCode;

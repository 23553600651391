import { Box, Grid } from '@mui/material';
import BotSocialConnectListItem from './BotSocialConnectListItem';

const BotSocialConnectList = ({ data, setSocial }) => {
  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Grid
        container
        spacing={2}
        sx={{
          width: '100%',
          margin: 0,
        }}
      >
        {data?.info?.map((el, i) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            key={i}
            sx={{
              padding: '8px !important',
            }}
          >
            <Box
              sx={{
                height: '100%',
                border: '1px solid #ccc',
                borderRadius: '10px',
                overflow: 'hidden',
              }}
            >
              <BotSocialConnectListItem socialId={data.id} data={el} setSocial={setSocial} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BotSocialConnectList;

import { Box, Container, Pagination, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import affiliateByAdmin from "../../../services/admin/affiliate/affiliate";
import AffiliateFilters from "./components/AffiliateFilters";
import AffiliateList from "./components/AffiliateList";
import AffiliateActions from "./components/AffiliateActions";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliates, setError, updateAffiliate, updateUi } from "../../../reducers/slices/affiliateSlice";

const AffiliateContainer = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const limit = 10;
    const { totalPages, filters, total } = useSelector(state => state.affiliate);

    useEffect(() => {
        const pageFromUrl = parseInt(searchParams.get('page')) || 1;
        dispatch(updateAffiliate({ page: pageFromUrl }));
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await affiliateByAdmin.getAllAffiliates(
                    filters.page,
                    limit,
                    filters.search,
                    filters.level,
                    filters.dateFilter,
                    filters.startDate ? dayjs(filters.startDate).format('YYYY-MM-DD') : '',
                    filters.endDate ? dayjs(filters.endDate).format('YYYY-MM-DD') : ''
                );
                dispatch(getAffiliates(response));
            } catch (error) {
                dispatch(updateUi({
                    showAlert: true,
                    alertMessage: error.response?.data?.message === "Start date and end date are required for custom date filter" ? "Ngày bắt đầu và ngày kết thúc là bắt buộc đối với bộ lọc ngày tùy chỉnh" :
                        error.response?.data?.message === "Start date must be before end date" ? "Ngày bắt đầu phải trước ngày kết thúc" : "Lỗi sever",
                    alertSeverity: 'error',
                }))
                dispatch(setError(error));
            }
        }
        fetchData();
    }, [dispatch, filters.page, filters.level, filters.search, filters.dateFilter, filters.startDate, filters.endDate, filters.refresh]);

    const handlePageChange = (event, newPage) => {
        dispatch(updateAffiliate({ page: newPage }));
        setSearchParams({ page: newPage, level: filters.level, search: filters.search });
    };


    return (
        <Container sx={{ height: { xs: "91vh", lg: '86vh' }, display: 'flex', flexDirection: 'column', }}>
            <Typography sx={{ minWidth: '400px', fontWeight: 'bold', mb: isMobile ? 2 : 0, fontSize: '20px' }}>
                Quản lý đối tác Affiliate
            </Typography>
            <Box sx={{ mb: 2, pt: 2 }}>
                <AffiliateFilters />
            </Box>
            <AffiliateList />
            <Box sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                py: 1,
                borderColor: 'divider',

            }}>
                <Typography sx={{ mr: 2, border: '1px solid #e0e0e0', py: 0.4, px: 2, borderRadius: '5px', bgcolor: '#E0E0E0' }}>
                    {`Tổng số: ${total}`}
                </Typography>
                <Pagination
                    count={totalPages}
                    page={filters.page}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    siblingCount={1}
                />
            </Box>
            <AffiliateActions />
        </Container>
    );
};

export default AffiliateContainer;
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { useState } from 'react';
import BotGuideContent from './BotGuideContent';
import BotSettingContent from './BotSettingContent';
import { useSelector } from 'react-redux';

const BotSetting = () => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState(0);
  const isOpen = useSelector((state) => state.sidebar?.isOpen ?? true);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          position: 'fixed',
          top: { xs: 65, sm: 60 },
          left: { xs: 0, sm: 0, md: isOpen ? 300 : 80 },
          right: 0,
          py: { xs: 1, sm: 2 },
          bgcolor: '#ECEDF4',
          zIndex: 1000,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'center',
          transition: theme.transitions.create(['left'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            maxWidth: { xs: '100%', sm: '600px' },
            width: '100%'
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: currentTab === 1 ? '#1976d2' : theme.palette.primary.main
            }
          }}
        >
          <Tab
            label="Cài đặt bot"
            sx={{
              fontSize: { xs: '0.8rem', sm: '0.9rem' },
              flex: 1
            }}
          />
          <Tab
            label="Hướng dẫn cài đặt bot"
            sx={{
              fontSize: { xs: '0.8rem', sm: '0.9rem' },
              color: currentTab === 1 ? '#1976d2' : 'inherit',
              '&.Mui-selected': {
                color: currentTab === 1 ? '#1976d2' : theme.palette.primary.main,
              },
              flex: 1
            }}
          />
        </Tabs>
      </Box>
      <Box sx={{
        mt: { xs: 6, sm: 7 },
        px: { xs: 1, sm: 2 },
        display: 'flex',
        justifyContent: 'center'
      }}>
        <Box sx={{
          width: '100%',
          maxWidth: '1200px'
        }}>
          {currentTab === 0 ? <BotSettingContent /> : <BotGuideContent />}
        </Box>
      </Box>
    </Box>
  );
};

export default BotSetting;

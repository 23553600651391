import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormGroup,
  FormLabel,
  Tooltip,
  Typography,
  Link, // Import Link from MUI
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import Input from '../../../components/forms/Input';
import { useSelector } from 'react-redux';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VisuallyHiddenInput from '../../../components/forms/VisuallyHiddenInput';
import userService from '../../../services/userService';
import uploadService from '../../../services/uploadService';
import toastMessage from '../../../utils/toastMessage';

const SettingProfile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const authSelector = useSelector((state) => state.auth);
  const [userLogged, setUserLogged] = useState(authSelector.user);
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(userLogged?.avatar || null);
  const [introductionLink, setIntroductionLink] = useState('');

  const initialValues = {
    email: userLogged?.email || '',
    name: userLogged?.name || '',
    phone: userLogged?.phone || '',
    address: userLogged?.address || '',
    avatar: null,
  };

  const updateUser = (body) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    userService
      .updateInformation(body)
      .then((res) => {
        setUserLogged(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    let body = {
      email: values?.email,
      name: values?.name,
      phone: values?.phone,
      address: values?.address,
    };
    if (values?.avatar) {
      uploadService
        .uploadFile(values?.avatar)
        .then((res) => {
          if (res) {
            body.avatar = res.url;
            updateUser(body);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      updateUser(body);
    }
  };

  useEffect(() => {
    loadUsageLimit();
  }, []);

  const loadUsageLimit = () => {
    setIsLoading(true);
    userService
      .getUsageItroduction()
      .then((res) => {
        console.log(res);
        setIntroductionLink(res.url);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(introductionLink)
      .then(() => {
        toastMessage.showSuccess('Đã sao chép')
      })
      .catch((err) => {
        console.error('Lỗi khi sao chép link: ', err);
      });
  };

  return (
    <>
      <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={handleSubmit}>
        {({ handleChange, handleBlur, setFieldValue, values }) => (
          <Form>
            <FormGroup sx={{ mb: 3 }}>
              <FormLabel required>Email</FormLabel>
              <Field
                name="email"
                disabled
                as={Input}
                placeholder="Nhập email..."
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>

            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              gap={isMobile ? 2 : 0}
              sx={{ mb: 3 }}
            >
              <FormGroup sx={{ flex: 1, mr: isMobile ? 0 : 1 }}>
                <FormLabel required>Họ và tên</FormLabel>
                <Field
                  name="name"
                  as={Input}
                  placeholder="Nhập họ và tên..."
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>

              <FormGroup sx={{ flex: 1, ml: isMobile ? 0 : 1 }}>
                <FormLabel required>Số điện thoại</FormLabel>
                <Field
                  name="phone"
                  as={Input}
                  placeholder="Nhập số điện thoại..."
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>
            </Box>

            <FormGroup sx={{ mb: 3 }}>
              <FormLabel required>Địa chỉ</FormLabel>
              <Field
                name="address"
                as={Input}
                placeholder="Nhập địa chỉ..."
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>

            {/* <Box
              sx={{
                mb: 2,
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: isMobile ? 'flex-start' : 'center',
                gap: isMobile ? 1 : 0
              }}
            >
              <Typography sx={{ mr: isMobile ? 0 : 2 }}>Link giới thiệu:</Typography>
              <Box sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: isMobile ? 'flex-start' : 'center',
                gap: isMobile ? 1 : 0,
                width: '100%'
              }}>
                <Link
                  href={introductionLink}
                  target="_blank"
                  rel="noopener"
                  sx={{
                    wordBreak: 'break-all',
                    flex: 1
                  }}
                >
                  {introductionLink.length > 55 ? `${introductionLink.slice(0, 55)}...` : introductionLink}
                </Link>
                <Button
                  onClick={handleCopyLink}
                  sx={{
                    ml: isMobile ? 0 : 2,
                    width: isMobile ? '100%' : 'auto'
                  }}
                  variant="outlined"
                >
                  Sao chép
                </Button>
              </Box>
            </Box> */}

            <FormGroup sx={{ mb: 2 }}>
              <FormLabel>Ảnh đại diện</FormLabel>
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={2}
                alignItems={isMobile ? 'center' : 'flex-start'}
                justifyContent={isMobile ? 'space-between' : 'flex-start'}
              >
                <Avatar
                  src={imagePreview}
                  variant="rounded"
                  sx={{
                    width: isMobile ? 120 : 100,
                    height: isMobile ? 120 : 100,
                    borderRadius: theme.shape.borderRadius,
                    border: `3px solid ${theme.palette.background.default}`,
                    boxSizing: 'border-box',
                  }}
                />
                <Tooltip title="Thay ảnh đại diện" placement={isMobile ? "bottom" : "right"}>
                  <Button
                    component="label"
                    sx={{
                      ml: isMobile ? 0 : 0,
                      borderRadius: theme.shape.borderRadius,
                      width: isMobile ? 120 : 100,
                      height: isMobile ? 120 : 100,
                      display: 'flex',
                      alignItems: 'center',
                      border: `1px dashed ${theme.palette.primary.main}`,
                    }}
                  >
                    <CameraAltIcon sx={{ color: theme.palette.primary.main, fontSize: isMobile ? 40 : 30 }} />
                    <VisuallyHiddenInput
                      accept="image/*"
                      type="file"
                      onChange={(e) => {
                        setFieldValue('avatar', e.target.files[0]);
                        setImagePreview(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                  </Button>
                </Tooltip>
              </Box>
            </FormGroup>

            <Box
              width={'100%'}
              display={'flex'}
              justifyContent={'end'}
              sx={{ mt: isMobile ? 3 : 0 }}
            >
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                fullWidth={isMobile}
              >
                {!isLoading ? (
                  'Lưu cài đặt'
                ) : (
                  <span>
                    Đang lưu
                    <CircularProgress
                      sx={{ ml: 1 }}
                      color="inherit"
                      size={12}
                      thickness={5}
                      variant="indeterminate"
                    />
                  </span>
                )}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SettingProfile;

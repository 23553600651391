import { Box, Button, MenuItem, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import ModalCustom from "../../../components/modals/ModalCustom";
import CreateUser from "./CreateUser";
import { useDispatch, useSelector } from "react-redux";
import { updateUsers } from "../../../reducers/slices/userSlice1";
import { debounce, filter } from "lodash";
const UserTableFilter = () => {
    const dispatch = useDispatch()
    const { filters } = useSelector(state => state.users1)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isOpen, setIsOpen] = useState(false);
    const [temStartDate, setTemStartDate] = useState(null);
    const [temEndDate, setTemEndDate] = useState(null);
    const handleClose = () => setIsOpen(false);
    const handleSearchChange = debounce((e) => {
        dispatch(updateUsers({ search: e.target.value, page: 1 }))
    }, 1000)
    const handleUpdateStatusPaid = (e) => {
        const value = e.target.value;
        dispatch(updateUsers({ paidFilter: value }));
    }
    const handleFilterTypeChange = (event) => {
        const value = event.target.value;
        dispatch(updateUsers({ dateFilter: value }));
        setTemStartDate(null);
        setTemEndDate(null);
        if (value !== "custom") {
            dispatch(updateUsers({
                startDate: null,
                endDate: null
            }));
        }
    };
    const handleStartDateChange = (newValue) => {
        setTemStartDate(newValue);
        if (newValue && temEndDate) {
            const startDateISO = dayjs(newValue).isValid() ? dayjs(newValue).startOf('day').toISOString() : null;
            const endDateISO = dayjs(temEndDate).isValid() ? dayjs(temEndDate).endOf('day').toISOString() : null;
            dispatch(updateUsers({
                startDate: startDateISO,
                endDate: endDateISO,
            }));
        }
    }
    const handleEndDateChange = (newValue) => {
        setTemEndDate(newValue);
        if (newValue && temStartDate) {
            const startDateISO = dayjs(temStartDate).isValid() ? dayjs(temStartDate).startOf('day').toISOString() : null;
            const endDateISO = dayjs(newValue).isValid() ? dayjs(newValue).endOf('day').toISOString() : null;
            dispatch(updateUsers({
                startDate: startDateISO,
                endDate: endDateISO
            }));
        }
    }
    return (
        <Box>
            <Typography sx={{ minWidth: '400px', fontWeight: 'bold', mb: isMobile ? 2 : 0, fontSize: '20px', mb: 2 }}>
                Quản lý đối tác Affiliate
            </Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                gap: 2,

                alignItems: isMobile ? 'stretch' : 'center',
                width: '100%',
                justifyContent: isMobile ? 'normal' : 'space-between',
            }}>
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: isMobile ? 'normal' : 'space-between',
                }}>
                    <TextField
                        select
                        label={isMobile ? "Lọc" : "Lọc theo thời gian"}
                        value={filters.dateFilter}
                        onChange={handleFilterTypeChange}
                        sx={{
                            minWidth: isMobile ? 100 : 180,
                            backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                            '& .MuiOutlinedInput-root': {
                                height: 40,
                            },
                            '& .MuiInputLabel-root': {
                                transform: 'translate(14px, 8px) scale(1)',
                                '&.Mui-focused, &.MuiFormLabel-filled': {
                                    transform: 'translate(14px, -9px) scale(0.75)',
                                }
                            }
                        }}
                    >
                        <MenuItem value="all">Tất cả</MenuItem>
                        <MenuItem value="today">Hôm nay</MenuItem>
                        <MenuItem value="yesterday">Hôm qua</MenuItem>
                        <MenuItem value="last7days">7 ngày gần nhất</MenuItem>
                        <MenuItem value="thisMonth">Tháng này</MenuItem>
                        <MenuItem value="lastMonth">Tháng trước</MenuItem>
                        <MenuItem value="custom">Tùy chỉnh</MenuItem>
                    </TextField>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Tooltip title={filters.dateFilter !== "custom"
                            ? "Chọn 'Tùy chỉnh' để lọc theo ngày"
                            : ""}
                            arrow
                            enterDelay={500}>
                            <Box sx={{ display: "flex", gap: 2 }}>
                                <DatePicker
                                    label="Ngày bắt đầu"
                                    value={temStartDate || (filters?.startDate ? dayjs(filters?.startDate) : null)}
                                    onChange={handleStartDateChange}
                                    format="DD/MM/YYYY"
                                    disabled={filters?.dateFilter !== "custom"}
                                    readOnly={filters?.dateFilter !== "custom"}
                                    sx={{
                                        backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                                        width: isMobile ? '100%' : 'auto',
                                        '& .MuiOutlinedInput-root': {
                                            height: 40,
                                            opacity: filters.dateFilter !== "custom" ? 0.7 : 1
                                        },
                                        '& .MuiInputLabel-root': {
                                            transform: 'translate(14px, 8px) scale(1)',
                                            '&.Mui-focused, &.MuiFormLabel-filled': {
                                                transform: 'translate(14px, -9px) scale(0.75)',
                                            }
                                        }
                                    }}
                                />
                                <DatePicker
                                    label="Ngày kết thúc"
                                    value={temEndDate || (filters?.endDate ? dayjs(filters?.endDate) : null)}
                                    onChange={handleEndDateChange}
                                    format="DD/MM/YYYY"
                                    disabled={filters?.dateFilter !== "custom"}
                                    readOnly={filters?.dateFilter !== "custom"}
                                    sx={{
                                        backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                                        width: isMobile ? '100%' : 'auto',
                                        '& .MuiOutlinedInput-root': {
                                            height: 40,
                                            opacity: filters.dateFilter !== "custom" ? 0.7 : 1
                                        },
                                        '& .MuiInputLabel-root': {
                                            transform: 'translate(14px, 8px) scale(1)',
                                            '&.Mui-focused, &.MuiFormLabel-filled': {
                                                transform: 'translate(14px, -9px) scale(0.75)',
                                            }
                                        }
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    </LocalizationProvider>
                </Box>

                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    // flexDirection: isMobile ? 'column' : 'row',
                    width: '100%',
                    justifyContent: isMobile ? 'normal' : 'flex-end',
                }}>
                    <TextField
                        select
                        label="Trạng thái trả phí"
                        value={filters.paidFilter}
                        onChange={handleUpdateStatusPaid}
                        sx={{
                            minWidth: isMobile ? 150 : 200,
                            backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                            '& .MuiOutlinedInput-root': {
                                height: 40,
                            },
                            '& .MuiInputLabel-root': {
                                transform: 'translate(14px, 8px) scale(1)',
                                '&.Mui-focused, &.MuiFormLabel-filled': {
                                    transform: 'translate(14px, -9px) scale(0.75)',
                                }
                            }
                        }}
                    >
                        <MenuItem value={'all'}>Tất cả</MenuItem>
                        <MenuItem value={'paid'}>Đã Trả phí</MenuItem>
                        <MenuItem value={'unpaid'}>Chưa trả phí</MenuItem>
                    </TextField>

                    <TextField
                        label={isMobile ? "Tìm kiếm" : "Tìm kiếm bằng email hoặc tên"}
                        variant="outlined"
                        fullWidth
                        value={filters.searchText}
                        onChange={handleSearchChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon color="disabled" />
                                </InputAdornment>
                            )
                        }}
                        sx={{
                            backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                            // width: isMobile ? '100px' : 300,
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                                height: 40,
                            },
                            '& .MuiInputLabel-root': {
                                transform: 'translate(14px, 8px) scale(1)',
                                '&.Mui-focused, &.MuiFormLabel-filled': {
                                    transform: 'translate(14px, -9px) scale(0.75)',
                                }
                            }
                        }}
                    />

                </Box>
            </Box>
            <ModalCustom open={isOpen} onClose={handleClose} size="large" placement="center">
                <CreateUser setIsOpen={setIsOpen} />
            </ModalCustom>
        </Box>

    )
}

export default UserTableFilter
import { Avatar, Box, Card, CardContent, Divider, Typography } from "@mui/material";
import no_data from "../../assets/media/images/no_data_remove.png";
const CardCustom = ({ columns, data }) => {
    if (!data || data.length === 0) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                p={3}

            >
                <Avatar src={no_data} sx={{
                    width: 120,
                    height: 120,
                    // mb: 2
                }} />

            </Box>
        );
    }

    return (
        <Box>
            {data.map((item, index) => (
                <Card key={index} sx={{
                    border: '1px solid #e0e0e0', borderRadius: 2, mb: 2, '&:hover': {
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        transform: 'translateY(-2px)',
                        transition: 'all 0.3s ease'
                    }
                }}>
                    <CardContent sx={{
                        mb: 2,

                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6" color="primary">
                                {columns[0].render ? columns[0].render(item[columns[0].id], item) : item?.info?.name}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                {columns?.map((column, idx) =>
                                    column.id === "action" && column.render ? (
                                        <Box key={`action-${idx}`}>
                                            {column.render(null, item)}
                                        </Box>
                                    ) : null
                                )}
                            </Box>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                            {columns.map((column) => (
                                column.id !== "action" && column.id !== columns[0].id && (
                                    <Box key={column.id}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            {column.label}
                                        </Typography>
                                        <Typography variant="body1" fontWeight="medium">
                                            {column.render ? column.render(item[column.id], item) : item[column.id]}
                                        </Typography>
                                    </Box>
                                )
                            ))}
                        </Box>
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
}

export default CardCustom;

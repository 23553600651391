import { Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Alert, Snackbar } from "@mui/material";
import AffiliateDialogContainer from "../AffiliateDialogContainer";
import TotalEarningDialog from "../TotalEarningDialog";
import AccountContainer from "../AccountContainer";
import { useDispatch, useSelector } from "react-redux";
import { setError, updateUi, triggerRefresh } from "../../../../reducers/slices/affiliateSlice";
import affiliateByAdmin from "../../../../services/admin/affiliate/affiliate";
import MessageCustoms from "../../../../components/message/MessageCustoms";

const AffiliateActions = () => {
    const dispatch = useDispatch();
    const { ui } = useSelector(state => state.affiliate)
    const handleMenuClose = () => {
        dispatch(updateUi({ anchorEl: null, selectedId: null }))
    }
    const handleViewCommission = async (data) => {
        try {
            dispatch(updateUi({
                openEarningDialog: true,
                earningData: data
            }));
        } catch (error) {
            dispatch(setError(error.message));
        }
        handleMenuClose();
    }
    const handleViewAffiliate = async (data) => {
        try {
            dispatch(updateUi({
                openDialog: true,
                selectedData: data?._id
            }));
        } catch (error) {
            dispatch(setError(error.message));
        }
        handleMenuClose();
    }
    const handlePromoteUser = async (data) => {
        try {
            await affiliateByAdmin.promoteUser(data?._id);
            dispatch(triggerRefresh());

            dispatch(updateUi({
                openDialog: true,
                selectedData: data?._id,
                showAlert: true,
                alertMessage: "Chỉ định làm F1 thành công",
                alertSeverity: 'success'
            }));
        } catch (error) {
            dispatch(updateUi({
                showAlert: true,
                alertMessage: "Chỉ có thể chỉ định làm F1 đối với đối tác cấp 3 chưa có người giới thiệu",
                alertSeverity: 'error'
            }));
            dispatch(setError(error.message));
        }
        handleMenuClose();
    }
    const handleUpdateStatus = async (data) => {
        try {
            await affiliateByAdmin.updateStatus(data?._id);
            dispatch(triggerRefresh());
        } catch (error) {
            dispatch(updateUi({
                showAlert: true,
                alertMessage: "Có lỗi xảy ra khi cập nhật trạng thái",
                alertSeverity: 'error'
            }));
            dispatch(setError(error.message));
        }
        handleMenuClose();
    }
    const handleViewsAccount = async (data) => {
        try {
            dispatch(updateUi({
                openAccountDialog: true,
                accountData: data,
            }));
        } catch (error) {
            dispatch(setError(error.message));
        }
        handleMenuClose();

    }
    return (
        <>
            <Menu
                anchorEl={ui.anchorEl}
                open={Boolean(ui.anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => handleViewCommission(ui.selectedId)}>Xem hoa hồng</MenuItem>
                <MenuItem onClick={() => handleViewsAccount(ui.selectedId)}>Xem các khách đã giới thiệu</MenuItem>
                <MenuItem onClick={() => handleViewAffiliate(ui.selectedId)}>Xem cây quan hệ Affiliate</MenuItem>
                <MenuItem onClick={() => handleUpdateStatus(ui.selectedId)}>Cập nhật trạng thái</MenuItem>
                <MenuItem onClick={() => handlePromoteUser(ui.selectedId)}>Chỉ định làm F1</MenuItem>

            </Menu>

            <AffiliateDialogContainer
                open={ui.openDialog}
                onClose={() => dispatch(updateUi({ openDialog: false, selectedData: null }))}
                id={ui.selectedData}
            />

            <TotalEarningDialog
                open={ui.openEarningDialog}
                onClose={() => dispatch(updateUi({ openEarningDialog: false, earningData: null }))}
                data={ui.earningData}
            />
            <AccountContainer
                open={ui.openAccountDialog}
                onClose={() => dispatch(updateUi({ openAccountDialog: false, accountData: null }))}
                data={ui.accountData}
            />
            {/* <Dialog
                open={dispatch(updateUi({ openConfirmDialog: true }))}
                onClose={dispatch(updateUi({ openConfirmDialog: false }))}
            >
                <DialogTitle>Xác nhận cập nhật</DialogTitle>
                <DialogContent>
                    <Typography>
                        Bạn có chắc chắn muốn thay đổi trạng thái của đối tác này?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={dispatch(updateUi({ openConfirmDialog: false }))}>Hủy</Button>
                    <Button onClick={handleUpdateStatus} variant="contained" color="primary">
                        Xác nhận
                    </Button>
                </DialogActions>
            </Dialog> */}

            <MessageCustoms open={ui.showAlert}
                autoHideDuration={3000}
                onClose={() => dispatch(updateUi({ showAlert: false }))}
                severity={ui.alertSeverity}
                message={ui.alertMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
        </>
    );
};

export default AffiliateActions; 
import { Button, styled, TableCell, tableCellClasses, TableRow } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalCustom from '../../../components/modals/ModalCustom';
import React, { useState } from 'react';
import AffilitionTableItemDetail from './AffilitionTableItemDetail';
import AfiiliationTableDetail from './AfiiliationTableDetail';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const AffiliationTableItem = ({ aff }) => {
  const [isOpen, setIsOpen] = useState(false);
  if (!aff) return null;

  const handleClose = () => setIsOpen(false);
  const handleClick = () => {
    setIsOpen(true);
    console.log(aff);
  };

  return (
    <>
      <StyledTableRow>
        <StyledTableCell>{aff.customer.name}</StyledTableCell>
        <StyledTableCell>{aff.packageCount}</StyledTableCell>
        <StyledTableCell>{aff.totalPrice?.toLocaleString('vi-VN')}</StyledTableCell>
        <StyledTableCell>{aff.bonus?.toLocaleString('vi-VN')}</StyledTableCell>
        <StyledTableCell>
          <Button onClick={handleClick}>
            <VisibilityIcon />
          </Button>
        </StyledTableCell>
      </StyledTableRow>
      <ModalCustom
        open={isOpen}
        onClose={handleClose}
        size="large"
        placement="center"
      >
        <AfiiliationTableDetail aff={aff} />

      </ModalCustom>
    </>
  );
};

export default AffiliationTableItem;

import { Box } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Sidebar from '../templates/components/sidebar/Sidebar';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import LinkIcon from '@mui/icons-material/Link';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import authService from '../services/authService';

const Bot = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentBot } = useSelector((state) => state.bot);
  const [affiliateData, setAffiliateData] = useState(null);

  const getPages = () => {
    const basePages = [
      { title: 'Cài đặt Bot', href: '/setting', icon: <SettingsOutlinedIcon /> },
      { title: 'Tích hợp Bot vào Website', href: '/integration', icon: <IntegrationInstructionsOutlinedIcon /> },
      { title: 'Thư viện sản phẩm', href: '/products', icon: <InventoryOutlinedIcon /> },
      { title: 'Cấu hình Chatbot', href: '/chatbox-setting', icon: <DisplaySettingsOutlinedIcon /> },
      { title: 'Tích hợp Bot vào Facebook', href: '/social-connect', icon: <LinkIcon /> },
      {
        title: 'Mua gói / Nâng cấp tài khoản',
        href: '/packages',
        icon: <CurrencyExchangeOutlinedIcon />,
        highlight: true
      },
    ];

    if (affiliateData) {
      basePages.push({
        title: 'Trang quản lý Affiliate',
        externalLink: 'https://affiliate.schat.vn/',
        icon: <GroupIcon />,
        highlight: true
      });
    } else {
      basePages.push({
        title: 'Đăng ký Affiliate',
        externalLink: 'https://affiliate.schat.vn/',
        icon: <GroupAddIcon />,
        highlight: true
      });
    }

    return basePages;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await authService.getAffiliate();
        setAffiliateData(res);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (currentBot?.id) {
      gotoBot(currentBot.id);
    }
  }, [currentBot]);

  const gotoBot = (botId) => {
    const path = location.pathname;
    const subPath = path.split('/').slice(3).join('/');
    navigate(`${botId}/${subPath}`);
  };

  return (
    <Box display={'flex'} flexDirection={'row'}>
      <Sidebar pages={getPages()} />
      <Box px={5} py={4} flexGrow={1}>
        {currentBot && <Outlet />}
      </Box>
    </Box>
  );
};

export default Bot;

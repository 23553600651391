import { Avatar, Box, Button, CardContent, Divider, Drawer, Typography, useMediaQuery, useTheme } from '@mui/material';
import CardContentHeader from '../../../components/cards/CardContentHeader';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import AccountInformationMenu from './AccountInformationMenu';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuIcon from '@mui/icons-material/Menu';

const AccountInformation = () => {
  const theme = useTheme();
  const authSelector = useSelector((state) => state.auth);
  const [userLogged, setUserLogged] = useState(authSelector.user);
  const user = useSelector((state) => state.auth.user);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isOpen, setIsOpen] = useState(!isMobile);

  useEffect(() => {
    setIsOpen(!isMobile);
    setIsCollapsed(false);
  }, [isMobile]);

  const toggleCollapse = () => {
    if (!isMobile) {
      setIsCollapsed(!isCollapsed);
    }
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = () => {
    if (isMobile) {
      toggleDrawer();
    }
  };

  return (
    <Box>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isOpen}
        onClose={toggleDrawer}
        sx={{
          position: 'relative',
          width: isCollapsed ? '80px' : "310px",
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isCollapsed ? '80px' : "310px",
            boxSizing: 'border-box',
            overflowX: 'hidden',
            transition: theme.transitions.create(['width', 'transform'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            zIndex: isMobile ? theme.zIndex.drawer + 2 : 'auto',
          },
        }}
      >
        <CardContent sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          p: isCollapsed ? 1 : 2,
          gap: isCollapsed ? 1 : 2,
          transition: 'all 0.3s ease',
          mt: 10,
        }}>
          {!isCollapsed && (
            <CardContentHeader
              title={'Thông tin cá nhân'}
              subtitle={'Cài đặt thông tin cá nhân, gói cước'}
            />
          )}
          <Box
            display={'flex'}
            justifyContent={isCollapsed ? 'center' : 'flex-start'}
            flexDirection={'row'}
            alignItems={'center'}
          // sx={{ ml: isCollapsed ? 0 : 10 }}
          >
            <Avatar
              sx={{
                width: isCollapsed ? 40 : 50,
                height: isCollapsed ? 40 : 50,
                border: `3px solid ${theme.palette.grey[300]}`,
                transition: 'all 0.3s ease'
              }}
            ></Avatar>
            {!isCollapsed && (
              <Box ml={3} display={'flex'} flexDirection={'column'}>
                <Typography variant="h6" fontWeight={500}>
                  {userLogged.name}
                </Typography>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mb={0.25}>
                  <EmailOutlinedIcon sx={{ mr: 1, fontSize: 15, color: 'text.secondary' }} />
                  <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 'normal', width: '100%', wordBreak: 'break-word' }}>
                    {userLogged.email}
                  </Typography>
                </Box>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                  <PhoneOutlinedIcon sx={{ mr: 1, fontSize: 15, color: 'text.secondary' }} />
                  <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 'normal' }}>
                    {userLogged.phone}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          {!isCollapsed && <Divider />}
          <Box sx={{ flex: '1 1 auto' }}>
            <AccountInformationMenu
              isCollapsed={isCollapsed}
              onMenuClick={handleMenuClick}
              isMobile={isMobile}
            />
          </Box>
          {!isMobile && (
            <Button
              onClick={toggleCollapse}
              sx={{
                minWidth: 'auto',
                position: 'fixed',
                bottom: 15,
                // width: isCollapsed ? 80 : '100px',
                maxWidth: '250px',
                p: 2,
                width: isCollapsed ? 'auto' : '100%',
                borderRadius: isCollapsed ? '50%' : 2,
                alignSelf: 'center',
                bgcolor: theme.palette.background.default,
                zIndex: theme.zIndex.drawer + 1,

                '& .MuiSvgIcon-root': {
                  fontSize: isCollapsed ? 20 : 24,
                  transition: 'all 0.3s ease'
                }
              }}
            >
              {isCollapsed ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />}
            </Button>
          )}
        </CardContent>
      </Drawer>

      {isMobile && (
        <Button
          onClick={toggleDrawer}
          sx={{
            position: 'fixed',
            bottom: 20,
            left: 20,
            minWidth: 'auto',
            width: 56,
            height: 56,
            borderRadius: '50%',
            bgcolor: theme.palette.primary.main,
            color: 'white',
            boxShadow: 3,
            zIndex: theme.zIndex.drawer + 1,
            '&:hover': {
              bgcolor: theme.palette.primary.dark,
            }
          }}
        >
          {isOpen ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />}
        </Button>
      )}
    </Box>
  );
};

export default AccountInformation;

import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, Paper, RadioGroup, FormControlLabel, Radio, FormControl, IconButton, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../../reducers/slices/userSlice';
import { triggerRefresh, updateInfoUser, updateUserAsync } from '../../../reducers/slices/userSlice1';

const UpdateUser = ({ user, setIsOpen }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    avatar: null,
    name: '',
    phone: '',
    email: '',
    address: '',
    role: 'user',
    isActive: true
  });

  useEffect(() => {
    if (user) {
      setFormData({
        avatar: user.avatar || null,
        name: user.name || '',
        phone: user.phone || '',
        email: user.email || '',
        address: user.address || '',
        role: user.role || 'user',
        isActive: user.isActive
      });
    }
  }, [user]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          avatar: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'isActive' ? value === 'true' : value,
    }));
  };
  const handleUpdate = async () => {
    try {
      const updatedData = { ...formData, id: user?._id };
      await dispatch(updateUserAsync(updatedData));
      dispatch(triggerRefresh());
      setIsOpen(false);
    } catch (error) {
      console.error('Failed to update user:', error);
    }
  };
  return (
    <Paper
      sx={{
        maxWidth: 600,
        margin: 'auto',
        padding: 4,
        borderRadius: 2,
        maxHeight: '90vh',
        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
          position: 'relative'
        }}
      >
        {/* Avatar Section */}
        <Box
          sx={{
            position: 'relative',
            width: 120,
            height: 120,
            mb: 2
          }}
        >
          <label htmlFor="avatar-upload">
            {/* <input
              id="avatar-upload"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            /> */}
            <Avatar
              sx={{
                width: 120,
                height: 120,
                cursor: 'pointer',
                bgcolor: '#f5f5f5',
                border: '3px dashed',
                borderColor: 'primary.main',
                '&:hover': {
                  opacity: 0.8
                },

              }}
              disabled
            >
              {formData.avatar ? (
                <Box
                  component="img"
                  src={formData.avatar}
                  alt="Avatar"
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <AccountCircleIcon
                  sx={{
                    width: '100%',
                    height: '100%',
                    color: 'primary.main'
                  }}
                />
              )}
            </Avatar>
          </label>
        </Box>

        {/* Form Fields */}
        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
          <TextField
            fullWidth
            label="Tên tài khoản"
            variant="outlined"
            name="name"
            value={formData.name}
            onChange={handleChange}
            sx={{ bgcolor: '#f5f5f5' }}
          />

          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            name="email"
            value={formData.email}
            onChange={handleChange}
            disabled
            sx={{ bgcolor: '#f5f5f5' }}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
          <TextField
            fullWidth
            label="Số điện thoại"
            variant="outlined"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            sx={{ bgcolor: '#f5f5f5' }}
          />

          <TextField
            fullWidth
            label="Địa chỉ"
            variant="outlined"
            name="address"
            value={formData.address}
            onChange={handleChange}
            sx={{ bgcolor: '#f5f5f5' }}
          />
        </Box>

        <Box sx={{ width: '100%' }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Vai trò
          </Typography>
          <FormControl fullWidth>
            <RadioGroup
              row
              name="role"
              value={formData.role}
              onChange={handleChange}
              sx={{
                justifyContent: 'flex-start',
                gap: 4
              }}
            >
              <FormControlLabel
                value="admin"
                control={<Radio />}
                label="Admin"
                sx={{
                  color: formData.role === 'admin' ? 'primary.main' : 'text.primary'
                }}
              />
              <FormControlLabel
                value="user"
                control={<Radio />}
                label="Người dùng"
                sx={{
                  color: formData.role === 'user' ? 'primary.main' : 'text.primary'
                }}
              />
            </RadioGroup>
          </FormControl>
        </Box>


        <Box sx={{ width: '100%' }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Trạng thái
          </Typography>
          <FormControl fullWidth>
            <RadioGroup
              row
              name="isActive"
              value={formData.isActive.toString()}
              onChange={handleChange}
              sx={{
                justifyContent: 'flex-start',
                gap: 2
              }}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Hoạt động"
                sx={{
                  color: formData.isActive ? 'success.main' : 'text.primary'
                }}
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Không hoạt động"
                sx={{
                  color: !formData.isActive ? 'error.main' : 'text.primary'
                }}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Button
          fullWidth
          variant="contained"
          onClick={handleUpdate}
          sx={{
            mt: 2,
            py: 1.5,
            bgcolor: '#00ACC1',
            '&:hover': {
              bgcolor: '#00838F'
            }
          }}
        >
          Cập nhật
        </Button>
        <IconButton sx={{ position: 'absolute', top: -14, right: -10 }} onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

    </Paper>
  );
};

export default UpdateUser;

import { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Avatar,
  Typography,
  Tooltip,
  IconButton,
  useMediaQuery,
  Chip,
  Pagination,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ModalCustom from '../../../components/modals/ModalCustom';
import CustomTable from '../../../components/table/TableCustom';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@emotion/react';
import CardCustom from '../../../components/table/CardCustom';
import UserTableFilter from './UserTableFilter';
import InfoIcon from '@mui/icons-material/Info';
import UpdateUser from './UpdateUser';
import userByAdmin from '../../../services/admin/users/user';
import { getUsers, setError, updateUI, updateUsers } from '../../../reducers/slices/userSlice1';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import DetailAffiliate from './DetailAffiliate';
import MessageCustoms from '../../../components/message/MessageCustoms';
import { formatDate } from '../../../utils/formatters';

const UserTable = () => {
  const dispatch = useDispatch();
  const { users, total = 0, totalPages, filters, ui } = useSelector((state) => state.users1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [data, setData] = useState([]);
  const [referrer, setReferrer] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await userByAdmin.getAllUser(
          filters.dateFilter,
          filters.page,
          filters.limit,
          filters.search,
          filters.paidFilter,
          filters.startDate ? dayjs(filters.startDate).format('YYYY-MM-DD') : '',
          filters.endDate ? dayjs(filters.endDate).format('YYYY-MM-DD') : '',
          filters.refresh);
        dispatch(getUsers(res));
      } catch (error) {
        dispatch(updateUI({
          showAlert: true,
          alertMessage: error.response?.data?.message === "Start date and end date are required for custom date filter" ? "Ngày bắt đầu và ngày kết thúc là bắt buộc đối với bộ lọc ngày tùy chỉnh" :
            error.response?.data?.message === "Start date must be before end date" ? "Ngày bắt đầu phải trước ngày kết thúc" : "Lỗi sever",
          alertSeverity: 'error',
        }))
        dispatch(setError(error));
      }
    }
    fetchData();
  }, [dispatch,
    filters.dateFilter,
    filters.page,
    filters.limit,
    filters.search,
    filters.paidFilter,
    filters.startDate,
    filters.endDate,
    filters.refresh
  ]);

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get('page')) || 1;
    dispatch(updateUsers({ page: pageFromUrl }));
  }, []);

  const handleChangePage = (event, newPage) => {
    dispatch(updateUsers({ page: newPage }));
    setSearchParams({ page: newPage, search: filters.search });

  };
  const handleClose = () => setIsOpen(false);
  const handleClick = (data) => {
    setData(data);
    setIsOpen(true);
  };
  const handleCloseDialog = () => setIsOpenDialog(false);
  const handleClickDialog = (data) => {
    setReferrer(data);
    setIsOpenDialog(true);
  };
  const columns = [
    {
      id: "name", label: "Tên khách hàng", render: (_, data) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ borderRadius: '50%', border: data?.isActive ? '3px solid #4caf50' : '3px solid #6C757D' }}>
            <Avatar src={data?.avatar} />
          </Box>
          <Box>
            <Typography fontWeight="bold" sx={{ width: "100%", wordBreak: 'break-word' }}>{data?.name}</Typography>
            <Typography fontWeight="bold" sx={{ width: "100%", wordBreak: 'break-word' }}>{data?.email}</Typography>
          </Box>
        </Box>
      )
    },

    { id: "createdAt", label: "Ngày đăng ký", render: (_, data) => <Typography>{formatDate(data?.createdAt)}</Typography> },
    { id: "isActive", label: "Trạng thái ", render: (_, data) => <Typography>{data?.isActive ? <Chip label="Hoạt động" size='small' color='primary' /> : <Chip label="Không hoạt động" size='small' color='error' />}</Typography> },
    {
      id: "name", label: "Gói hiện tại", render: (_, data) => <Typography>{data?.package?.name === 'free' ? "Dùng thử" : data?.package?.name === 'basic' ? "Cơ bản" : data?.package?.name === 'standard' ? "Tiêu chuẩn" : 'Nâng cao'}</Typography>
    },
    {
      id: "hasPaidPackage", label: "Trạng thái trả phí", render: (_, data) =>
        <Typography>{data?.hasPaidPackage ? <Chip label="Đã trả phí" size='small' color='secondary' /> : <Chip label="Chưa trả phí" size='small' color='warning' />}</Typography>
    },
    {
      id: "referrer", label: "Người giới thiệu", render: (_, data) => (
        data?.referrer === null ? "Không có" : <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box sx={{ borderRadius: '50%', border: data?.isActive ? '3px solid #4caf50' : '3px solid #6C757D' }}>
              <Avatar src={data?.referrer?.owner?.avatar} />
            </Box>
            <Box>
              <Typography fontWeight="bold" sx={{ width: "100%", wordBreak: 'break-word' }}>{data?.referrer?.owner?.name}</Typography>
              <Typography >Cấp độ: {data?.referrer?.level}</Typography>
            </Box>
          </Box>
          <Tooltip title="Xem thông tin người giới thiệu">
            <IconButton onClick={() => handleClickDialog(data?.referrer)}>
              <InfoIcon color='primary' />
            </IconButton>
          </Tooltip>

        </Box>
      )
    },

    {
      id: "action", label: "Thao tác", render: (_, data) =>
        <Tooltip title="Chỉnh sửa">
          <IconButton onClick={() => handleClick(data)}>
            <EditIcon color='primary' />
          </IconButton>
        </Tooltip>
    },

  ]
  return (
    <Container sx={{
      height: { xs: "90vh", lg: '86vh' },
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      // padding: '16px'
    }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: isMobile ? "flex-start" : 'center',
          justifyContent: 'space-between',
          flexDirection: isMobile ? 'column' : 'row',
          marginBottom: 2,
          position: 'sticky',
          top: 0,
          backgroundColor: 'background.default',
          zIndex: 1000,
        }}
      >
        <UserTableFilter />
      </Box>
      <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex' }}>
        {isMobile ?
          <Box sx={{ gap: 2, overflow: 'auto', flex: 1 }}>
            <CardCustom columns={columns} data={users} />
          </Box>
          :
          <Box sx={{ width: '100%' }}>
            <CustomTable columns={columns} data={users} />
          </Box>
        }
      </Box>

      <Box sx={{
        display: 'flex',
        justifyContent: 'end',
        py: 0.5,
        px: 3,
        borderColor: 'divider',
        backgroundColor: theme.palette.mode === 'dark' ? '#1a1a1a' : '#fff'
      }}>
        <Typography sx={{ mr: 2, border: '1px solid #e0e0e0', py: 0.4, px: 2, borderRadius: '5px', bgcolor: '#E0E0E0' }}>
          {`Tổng số: ${total || 0}`}
        </Typography>
        <Pagination
          count={totalPages}
          page={filters?.page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          siblingCount={1}
        />
      </Box>
      <ModalCustom open={isOpen} onClose={handleClose} size="large" placement="center">
        <UpdateUser setIsOpen={setIsOpen} user={data} />
      </ModalCustom>
      <DetailAffiliate onClose={handleCloseDialog} open={isOpenDialog} referrer={referrer} />
      <MessageCustoms open={ui.showAlert}
        autoHideDuration={3000}
        onClose={() => dispatch(updateUI({ showAlert: false }))}
        severity={ui.alertSeverity}
        message={ui.alertMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Container>
  );
};

export default UserTable;

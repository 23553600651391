import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const BuyPackage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Điều hướng đến trang packages khi component được mount
    navigate('/account/packages');
  }, [navigate]);

  // Component này không cần render gì vì sẽ điều hướng ngay lập tức
  return null;
};

export default BuyPackage;
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';

const pages = [
  {
    title: 'Lịch sử chat',
    href: '/chats',
  },
  {
    title: 'Cài đặt Bot',
    href: '/bots',
  },
  {
    title: 'Danh sách Bot',
    href: '/list-bot',
  },
];

const HeaderTabs = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const isActivePage = (page) => {
    return matchPath({ path: page.href, end: false }, location.pathname) !== null;
  };

  const goToPage = (page) => {
    navigate(page.href);
    handleClose();
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
      <Typography
        variant="h5"
        component="a"
        noWrap
        href="/"
        sx={{
          flexGrow: 1,
          display: { xs: 'none', md: 'flex' },
          fontWeight: 400,
          letterSpacing: '.2rem',
          color: 'inherit',
          textDecoration: 'none',
          mr: 5,
        }}
      >
        <img src="/images/logo2-removebg.png" style={{ width: '60px' }} />
      </Typography>

      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size="large"
          aria-label="menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          sx={{
            color: 'inherit',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
            transition: 'all 0.3s ease',
          }}
        >
          <MenuIcon sx={{ fontSize: 28 }} />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: 2,
              minWidth: 180,
              backgroundColor: "#fff",
              border: '1px solid rgba(0, 0, 0, 0.12)',
              boxShadow: theme.shadows[3],
              mt: 0.5,
            },
            '& .MuiList-root': {
              padding: 1,
            },
          }}
          PaperProps={{
            elevation: 0,
          }}
        >
          {pages.map((page) => (
            <MenuItem
              key={page.title}
              onClick={() => goToPage(page)}
              sx={{
                borderRadius: 1,
                mx: 0.5,
                my: 0.5,
                fontSize: '0.875rem',
                fontWeight: isActivePage(page) ? 600 : 400,
                backgroundColor: isActivePage(page)
                  ? `${theme.palette.primary.main}15`
                  : 'transparent',
                color: isActivePage(page)
                  ? theme.palette.primary.main
                  : theme.palette.text.primary,
                '&:hover': {
                  backgroundColor: isActivePage(page)
                    ? `${theme.palette.primary.main}25`
                    : `${theme.palette.primary.main}10`,
                },
                transition: 'all 0.2s ease',
                '& .MuiSvgIcon-root': {
                  fontSize: 20,
                  marginRight: 1,
                  color: isActivePage(page)
                    ? theme.palette.primary.main
                    : theme.palette.text.secondary,
                },
              }}
            >
              {page.title}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        {pages.map((page) => (
          <Button
            key={page.title}
            onClick={() => goToPage(page)}
            variant="text"
            sx={{
              fontSize: '0.75rem',
              m: 1,
              px: 1.5,
              py: 1,
              color: 'white',
              backgroundColor: isActivePage(page) ? theme.palette.primary.dark : '',
              '&:hover': {
                backgroundColor: isActivePage(page)
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light,
              },
            }}
          >
            {page.title}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default HeaderTabs;

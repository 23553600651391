import { Alert, Avatar, Box, Breadcrumbs, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Menu, MenuItem, Pagination, Snackbar, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomTable from '../../../components/table/TableCustom'
import { useTheme } from '@emotion/react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import CardCustom from '../../../components/table/CardCustom'
import affiliateByAdmin from '../../../services/admin/affiliate/affiliate'
import { useDispatch, useSelector } from 'react-redux'
import { getAccountReferrals, setError, updateAccountReferrals, updateUI } from '../../../reducers/slices/affiliateReferralsSlice'
import dayjs from 'dayjs'
import { debounce } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import MessageCustoms from '../../../components/message/MessageCustoms'
import { formatDate } from '../../../utils/formatters'
const AccountContainer = ({ open, onClose, data }) => {
    const dispatch = useDispatch();
    const { accountReferrals, totalPages, filters, total, ui } = useSelector(state => state.referrals);
    const theme = useTheme();
    const [searchParams, setSearchParams] = useSearchParams();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [temStartDate, setTemStartDate] = useState(null);
    const [temEndDate, setTemEndDate] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            if (!open) return;
            try {
                const response = await affiliateByAdmin.getAffiliateReferrals(
                    data?._id,
                    filters.dateFilter,
                    filters.page,
                    filters.limit,
                    filters.search,
                    filters.paidFilter,
                    filters.startDate ? dayjs(filters.startDate).format('YYYY-MM-DD') : '',
                    filters.endDate ? dayjs(filters.endDate).format('YYYY-MM-DD') : '',
                    filters.affiliateFilter,
                );

                dispatch(getAccountReferrals(response));
            } catch (error) {
                dispatch(updateUI({
                    showAlert: true,
                    alertMessage: error?.response?.data?.message === "Start date and end date are required for custom date filter" ? "Ngày bắt đầu và ngày kết thúc là bắt buộc đối với bộ lọc ngày tùy chỉnh" :
                        error?.response?.data?.message === "Start date must be before end date" ? "Ngày bắt đầu phải trước ngày kết thúc" : "Lỗi sever",
                    alertSeverity: 'error',
                }))
                dispatch(setError(error));
            }
        }
        fetchData();
    }, [open, data?._id, filters.dateFilter, filters.page, filters.limit, filters.search, filters.paidFilter, filters.startDate, filters.endDate, filters.affiliateFilter, dispatch]);

    useEffect(() => {
        const pageFromUrl = parseInt(searchParams.get('page')) || 1;
        dispatch(updateAccountReferrals({ page: pageFromUrl }));
    }, []);

    const handleSearchChange = debounce((e) => {
        dispatch(updateAccountReferrals({ search: e.target.value, page: 1 }))
    }, 1000)

    const handlePageChange = (event, newPage) => {
        dispatch(updateAccountReferrals({ page: newPage }));
        setSearchParams({ page: newPage, search: filters.search });
    };
    const columns = [
        {
            id: 'name', label: 'Tên', render: (_, data) => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box sx={{ borderRadius: '50%', border: '3px solid #4caf50' }}>
                        <Avatar src={data?.avatar} />
                    </Box>
                    <Box>
                        <Typography fontWeight="bold">{data?.name}</Typography>
                        {data.affiliateInfo === null ? "" : <Typography variant="body1">Cấp độ: {data?.affiliateInfo?.level}</Typography>}
                    </Box>
                </Box>
            )
        },
        { id: 'email', label: 'Email', render: (email) => <Typography sx={{ width: isMobile ? 150 : "100%", wordBreak: 'break-word' }}>{email}</Typography> },
        { id: 'createdAt', label: 'Ngày đăng ký schat', render: (createdAt) => formatDate(createdAt) },
        { id: 'createdAt', label: 'Ngày đăng ký Affiliate', render: (_, data) => data?.affiliateInfo?.createdAt ? formatDate(data?.affiliateInfo?.createdAt) : <Chip size='small' label="Chưa đăng ký" color='default' /> },
        { id: 'isAffiliate', label: 'Trạng thái đăng ký Affiliate', render: (isAffiliate) => isAffiliate ? <Chip size='small' label="Đã đăng ký" color='primary' /> : <Chip size='small' label="Chưa đăng ký" color='default' /> },
        { id: 'hasPaidPackage', label: 'Trạng thái mua gói', render: (hasPaidPackage) => hasPaidPackage ? <Chip size='small' label="Đã mua gói" color='primary' /> : <Chip size='small' label="Chưa mua gói" color='default' /> },
        {
            id: "name", label: "Gói hiện tại", render: (_, data) => <Typography>{data?.package?.name === 'free' ? "Dùng thử" : data?.package?.name === 'basic' ? "Cơ bản" : data?.package?.name === 'standard' ? "Tiêu chuẩn" : 'Nâng cao'}</Typography>
        },
        {
            id: 'action', label: 'Thao tác', render: (_, data) => (
                data?.affiliateInfo === null ? "" :
                    <Tooltip title="Menu chi tiết">
                        <IconButton onClick={(e) => handleMenuOpen(e, data)}>
                            <MoreHorizIcon />
                        </IconButton>
                    </Tooltip>
            )
        },
    ]
    const handleFilterTypeChange = (event) => {
        const value = event.target.value;
        dispatch(updateAccountReferrals({ dateFilter: value }));
        setTemStartDate(null);
        setTemEndDate(null);
        if (value !== "custom") {
            dispatch(updateAccountReferrals({
                startDate: null,
                endDate: null
            }));
        }
    };
    const handleStartDateChange = (newValue) => {
        setTemStartDate(newValue);
        if (newValue && temEndDate) {
            const startDateISO = dayjs(newValue).isValid() ? dayjs(newValue).startOf('day').toISOString() : null;
            const endDateISO = dayjs(temEndDate).isValid() ? dayjs(temEndDate).endOf('day').toISOString() : null;
            dispatch(updateAccountReferrals({
                startDate: startDateISO,
                endDate: endDateISO,
            }));
        }
    }
    const handleEndDateChange = (newValue) => {
        setTemEndDate(newValue);
        if (newValue && temStartDate) {
            const startDateISO = dayjs(temStartDate).isValid() ? dayjs(temStartDate).startOf('day').toISOString() : null;
            const endDateISO = dayjs(newValue).isValid() ? dayjs(newValue).endOf('day').toISOString() : null;
            dispatch(updateAccountReferrals({
                startDate: startDateISO,
                endDate: endDateISO
            }));
        }
    }
    const handleUpdateStatusAffiliate = (e) => {
        const value = e.target.value;
        dispatch(updateAccountReferrals({ affiliateFilter: value }));
    }
    const handleUpdateStatusPaid = (e) => {
        const value = e.target.value;
        dispatch(updateAccountReferrals({ paidFilter: value }));
    }
    const handleMenuOpen = (event, id) => {
        setSelectedId(id);
        setAnchorEl(event.currentTarget);
    }
    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedId(null);
    }
    const handleViewDetails = async () => {
        try {
            const response = await affiliateByAdmin.getAffiliateReferrals(
                selectedId?.affiliateInfo?.id,
                filters.dateFilter,
                filters.page,
                filters.limit,
                filters.search,
                filters.paidFilter,
                filters.startDate ? dayjs(filters.startDate).format('YYYY-MM-DD') : '',
                filters.endDate ? dayjs(filters.endDate).format('YYYY-MM-DD') : '',
                filters.affiliateFilter,
            );
            dispatch(getAccountReferrals(response));
            handleMenuClose();
        } catch (error) {
            dispatch(updateUI({
                showAlert: true,
                alertMessage: 'Lỗi khi tải chi tiết giới thiệu',
                alertSeverity: 'error',
            }));
        }
    }
    return (
        <Dialog
            maxWidth="xl"
            fullWidth
            open={open}
            onClose={onClose}>
            <IconButton
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    zIndex: 1,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle>
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                }}>
                    <Typography variant="h6" >Danh sách tài khoản khách giới thiệu của: {data?.ownerInfo?.email}</Typography>
                </Box>
                <Box sx={{ my: 2 }}>
                    {/* <Breadcrumbs aria-label="breadcrumb">
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}><Avatar src={data
                            ?.ownerInfo.avatar} />
                            <Typography>
                                {data?.ownerInfo?.name}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}><Avatar src={selectedId?.avatar} />
                            <Typography>
                                {selectedId?.name}
                            </Typography>
                        </Box>
                    </Breadcrumbs> */}
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: 2,
                    alignItems: isMobile ? 'stretch' : 'center',
                    width: '100%',
                    justifyContent: isMobile ? 'normal' : 'space-between',
                }}>
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: isMobile ? 'normal' : 'space-between',
                    }}>
                        <TextField
                            select
                            label={isMobile ? "Lọc" : "Lọc theo thời gian"}
                            value={filters.dateFilter}
                            onChange={handleFilterTypeChange}
                            sx={{
                                minWidth: isMobile ? 100 : 180,
                                backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                                '& .MuiOutlinedInput-root': {
                                    height: 40,
                                },
                                '& .MuiInputLabel-root': {
                                    transform: 'translate(14px, 8px) scale(1)',
                                    '&.Mui-focused, &.MuiFormLabel-filled': {
                                        transform: 'translate(14px, -9px) scale(0.75)',
                                    }
                                }
                            }}
                        >
                            <MenuItem value="all">Tất cả</MenuItem>
                            <MenuItem value="today">Hôm nay</MenuItem>
                            <MenuItem value="yesterday">Hôm qua</MenuItem>
                            <MenuItem value="last7days">7 ngày gần nhất</MenuItem>
                            <MenuItem value="thisMonth">Tháng này</MenuItem>
                            <MenuItem value="lastMonth">Tháng trước</MenuItem>
                            <MenuItem value="custom">Tùy chỉnh</MenuItem>
                        </TextField>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ display: "flex", gap: 2 }}>
                                <DatePicker
                                    label="Ngày bắt đầu"
                                    value={temStartDate || (filters?.startDate ? dayjs(filters?.startDate) : null)}
                                    onChange={handleStartDateChange}
                                    format="DD/MM/YYYY"
                                    disabled={filters?.dateFilter !== "custom"}
                                    readOnly={filters?.dateFilter !== "custom"}
                                    sx={{
                                        backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                                        width: isMobile ? '100%' : 'auto',
                                        '& .MuiOutlinedInput-root': {
                                            height: 40,
                                            opacity: filters.dateFilter !== "custom" ? 0.7 : 1
                                        },
                                        '& .MuiInputLabel-root': {
                                            transform: 'translate(14px, 8px) scale(1)',
                                            '&.Mui-focused, &.MuiFormLabel-filled': {
                                                transform: 'translate(14px, -9px) scale(0.75)',
                                            }
                                        }
                                    }}
                                />
                                <DatePicker
                                    label="Ngày kết thúc"
                                    value={temEndDate || (filters?.endDate ? dayjs(filters?.endDate) : null)}
                                    onChange={handleEndDateChange}
                                    format="DD/MM/YYYY"
                                    disabled={filters?.dateFilter !== "custom"}
                                    readOnly={filters?.dateFilter !== "custom"}
                                    sx={{
                                        backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                                        width: isMobile ? '100%' : 'auto',
                                        '& .MuiOutlinedInput-root': {
                                            height: 40,
                                            opacity: filters.dateFilter !== "custom" ? 0.7 : 1
                                        },
                                        '& .MuiInputLabel-root': {
                                            transform: 'translate(14px, 8px) scale(1)',
                                            '&.Mui-focused, &.MuiFormLabel-filled': {
                                                transform: 'translate(14px, -9px) scale(0.75)',
                                            }
                                        }
                                    }}
                                />
                            </Box>
                        </LocalizationProvider>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        flexDirection: isMobile ? 'column' : 'row',
                        width: '100%',
                        justifyContent: isMobile ? 'normal' : 'flex-end',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}>
                            <TextField
                                select
                                label="Trạng thái đăng ký"
                                value={filters.affiliateFilter}
                                onChange={handleUpdateStatusAffiliate}
                                sx={{
                                    minWidth: isMobile ? 150 : 190,
                                    backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                                    '& .MuiOutlinedInput-root': {
                                        height: 40,
                                    },
                                    '& .MuiInputLabel-root': {
                                        transform: 'translate(14px, 8px) scale(1)',
                                        '&.Mui-focused, &.MuiFormLabel-filled': {
                                            transform: 'translate(14px, -9px) scale(0.75)',
                                        }
                                    }
                                }}
                            >
                                <MenuItem value={"all"}>Tất cả</MenuItem>
                                <MenuItem value={'affiliate'}>Đã đăng ký Affiliate</MenuItem>
                                <MenuItem value={'nonAffiliate'}>Chưa đăng ký Affiliate</MenuItem>
                            </TextField>
                            <TextField
                                select
                                label="Trạng thái trả phí"
                                value={filters.paidFilter}
                                onChange={handleUpdateStatusPaid}
                                sx={{
                                    minWidth: isMobile ? 150 : 200,
                                    backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                                    '& .MuiOutlinedInput-root': {
                                        height: 40,
                                    },
                                    '& .MuiInputLabel-root': {
                                        transform: 'translate(14px, 8px) scale(1)',
                                        '&.Mui-focused, &.MuiFormLabel-filled': {
                                            transform: 'translate(14px, -9px) scale(0.75)',
                                        }
                                    }
                                }}
                            >
                                <MenuItem value={'all'}>Tất cả</MenuItem>
                                <MenuItem value={'paid'}>Đã Trả phí</MenuItem>
                                <MenuItem value={'unpaid'}>Chưa trả phí</MenuItem>
                            </TextField>
                        </Box>
                        <TextField
                            label={isMobile ? "Tìm kiếm" : "Tìm kiếm bằng email hoặc tên"}
                            variant="outlined"
                            fullWidth
                            value={filters.searchText}
                            onChange={handleSearchChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon color="disabled" />
                                    </InputAdornment>
                                )
                            }}
                            sx={{
                                backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                                borderRadius: '8px',
                                '& .MuiOutlinedInput-root': {
                                    height: 40,
                                },
                                '& .MuiInputLabel-root': {
                                    transform: 'translate(14px, 8px) scale(1)',
                                    '&.Mui-focused, &.MuiFormLabel-filled': {
                                        transform: 'translate(14px, -9px) scale(0.75)',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100vh - 200px)',
                p: 0,
                '&:last-child': { pb: 0 }
            }}>
                <Box sx={{
                    flex: 1,
                    overflow: 'auto',
                    px: 3,
                    pt: 2
                }}>
                    {isMobile ? <CardCustom columns={columns} data={accountReferrals} /> :
                        <CustomTable columns={columns} data={accountReferrals} />}
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    py: 2,
                    px: 3,
                    borderColor: 'divider',
                    backgroundColor: theme.palette.mode === 'dark' ? '#1a1a1a' : '#fff'
                }}>
                    <Typography sx={{ mr: 2, border: '1px solid #e0e0e0', py: 0.4, px: 2, borderRadius: '5px', bgcolor: '#E0E0E0' }}>
                        {`Tổng số: ${total}`}
                    </Typography>
                    <Pagination
                        count={totalPages}
                        page={filters.page}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        siblingCount={1}
                    />
                </Box>
            </DialogContent>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleViewDetails}>Xem các khách đã giới thiệu</MenuItem>
            </Menu>
            <MessageCustoms open={ui.showAlert}
                autoHideDuration={3000}
                onClose={() => dispatch(updateUI({ showAlert: false }))}
                severity={ui.alertSeverity}
                message={ui.alertMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
        </Dialog>
    )
}

export default AccountContainer
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material'
import React from 'react'
import Avatar from '@mui/material/Avatar'
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
const BuyerInfo = ({ data }) => {
    return (
        <Card sx={{
            width: "100%",
            border: '1px solid #e0e0e0',
            borderRadius: '10px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.04)',
            bgcolor: '#F0F2FA',
        }}>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Thông tin người mua gói</Typography>
                    <IconButton>
                        <PersonIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                    <Avatar src={data?.package?.owner?.avatar} sx={{ width: 70, height: 70, }} />
                    <Typography variant="h5" fontWeight={'bold'}>{data?.package?.owner?.name}</Typography>
                    <Typography sx={{
                        color: '#333',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1.5
                    }}>
                        {data?.commissions?.some(comm => comm?.affiliate?.owner?.id === data?.package?.owner?._id)
                            ? ''
                            : data?.commissions?.[0]?.affiliate?.owner?.name
                            && `Khách hàng của ${data?.commissions[0]?.affiliate?.owner?.name} (Cấp ${data?.commissions[0]?.affiliate?.level})`
                        }
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                    <Typography sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1.5, color: '#333',
                        width: '100%',
                        bgcolor: '#F0F2FA',
                        border: '1px solid #e0e0e0',
                        borderRadius: '10px',
                        boxShadow: '0 1px 4px 1px rgba(11, 1, 1, 0.14)',
                        p: 1,
                        '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: '0 1px 10px 3px rgba(11, 1, 1, 0.14)',
                            transition: 'all 0.3s ease-in-out',
                            cursor: 'pointer',
                            bgcolor: '#b3e5fc'
                        }
                    }}>
                        <Box sx={{
                            bgcolor: '#FFF4E5',
                            p: 1.5,
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '40px',
                            height: '40px',
                            color: '#FF9800',
                            border: '1px solid rgb(170, 179, 255)',

                        }}>
                            <IconButton sx={{ fontSize: '1.2rem' }}><EmailIcon /></IconButton>

                        </Box>
                        <Box>
                            <Box sx={{ color: '#666', fontSize: '0.875rem' }}>Email:</Box>
                            <Box sx={{ fontWeight: 'bold' }}>{data?.package?.owner?.email}</Box>
                        </Box>
                    </Typography>
                    <Typography sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1.5, color: '#333',
                        width: '100%',
                        bgcolor: '#a7ffeb',
                        border: '1px solid #e0e0e0',
                        borderRadius: '10px', boxShadow: '0 1px 4px 1px rgba(11, 1, 1, 0.14)',
                        p: 1,
                        '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: '0 1px 10px 3px rgba(11, 1, 1, 0.14)',
                            transition: 'all 0.3s ease-in-out',
                            cursor: 'pointer',
                            bgcolor: '#b3e5fc'
                        }
                    }}>
                        <Box sx={{
                            bgcolor: '#f3e5f5',
                            p: 1.5,
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '40px',
                            height: '40px',
                            color: '#FF9800',
                            border: '1px solid rgb(170, 179, 255)',

                        }}>
                            <IconButton sx={{ fontSize: '1.2rem' }}><PhoneIcon /></IconButton>

                        </Box>
                        <Box>
                            <Box sx={{ color: '#666', fontSize: '0.875rem' }}>Số điện thoại:</Box>
                            <Box sx={{ fontWeight: 'bold' }}>{data?.package?.owner?.phone === undefined ? "Chưa cập nhật" : data?.package?.owner?.phone}</Box>
                        </Box>
                    </Typography>
                    <Typography sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1.5, color: '#333',
                        width: '100%',
                        bgcolor: '#fffde7',
                        border: '1px solid #e0e0e0',
                        borderRadius: '10px',
                        boxShadow: '0 1px 4px 1px rgba(11, 1, 1, 0.14)',
                        p: 1,
                        '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: '0 1px 10px 3px rgba(11, 1, 1, 0.14)',
                            transition: 'all 0.3s ease-in-out',
                            cursor: 'pointer',
                            bgcolor: '#b3e5fc'
                        }
                    }}>
                        <Box sx={{
                            bgcolor: '#b3e5fc',
                            p: 1.5,
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '40px',
                            height: '40px',
                            color: '#FF9800',
                            border: '1px solid rgb(170, 179, 255)',

                        }}>
                            <IconButton sx={{ fontSize: '1.2rem' }}><LocationOnIcon /></IconButton>

                        </Box>
                        <Box>
                            <Box sx={{ color: '#666', fontSize: '0.875rem' }}>Địa chỉ: </Box>
                            <Box sx={{ fontWeight: 'bold' }}>{data?.package?.owner?.address === undefined ? "Chưa cập nhật" : data?.package?.owner?.address}</Box>
                        </Box>
                    </Typography>

                </Box>
            </CardContent>
        </Card>
    )
}

export default BuyerInfo
import React, { useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box,
} from '@mui/material';
import no_data from "../../assets/media/images/no_data_remove.png"
import { useTheme } from '@emotion/react';

const CustomTable = ({ columns, data }) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    if (!data || data.length === 0) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                p={3}
            >
                <img
                    src={no_data}
                    alt="No data"
                    style={{ width: '200px', height: 'auto' }}
                />
            </Box>
        );
    }

    return (
        <TableContainer sx={{
            border: `1px solid ${isDarkMode ? '#424242' : '#E0E0E0'}`,
            borderRadius: '8px',
            backgroundColor: isDarkMode ? '#1e1e1e' : '#ffffff',
            maxHeight: '500px',
            overflow: 'auto',
            scrollbarWidth: 'none'
        }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {columns?.map((column) => (
                            <TableCell
                                sx={{
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1,
                                    // backgroundColor: isDarkMode ? '#2d2d2d' : '#ffffff',
                                    color: isDarkMode ? '#ffffff' : 'inherit'
                                }}
                                key={column.id}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{
                                backgroundColor: isDarkMode
                                    ? index % 2 === 0 ? '#2d2d2d' : '#1e1e1e'
                                    : index % 2 === 0 ? '#f5f5f5' : '#ffffff',
                                '&:hover': {
                                    backgroundColor: isDarkMode ? '#3d3d3d' : '#e3f2fd',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.2s ease'
                                },
                                '& .MuiTableCell-root': {
                                    color: isDarkMode ? '#ffffff' : 'inherit'
                                }
                            }}
                        >
                            {columns?.map((column) => (
                                <TableCell key={column.id}>
                                    {column?.render
                                        ? column.render(row[column?.id], row, index)
                                        : column.format
                                            ? column.format(row[column?.id])
                                            : row[column?.id]}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CustomTable;

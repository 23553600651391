import { Box, Card, CardContent, Typography } from '@mui/material';
import MainTitle from '../../../components/partials/MainTitle';
import CardContentHeader from '../../../components/cards/CardContentHeader';
import BotSocialConnectList from './BotSocialConnectList';
import { useModal } from '../../../hooks/useModal';
import BotSocialConnectCreateModal from './BotSocialConnectCreateModal';
import BotSocialConnectDetail from './BotSocialConnectDetail';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import facebookService from '../../../services/facebookService';
import FacebookIcon from '@mui/icons-material/Facebook';

const BotSocialConnect = () => {
  const [social, setSocial] = useState();
  // console.log(social);

  // const createConnectModal = useModal('create-connect', BotSocialConnectCreateModal, {
  //   configs: {
  //     size: 'lg',
  //   },
  // });
  const { currentSocialConnect } = useSelector((state) => state.socialConnect);
  const userInfo = useSelector((state) => state.auth.user);
  const botInfo = useSelector((state) => state.bot.currentBot);

  const [status, setStatus] = useState(null);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setStatus(queryParams.get('success'));
  }, []);

  // const openConnectModal = () => {
  //   createConnectModal.openModal();
  // };

  const handleLoginWithFacebook = () => {
    const reqQuery = {
      userId: userInfo.id,
      botId: botInfo.id,
    };

    const queryString = new URLSearchParams(reqQuery).toString();

    const url = `${process.env.REACT_APP_FB_AUTH_URL}?${queryString}`;

    window.open(url, '_self');
  };

  useEffect(() => {
    (async () => {
      const data = await facebookService.getPagesByUserId();
      setSocial(data);
    })();
  }, []);
  // const dataFake = {
  //   "owner": "68f915d47e58dd54897cd59d",
  //   "endDate": null,
  //   "startDate": "2024-03-20T08:45:12.123Z",
  //   "info": [
  //     {
  //       "_id": "68f915e32458dd54897cd5a1",
  //       "pageID": "645789123456789",
  //       "pageName": "Coffee Shop Online",
  //       "avatar": "https://scontent.fsgn2-5.fna.fbcdn.net/v/sample1.jpg",
  //       "botId": "68f915f52458dd54897cd5b2",
  //       "chatBoxID": null,
  //       "isSubscribed": true
  //     },
  //     {
  //       "_id": "68f915e32458dd54897cd5a2",
  //       "pageID": "645789123456790",
  //       "pageName": "Digital Marketing Pro",
  //       "avatar": "https://scontent.fsgn2-5.fna.fbcdn.net/v/sample2.jpg",
  //       "botId": "68f915f52458dd54897cd5b2",
  //       "chatBoxID": null,
  //       "isSubscribed": true
  //     },
  //     {
  //       "_id": "68f915e32458dd54897cd5a3",
  //       "pageID": "645789123456791",
  //       "pageName": "Fashion Boutique",
  //       "avatar": "https://scontent.fsgn2-5.fna.fbcdn.net/v/sample3.jpg",
  //       "botId": "68f915f52458dd54897cd5b3",
  //       "chatBoxID": null,
  //       "isSubscribed": true
  //     },
  //     {
  //       "_id": "68f915e32458dd54897cd5a4",
  //       "pageID": "645789123456792",
  //       "pageName": "Tech Gadgets Store",
  //       "avatar": "https://scontent.fsgn2-5.fna.fbcdn.net/v/sample4.jpg",
  //       "botId": "68f915f52458dd54897cd5b4",
  //       "chatBoxID": null,
  //       "isSubscribed": false
  //     },
  //     {
  //       "_id": "68f915e32458dd54897cd5a5",
  //       "pageID": "645789123456793",
  //       "pageName": "Foodie Paradise",
  //       "avatar": "https://scontent.fsgn2-5.fna.fbcdn.net/v/sample5.jpg",
  //       "botId": "68f915f52458dd54897cd5b5",
  //       "chatBoxID": null,
  //       "isSubscribed": true
  //     },
  //     {
  //       "_id": "68f915e32458dd54897cd5a6",
  //       "pageID": "645789123456794",
  //       "pageName": "Travel Adventures",
  //       "avatar": "https://scontent.fsgn2-5.fna.fbcdn.net/v/sample6.jpg",
  //       "botId": "68f915f52458dd54897cd5b6",
  //       "chatBoxID": null,
  //       "isSubscribed": true
  //     },
  //     {
  //       "_id": "68f915e32458dd54897cd5a7",
  //       "pageID": "645789123456795",
  //       "pageName": "Fitness & Health",
  //       "avatar": "https://scontent.fsgn2-5.fna.fbcdn.net/v/sample7.jpg",
  //       "botId": "68f915f52458dd54897cd5b7",
  //       "chatBoxID": null,
  //       "isSubscribed": false
  //     },
  //     {
  //       "_id": "68f915e32458dd54897cd5a8",
  //       "pageID": "645789123456796",
  //       "pageName": "Book Lovers Club",
  //       "avatar": "https://scontent.fsgn2-5.fna.fbcdn.net/v/sample8.jpg",
  //       "botId": "68f915f52458dd54897cd5b8",
  //       "chatBoxID": null,
  //       "isSubscribed": true
  //     },
  //     {
  //       "_id": "68f915e32458dd54897cd5a8",
  //       "pageID": "645789123456796",
  //       "pageName": "Book Lovers Club",
  //       "avatar": "https://scontent.fsgn2-5.fna.fbcdn.net/v/sample8.jpg",
  //       "botId": "68f915f52458dd54897cd5b8",
  //       "chatBoxID": null,
  //       "isSubscribed": true
  //     },
  //     {
  //       "_id": "68f915e32458dd54897cd5a8",
  //       "pageID": "645789123456796",
  //       "pageName": "Book Lovers Club",
  //       "avatar": "https://scontent.fsgn2-5.fna.fbcdn.net/v/sample8.jpg",
  //       "botId": "68f915f52458dd54897cd5b8",
  //       "chatBoxID": null,
  //       "isSubscribed": true
  //     },
  //     {
  //       "_id": "68f915e32458dd54897cd5a8",
  //       "pageID": "645789123456796",
  //       "pageName": "Book Lovers Club",
  //       "avatar": "https://scontent.fsgn2-5.fna.fbcdn.net/v/sample8.jpg",
  //       "botId": "68f915f52458dd54897cd5b8",
  //       "chatBoxID": null,
  //       "isSubscribed": true
  //     }

  //   ],
  //   "id": "68f915g62458dd54897cd5c3"
  // }
  return (
    <Box>
      <MainTitle
        title={'Tích hợp Bot vào Facebook'}
        buttonName={'Đăng nhập với Facebook'}
        buttonIcon={<FacebookIcon />}
        onClick={handleLoginWithFacebook}
      />
      <Card>
        <CardContent>
          {currentSocialConnect ? (
            <BotSocialConnectDetail socialId={social?.id} setSocial={setSocial} />
          ) : (
            <>
              <CardContentHeader title={'Danh sách Trang'} />
              {status && (
                <Alert
                  icon={<CheckIcon fontSize="inherit" sx={{ marginTop: '5px' }} />}
                  severity="success"
                  sx={{ marginBottom: '20px', backgroundColor: '#B7D78D' }}
                >
                  <Typography color="#587E49" variant="h5" marginBottom="10px">
                    Chúc mừng!
                  </Typography>
                  <Typography>Tài khoản của bạn đã được kết nối thành công.</Typography>
                  <Typography>
                    Bất cứ khi nào bạn cần làm mới acccess token hoặc đồng bộ hóa dữ liệu mới, chỉ cần đăng nhập lại với Facebook.
                  </Typography>
                </Alert>
              )}
              {social ? (
                <BotSocialConnectList data={social} setSocial={setSocial} />
              ) : (
                <Typography padding={10} textAlign="center" color="#777777">
                  Chưa có Trang nào được kết nối
                </Typography>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default BotSocialConnect;

import {
  Box,
  Button,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Toolbar,
  Typography,
  useTheme,
  IconButton,
  useMediaQuery,
  Tooltip,
  Fab,
} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuIcon from '@mui/icons-material/Menu';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar, setSidebarOpen } from '../../../reducers/slices/sidebarSlice';

const sidebarWidth = 320;
const collapsedWidth = 70;

const Sidebar = ({ title, pages }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.sidebar?.isOpen ?? true);
  const [mobileOpen, setMobileOpen] = useState(false);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const isActivePage = (page) => {
    if (!page.href) {
      return false;
    }
    return location.pathname.includes(page.href);
  };

  const goToPage = (page) => {
    if (page.externalLink) {
      window.open(page.externalLink, '_blank');
      return;
    }
    if (!page.href || isActivePage(page)) {
      return;
    }
    navigate(`${id}${page.href}`);
  };

  useEffect(() => {
    if (isSmallScreen) {
      dispatch(setSidebarOpen(false));
    } else {
      dispatch(setSidebarOpen(true));
    }
  }, [isSmallScreen, dispatch]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (variant) => (
    <Box
      p={variant === 'temporary' ? 3 : (!isOpen ? 1 : 3)}
      boxSizing={'border-box'}
      border={'none'}
      sx={{}}
    >
      {(variant === 'temporary' || isOpen) && (
        <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
          {title || 'Settings'}
        </Typography>
      )}
      <List>
        {pages?.length &&
          pages.map((page) => (
            <ListItem key={page.title} sx={{ px: 0, py: 0.8 }}>
              <Tooltip title={(variant === 'permanent' && !isOpen) ? page.title : ''} placement="right" arrow>
                <ListItemButton
                  onClick={() => {
                    goToPage(page);
                    if (isSmallScreen) {
                      setMobileOpen(false);
                    }
                  }}
                  selected={isActivePage(page)}
                  sx={{
                    minHeight: 48,
                    justifyContent: (variant === 'permanent' && !isOpen) ? 'center' : 'initial',
                    px: (variant === 'permanent' && !isOpen) ? 2.5 : 3,
                    py: 1.5,
                    color: page.title === 'Đăng ký Affiliate' ? '#2563eb' : page.title === 'Trang quản lý Affiliate' ? '#2563eb' :
                      page.highlight ? '#d97706' :
                        isActivePage(page) ? 'primary.main' : 'text.primary',
                    fontWeight: page.highlight || isActivePage(page) ? 'bold' : 'normal',
                    fontSize: page.highlight ? '1rem' : '0.9rem',
                    borderRadius: 2,
                    position: 'relative',
                    overflow: 'hidden',
                    backgroundColor: page.title === 'Đăng ký Affiliate' ? '#dbeafe !important' : page.title === 'Trang quản lý Affiliate' ? '#dbeafe !important' :
                      page.highlight ? '#fef3c7 !important' : 'transparent',
                    boxShadow: page.title === 'Đăng ký Affiliate' ? '0 2px 8px rgba(37, 99, 235, 0.15)' : page.title === 'Trang quản lý Affiliate' ? '0 2px 8px rgba(37, 99, 235, 0.15)' :
                      page.highlight ? '0 2px 8px rgba(217, 119, 6, 0.15)' : 'none',
                    transition: 'all 0.3s ease',
                    '@keyframes glow': {
                      '0%': {
                        boxShadow: '0 2px 8px rgba(217, 119, 6, 0.15)',
                      },
                      '50%': {
                        boxShadow: '0 2px 12px rgba(217, 119, 6, 0.3)',
                      },
                      '100%': {
                        boxShadow: '0 2px 8px rgba(217, 119, 6, 0.15)',
                      }
                    },
                    '@keyframes shine': {
                      '0%': {
                        left: '-100%',
                      },
                      '100%': {
                        left: '100%',
                      }
                    },
                    animation: page.highlight ? 'glow 2s infinite' : 'none',
                    '&::after': page.highlight ? {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: '-100%',
                      width: '50%',
                      height: '100%',
                      background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.4), transparent)',
                      animation: 'shine 3s infinite',
                    } : {},
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: (variant === 'permanent' && !isOpen) ? 0 : '36px',
                      mr: (variant === 'permanent' && !isOpen) ? 0 : 3,
                      justifyContent: 'center',
                      color: page.title === 'Đăng ký Affiliate' ? '#2563eb' : page.title === 'Trang quản lý Affiliate' ? '#2563eb' :
                        page.highlight ? '#d97706' :
                          isActivePage(page) ? 'primary.main' : 'text.primary',
                      transform: page.highlight ? 'scale(1.1)' : 'scale(1)',
                    }}
                  >
                    {page.icon || <MailIcon />}
                  </ListItemIcon>
                  {(variant === 'temporary' || isOpen) && page.title}
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))}
      </List>
      {!isSmallScreen && variant === 'permanent' && (
        <Button
          onClick={() => dispatch(toggleSidebar())}
          sx={{
            minWidth: 'auto',
            justifyContent: 'center',
            borderRadius: 3,
            mt: 'auto',
            mb: 1,
            bgcolor: '#f5f5f5',
            width: !isOpen ? '50px' : '250px',
            maxWidth: '250px',
            py: 2,
            position: 'fixed',
            color: 'black',
            transition: 'all 0.3s ease',
            bottom: 20,
            '&:hover': {
              bgcolor: '#e0e0e0',
            }
          }}
        >
          {isOpen ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon />}
        </Button>
      )}
    </Box>
  );

  return (
    <Box>
      {isSmallScreen ? (
        <>
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: sidebarWidth,
                zIndex: theme.zIndex.drawer + 2,
              },
            }}
          >
            {drawer('temporary')}
          </Drawer>
          <Fab
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{
              position: 'fixed',
              bottom: 16,
              left: 16,
              zIndex: theme.zIndex.drawer + 1,
            }}
          >
            {mobileOpen ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon />}
          </Fab>
        </>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            position: 'relative',
            width: isOpen ? sidebarWidth : collapsedWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: isOpen ? sidebarWidth : collapsedWidth,
              boxSizing: 'border-box',
              overflowX: 'hidden',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            },
          }}
        >
          <Toolbar />
          {drawer('permanent')}
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
